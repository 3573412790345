
import { Box, Container, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import '../../Assets/css/HealthQuickQuote.css'
import '../../Assets/css/globalStyle.css'

import QuickQuoteForm from './QuickQuoteForm';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Axios from "axios";

const QuickQuote = () => {

    const [productDetails, setProductDetails] = useState([]);

    useEffect(() => {
      
    
    }, [])
    

    return (
        <div className=" bg-white QQ_container" >
            <div className="header-sec">
                <p className="insurence-title">
                    Health Insurance <KeyboardArrowDownIcon />
                </p>
            </div>
            <div >

            </div>
            <div className="form-container ">
                <QuickQuoteForm />
            </div>
        </div>
    )
}

export default QuickQuote