import { Box, Checkbox } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import React from 'react'
import TextError from '../TextError'

const FormikCheckBox = (props) => {
    const { label, name,arrayindex, validation,previousIndex, options, errors, ...rest } = props

    return (
        <Box >
            <div>
                <Field name={name}
                >
                    {
                        ({ form, field }) => {
                            const { setFieldValue,values } = form
                           // const { value } = form

                            const handleChange = (val) => {
                                console.log('inside checkbox', val.target.checked ,name,arrayindex,previousIndex)
                                setFieldValue(name,val.target.checked )
                                if(name ==='dependentDetail.sameAsAbove'){
                                    if( val.target.checked ===true){
                                        setFieldValue('dependentDetail.proposerResidenceAddressOne',values.dependentDetail.proposerAddressOne)
                                        setFieldValue('dependentDetail.proposerResidenceAddressTwo',values.dependentDetail.proposerAddressTwo)
                                        setFieldValue('dependentDetail.proposerResidencePostalCode',values.dependentDetail.postalCode)
                                        setFieldValue('dependentDetail.proposerResidenceAreaId',values.dependentDetail.proposerAreaId)
                                        document.getElementById(`dependentDetail.proposerResidenceAddressOne`).setAttribute('readonly', true)
                                        document.getElementById(`dependentDetail.proposerResidenceAddressTwo`).setAttribute('readonly', true)
                                        document.getElementById(`dependentDetail.proposerResidencePostalCode`).setAttribute('readonly', true)
                                        document.getElementById(`dependentDetail.proposerResidenceAreaId`).setAttribute('readonly', true)
                                        
                                    }else{
                                        setFieldValue('dependentDetail.proposerResidenceAddressOne','')
                                        setFieldValue('dependentDetail.proposerResidenceAddressTwo','')
                                        setFieldValue('dependentDetail.proposerResidencePostalCode','')
                                        setFieldValue('dependentDetail.proposerResidenceAreaId','')
                                        document.getElementById(`dependentDetail.proposerResidenceAddressOne`).removeAttribute('readonly')
                                        document.getElementById(`dependentDetail.proposerResidenceAddressTwo`).removeAttribute('readonly')
                                        document.getElementById(`dependentDetail.proposerResidencePostalCode`).removeAttribute('readonly')
                                        document.getElementById(`dependentDetail.proposerResidenceAreaId`).removeAttribute('readonly')

                                    }
                                }else if(name===`dependentDetail.partyAddressDOList[0].sameAsAbove`){
                                    if( val.target.checked ===true){
                                        setFieldValue(`dependentDetail.partyAddressDOList[0].addressLine1Lang12`,
                                        values.dependentDetail.partyAddressDOList[0].addressLine1Lang1)
                                        
                                        setFieldValue(`dependentDetail.partyAddressDOList[0].addressLine2Lang12`,
                                        values.dependentDetail.partyAddressDOList[0].addressLine2Lang1)
                                        
                                      /*   setFieldValue(`dependentDetail.partyAddressDOList[0].addressTypeCd2`,
                                        "COMMUNICATION") */

                                        setFieldValue(`dependentDetail.partyAddressDOList[0].areaCd2`,
                                        values.dependentDetail.partyAddressDOList[0].areaCd)

                                        setFieldValue(`dependentDetail.partyAddressDOList[0].cityCd2`,
                                        values.dependentDetail.partyAddressDOList[0].cityCd)

                                        setFieldValue(`dependentDetail.partyAddressDOList[0].pinCode2`,
                                        values.dependentDetail.partyAddressDOList[0].pinCode)

                                        setFieldValue(`dependentDetail.partyAddressDOList[0].stateCd2`,
                                        values.dependentDetail.partyAddressDOList[0].stateCd)

                                        document.getElementById(`dependentDetail.partyAddressDOList[0].addressLine1Lang12`).setAttribute('readonly', true)
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].addressLine2Lang12`).setAttribute('readonly', true)
                                        //document.getElementById(`dependentDetail.partyAddressDOList[0].addressTypeCd2`).setAttribute('readonly', true)
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].areaCd2`).setAttribute('readonly', true)
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].cityCd2`).setAttribute('readonly', true)
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].stateCd2`).setAttribute('readonly', true)
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].pinCode2`).setAttribute('readonly', true)
                                        
                                    }else{
                                        setFieldValue(`dependentDetail.partyAddressDOList[0].addressLine1Lang12`,
                                        '')
                                        
                                        setFieldValue(`dependentDetail.partyAddressDOList[0].addressLine2Lang12`,
                                        '')
                                        
                                      /*   setFieldValue(`dependentDetail.partyAddressDOList[0].addressTypeCd2`,
                                        "") */

                                        setFieldValue(`dependentDetail.partyAddressDOList[0].areaCd2`,
                                        '')

                                        setFieldValue(`dependentDetail.partyAddressDOList[0].cityCd2`,
                                        '')

                                        setFieldValue(`dependentDetail.partyAddressDOList[0].pinCode2`,
                                        '')

                                        setFieldValue(`dependentDetail.partyAddressDOList[0].stateCd2`,
                                        '')

                                        document.getElementById(`dependentDetail.partyAddressDOList[0].addressLine1Lang12`).removeAttribute('readonly')
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].addressLine2Lang12`).removeAttribute('readonly')
                                       // document.getElementById(`dependentDetail.partyAddressDOList[0].addressTypeCd2`).removeAttribute('readonly')
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].areaCd2`).removeAttribute('readonly')
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].cityCd2`).removeAttribute('readonly')
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].stateCd2`).removeAttribute('readonly')
                                        document.getElementById(`dependentDetail.partyAddressDOList[0].pinCode2`).removeAttribute('readonly')
                                        
                                    }
                                }else if(name===`primaryDetail[${previousIndex}].partyAddressDOList[0].sameAsAbove`){
                                    if( val.target.checked ===true){
                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressLine1Lang12`,
                                        values.primaryDetail[`${previousIndex}`].partyAddressDOList[0].addressLine1Lang1)
                                        
                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressLine2Lang12`,
                                        values.primaryDetail[`${previousIndex}`].partyAddressDOList[0].addressLine2Lang1)
                                        
                                        /* setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressTypeCd2`,
                                        "COMMUNICATION") */

                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].areaCd2`,
                                        values.primaryDetail[`${previousIndex}`].partyAddressDOList[0].areaCd)

                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].cityCd2`,
                                        values.primaryDetail[`${previousIndex}`].partyAddressDOList[0].cityCd)

                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].pinCode2`,
                                        values.primaryDetail[`${previousIndex}`].partyAddressDOList[0].pinCode)

                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].stateCd2`,
                                        values.primaryDetail[`${previousIndex}`].partyAddressDOList[0].stateCd)

                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressLine1Lang12`).setAttribute('readonly', true)
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressLine2Lang12`).setAttribute('readonly', true)
                                        //document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressTypeCd2`).setAttribute('readonly', true)

                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].areaCd2`).setAttribute('readonly', true)
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].cityCd2`).setAttribute('readonly', true)
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].stateCd2`).setAttribute('readonly', true)
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].pinCode2`).setAttribute('readonly', true)
                                        
                                    }else{
                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressLine1Lang12`,
                                        '')
                                        
                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressLine2Lang12`,
                                        '')
                                        
                                        /* setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressTypeCd2`,
                                        "") */

                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].areaCd2`,
                                        '')

                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].cityCd2`,
                                        '')

                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].pinCode2`,
                                        '')

                                        setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[0].stateCd2`,
                                        '')

                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressLine1Lang12`).removeAttribute('readonly')
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressLine2Lang12`).removeAttribute('readonly')
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].addressTypeCd2`).removeAttribute('readonly')

                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].areaCd2`).removeAttribute('readonly')
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].cityCd2`).removeAttribute('readonly')
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].stateCd2`).removeAttribute('readonly')
                                        document.getElementById(`primaryDetail[${previousIndex}].partyAddressDOList[0].pinCode2`).removeAttribute('readonly')
                                        
                                    }
                                }



                            }
                            /* return options.map((option)=>{
                                return (
                                    <>
                                        <input 
                                            id={option.value}
                                            type="checkbox"
                                            value={option.value}
                                            checked={field.value.includes(option.value)}
                                        />
                                        <label htmlFor={option.value}>{name}</label>
                                    </>
                                )
                            }) */
                            return (
                                <>
                                  {/*   <Field
                                        as={Checkbox}
                                        name={name}
                                        label={label}
                                        {...field}
                                        onChange={val => handleChange(val)}
                                    />
                                    <label>{label}</label> */}
                                    <label className="checkbox-container">
                                        {label}
                                        <Field
                                            type="checkbox"
                                            name={name}
                                            className="checkbox disable-team team_values mx-3"
                                            onChange={val => handleChange(val)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </>

                            )


                        }
                    }

                </Field>



                <ErrorMessage name={name} component={TextError} />
            </div>
        </Box >
    )
}

export default FormikCheckBox