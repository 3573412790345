import { Typography } from "@mui/material";
import Axios from "axios";
import { ErrorMessage, Field } from "formik";
import TextError from "../TextError";
import React, { useEffect } from "react";

function InputFields(props) {
  const {
    label,
    nomineeArray,
    setnomineeArray,
    name,
    validation,
    customeValidation,
    transperData,
    type,
    setNomineeShare,
    nomineeShare,
    arrayindex,
    readOnly,
    hidden,
    array,
    manualValidation,
    url,
    schemeId,
    errors,
    parentname,
    ...rest
  } = props;
  

  useEffect(() => {}, []);

  return (
    <div>
      <div>
        <Typography className="form-label">{hidden ? null : label}</Typography>

        <Field name={name}>
          {({ form, field }) => {
            const { setFieldValue, setFieldError } = form;
            const { value } = form;

            const handleChange=(val)=>{
              // const totalArray=[]
             
                setFieldValue(name,val.target.value)
              if(parentname==="nomineeDetails"){
                console.log(array,"array")
                // totalArray[array]=val.target.value
               
                  // console.log(val.target.value,"totalArray")
                                            
              }
             
                
                // console.log(values,"value")
              
             
            }


            const handleBlur=(val)=>{
              console.log(name,array,"name")
              if(name===`nomineeDetails[${array}].ShareofNominee`){
                console.log(val.target.value,name,"valuename")
              // totalArray[array]=val.target.value
              console.log(nomineeShare,val.target.value,"nomineeShare")
              const total=nomineeShare+ parseInt(val.target.value)
              if(total<100){
                setnomineeArray((prevstate)=>prevstate+1)

              }
              
              setNomineeShare((prevState)=>
              prevState+
              parseInt(val.target.value)
            )

              }

              
            }

            return (
              <>
                {readOnly !== undefined ? (
                  <Field
                    className="form-control"
                    label={label}
                    {...rest}
                    id={name}
                    hidden={hidden}
                    disabled={Boolean(readOnly)}
                    name={name}
                    {...rest}
                    min={type === "number" ? "0" : null}
                    placeholder={label}
                  />
                ) : (
                  <Field
                    className="form-control"
                    label={label}
                    {...rest}
                    id={name}
                    hidden={hidden}
                    type={type}
                    name={name}
                    min={type === "number" ? "0" : null}
                    {...rest}
                    placeholder={label}
                    onChange={(val) => handleChange(val, name)}
                    onBlur={(val)=>handleBlur(val,name)}
                  />
                )}
              </>
            );
          }}
        </Field>
        <ErrorMessage name={name} component={TextError}/>
      </div>
    </div>
  );
}

export default InputFields;
