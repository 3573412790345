import React from 'react'
import FormikControl from "../../../Components/HealthInsFormikComponent/FormikControl";
import CreateQuoteDependent from "./CreateQuoteDependent"

const CreateQuoteObjectView = (props) => {
    const { data, prevObject, previousObjectName, quickQuoteDetails } = props
    console.log('ob', data)
    console.log('prevObject from objrect', prevObject, previousObjectName)
    var length = data.obj.length;
    return (
        <div>
            {/*  <h5>{data.label}</h5> */}
            {
                data.obj.map((arrayField, arrayIndex) => {
                    if (arrayField.htmlElement === "Array") {
                        var nA = [arrayField.name]
                    }
                    return (
                        <div key={arrayIndex}>
                            {
                                arrayField.htmlElement === "TextField" ?
                                    <div className="mb-3" hidden={arrayField.hidden}>
                                        <FormikControl
                                            control='input'
                                            name={`${previousObjectName}.${data.name}.${arrayField.name}`}
                                            // arrayindex={i}
                                            errors={arrayField.errors}
                                            //transperData={transperData}
                                            readOnly={arrayField.readOnly}
                                            label={arrayField.label}
                                            type={arrayField.dataType}
                                            url={arrayField.url}

                                        />

                                    </div>
                                    : arrayField.htmlElement === "Select" ?
                                        <div className="mb-3" hidden={arrayField.hidden}>

                                            <FormikControl
                                                control='select'
                                                name={`${previousObjectName}.${data.name}.${arrayField.name}`}
                                                //name="insured.name"
                                                readOnly={arrayField.readOnly}
                                                quickQuoteDetails={quickQuoteDetails}

                                                //  arrayindex={i}
                                                errors={arrayField.errors}
                                                label={arrayField.label}
                                                type={arrayField.dataType}
                                                options={arrayField.options}

                                            />

                                        </div>
                                        : arrayField.htmlElement === "TextArea" ? (
                                            <div
                                                className="mb-3"
                                                key={arrayIndex}
                                                hidden={arrayField.hidden}
                                            >
                                                <FormikControl
                                                    control="textarea"
                                                    name={`${previousObjectName}.${data.name}.${arrayField.name}`}
                                                    // arrayindex={i}
                                                    errors={arrayField.errors}
                                                    //transperData={transperData}
                                                    readOnly={arrayField.readOnly}
                                                    label={arrayField.label}
                                                    type={arrayField.dataType}

                                                />
                                            </div>
                                        )
                                            : arrayField.htmlElement === 'MultiSelect' ?
                                                <div className="mb-3" key={arrayIndex} hidden={arrayField.hidden}>

                                                    <FormikControl
                                                        control='MultiSelect'
                                                        readOnly={arrayField.readOnly}
                                                        name={arrayField.name}
                                                        errors={arrayField.errors}
                                                        label={arrayField.label}
                                                        type={arrayField.dataType}
                                                        touched={arrayField.touched}
                                                        options={arrayField.options}
                                                    //onChange={(e)=>onChange(e,field.name)}  
                                                    //onChange={(e) => formik.handleChange(e)}

                                                    />

                                                </div>

                                                : arrayField.htmlElement === "DatePicker" ?
                                                    <div className="mb-3" hidden={arrayField.hidden}>

                                                        <FormikControl
                                                            control='date'
                                                            name={`${previousObjectName}.${data.name}.${arrayField.name}`}
                                                            //name="insured.dob"
                                                            errors={arrayField.errors}
                                                            readOnly={arrayField.readOnly}
                                                            label={arrayField.label}
                                                            type={arrayField.dataType}
                                                        //setSchemeId={setSchemeId}
                                                        //arrayindex={i}
                                                        />

                                                    </div>
                                                    : arrayField.htmlElement === "checkbox" ?
                                                        <div className="mb-3" hidden={arrayField.hidden}>

                                                            <FormikControl
                                                                control='checkBox'
                                                                name={`${previousObjectName}.${data.name}.${arrayField.name}`}
                                                                //name="insured.name"
                                                                //readOnly={arrayField.readOnly}

                                                                errors={arrayField.errors}
                                                                label={arrayField.label}
                                                                type={arrayField.dataType}
                                                                options={arrayField.options}

                                                            />

                                                        </div>
                                                        : arrayField.htmlElement === "Radio" ?
                                                            <div className="mb-3" hidden={arrayField.hidden}>

                                                                <FormikControl
                                                                    control='radio'
                                                                    name={`${previousObjectName}.${data.name}.${arrayField.name}`}
                                                                    //name="insured.name"
                                                                    //readOnly={arrayField.readOnly}

                                                                    errors={arrayField.errors}
                                                                    label={arrayField.label}
                                                                    type={arrayField.dataType}
                                                                    options={arrayField.options}

                                                                />

                                                            </div>
                                                            : arrayField.htmlElement === "Object" ?
                                                                <div >
                                                                    <CreateQuoteObjectView data={arrayField} name={prevObject} prevObject={data.name} />
                                                                </div>
                                                                : arrayField.htmlElement === "Array" ?
                                                                    <div className="mb-3" style={{ marginTop: '24px' }}>

                                                                        <h3 className="py-3 second-heading-text">{arrayField.label}</h3>

                                                                        <div className="mb-3" >
                                                                            <CreateQuoteDependent nestedname={arrayField.name} field={arrayField} dependentIndex={1} prevObject={data.name} name={prevObject} nested={nA} self='main' />

                                                                        </div>

                                                                    </div>
                                                                    : null
                            }

                        </div>

                    )
                })
            }
        </div>
    )
}

export default CreateQuoteObjectView