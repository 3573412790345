import React from 'react'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Card, CardContent, Stack, styled, Typography } from '@mui/material';


const DownLoadButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#0d409b'),
    backgroundColor: '#0d409b',
    border: '1px solid',
    borderRadius: '24px',
    '&:hover': {
        backgroundColor: '#0d409b',
    },
}));

const PaymentPage = () => {
    return (
        <div>
            <div className="header-sec">
                <p className="insurence-title">
                    Health Insurance <KeyboardArrowDownIcon />
                </p>
            </div>
            <Box sx={{ padding: '16px', marginTop: '16px' }}>
                <Card>
                    <CardContent>
                        <img />
                        <Typography
                            variant='h5'

                            sx={{display:'flex',justifyContent:'center',fontWeight:600}}
                        >
                            Premiuim Details
                        </Typography>
                       
                        <div className='mt-3 mb-3'>
                            <span style={{ float: 'left' }}>Package Premiuim </span>
                            <span style={{ float: 'left', color: 'silver' }}>..................</span>
                            <span style={{ float: 'right' }}><b>1122</b></span>
                            <br />
                            <span style={{ float: 'left' }}>GST@18%</span>
                            <span style={{ float: 'left', color: 'silver' }}>..................</span>
                            <span style={{ float: 'right' }}><b>43</b></span>
                            <br />
                            <span style={{ float: 'left' }}>Final Premium</span>
                            <span style={{ float: 'left', color: 'silver' }}>..................</span>
                            <span style={{ float: 'right' }}><b>111</b></span>
                        </div>
                    </CardContent>
                </Card>
            </Box>
            <Stack spacing={2} sx={{ padding: '16px', marginTop: '16px' }}>
                <DownLoadButton>
                    Pay Here
                </DownLoadButton>
            </Stack>
        </div>
    )
}

export default PaymentPage