import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import "../../Assets/css/LifeInsurance/ProductListCard.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate, useParams } from "react-router";
import Axios from "axios";
import { useLocation } from 'react-router';
//import SRimg from '../../ShriramImage/SRimg.jpg'




function ProductListCard() {
  const [focus, setFocus] = useState(false);
  const [activeindex, setactiveindex] = useState();
  const [productdata, setProductdata] = useState({});
  const [transferDataa, setTransferData] = useState({})
  const { id } = useParams()

  console.log(id, "id")
  const navigate = useNavigate();
  const location = useLocation();
  console.log(activeindex)
  var transferData


  const SelectingCardHandler = (item, index) => {
    console.log(productdata, "data wants to pass")
    console.log("trans", item[0], index)

    let premiumFrequency;
    switch (item[0]) {
      case 'Monthly':
        premiumFrequency = 1002
        break;
      case 'Quarterly':
        premiumFrequency = 1003
        break;
      case 'Half Yearly':
        premiumFrequency = 1004
        break;
      case 'Yearly':
        premiumFrequency = 1005
        break;
      default:
        break;

    }
    transferData = {
      PolicyTerm: productdata.request.PolicyTerm,
      PremiumTerm: productdata.request.PremiumTerm,
      PremiumFrequency: premiumFrequency,
      SumProposed: productdata.request.SumProposed,
      PremiumProposed: productdata.request.PremiumProposed,
      Premium: item[1].BasicPremium,
      GST: item[1].BasicST,
      TotalPremium: item[1].TotalPrem,
      ApplicationNo: productdata.request.ApplicationNo,
      PlanNo: "106",
      Age: productdata.request.Age,
      Gender: productdata.request.Gender,
      IsSmoker: productdata.request.IsSmoker,
      product_ref: productdata.response.responses.product_ref,
      UserID: "AXN80DMKYQ",
      MobileNo: location.state.value,
      PremiumFrequencyString: item[0],
    }
    setTransferData(transferData)
    console.log(transferData, "transferdata")
    setactiveindex(index)
  }
  console.log(transferDataa, "otside")

  const data = [1];
  const changeStyle = () => {
    console.log("you just clicked");

    setFocus(true);
  };
  const buyPlanHandler = () => {


    Axios.post(
      "http://65.0.70.70:8000/api/shriram/plp-validate/",
      //"http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/shriram/plp-validate/",
      transferDataa).then(response => {
        console.log(response.data)
        navigate(`/life-insurance/product-detail/${response.data.id}`)
      })
    // console.log(response.data,"outiside")

  }
  console.log(productdata, "response After set")

  useEffect(() => {
    /* "44a4bxedxPwGQtSnEzGzdejnCdtrDJqkJ6uqWwGbXmdx5zrjGdHivmr9pyd7x8rVQMgumwGPCfuwseieCg8tuN5jNNeMJPU" */
    //  Axios.get(`http://localhost:3002/api/v1/lifeInsurance/getQuickQuoteResponse}`).then(response=>{
    //   console.log(response,"response for PLP")
    //  })
    Axios.get(`http://localhost:3002/api/v1/lifeInsurance/getQuickQuoteResponse/${id}`).then(response => {
      console.log(response.data, "response for PLP")
      response.data.map((field, ind) => {
        if (field.response.response_id === id) {
          console.log("data find", field)

        }
      })
      setProductdata(response.data[0])
    })

  }, []);



  return (
    <div>
      <div style={{ padding: "10px" }}>
        <div className="header-text">
          Plan Suggested For You
          <KeyboardArrowDownIcon />
        </div>
        <div className="searchbox_container">
          <div className="search_box">
            {""}

            <SearchIcon></SearchIcon>


            <input type="search" className='searchbox' placeholder="Search for plans"></input>

          </div>

          <button className="sort_btn">sort</button>
        </div>
        {
          productdata &&
          Object.entries(productdata).length > 0 ? Object.entries(productdata.response.responses.ResultSet.Response).map((item, index) => {
            return (
              <Card className={activeindex === index ? "card-Sec_active" : "card-Sec"} key={index} onClick={() => SelectingCardHandler(item, index)}>
                {/*  <img className="pro_img" src={SRimg}/> */}
                <div className="pro_content">
                  <div className="p_namepart">
                    <div className="product_heading">Shriram Life Assured Savings Plan</div>
                    {/* <CheckCircleOutlineRoundedIcon />
                  <CheckCircleOutlineIcon/> */}
                    {activeindex === index ? <CheckCircleIcon className="circle_tic" /> : <CheckCircleOutlineRoundedIcon />}
                  </div>
                  <div>
                    <div id="pro_name">{productdata.product_name}</div>
                  </div>
                  <div className="Amount">
                    <div>
                      <div className="Care_text">Term</div>
                      <div className="Care_text">{item[0]}</div>
                    </div>
                    <div>
                      <div className="Care_text" >Sum Assured</div>
                      <div className="Care_text"><b className="rupee_symp">&#2352;</b> {item[1].SumAssured}</div>
                    </div>
                    <div>
                      <div className="Care_text">Premium</div>
                      <div className="Care_text"><b className="rupee_symp">&#2352;</b> {item[1].BasicPremium}</div>
                    </div>

                  </div>
                </div>
              </Card>
            )

          }) : null
        }
        <div className="button-sec">
          <button className="Goback_btn">Go back</button>
          <button className="btn" onClick={buyPlanHandler}>buy plan</button>
        </div>
      </div>
    </div>
  );
}

export default ProductListCard;
