import React from 'react'
import pdfimage from '../../Assets/images/pdfimage.png';


const PdfDocuments = ({ doc1, doc2, doc3, doc4, doc5,docName1,docName2,docName3,docName4,docName5 }) => {
    return (
        <>
            {
                doc1 && docName1 !== "" ?
                    <>
                        <a className="pdf_card"
                            href={doc1}
                        >

                            <div className="sub_card blue_card"></div>
                            <div className="sub_card white_card mx-auto">
                                <div className="pdf_img">
                                    <img className='' src={pdfimage}></img>

                                </div>
                                <div className="pdf_text text-center text-wrap">{docName1}</div>
                            </div>
                        </a>
                    </>
                    : null
            }
            {
                doc2 && docName2 !== "" ?
                    <>
                        <a className="pdf_card"
                            href={doc2}
                        >

                            <div className="sub_card blue_card"></div>
                            <div className="sub_card white_card">
                                <div className="pdf_img">
                                    <img src={pdfimage}></img>

                                </div>
                                <div className="pdf_text text-center">{docName2}</div>
                            </div>
                        </a>
                    </>
                    : null
            }
            {
                doc3 && docName3 !== "" ?
                    <>
                        <a className="pdf_card"
                            href={doc3}
                        >

                            <div className="sub_card blue_card"></div>
                            <div className="sub_card white_card">
                                <div className="pdf_img">
                                    <img src={pdfimage}></img>

                                </div>
                                <div className="pdf_text text-center">{docName3}</div>
                            </div>
                        </a>
                    </>
                    : null
            }
            {
                doc4 && docName4!== "" ?
                    <>
                        <a className="pdf_card"
                            href={doc4}
                        >

                            <div className="sub_card blue_card"></div>
                            <div className="sub_card white_card">
                                <div className="pdf_img">
                                    <img src={pdfimage}></img>

                                </div>
                                <div className="pdf_text text-center">{docName4} </div>
                            </div>
                        </a>
                    </>
                    : null
            }
            {
                doc5 && docName5 !== "" ?
                    <>
                        <a className="pdf_card"
                            href={doc5}
                        >

                            <div className="sub_card blue_card"></div>
                            <div className="sub_card white_card">
                                <div className="pdf_img">
                                    <img src={pdfimage}></img>

                                </div>
                                <div className="pdf_text text-center">{docName5} </div>
                            </div>
                        </a>
                    </>
                    : null
            }

            {/*  <a className="pdf_card" href="https://acuvisor-prod.s3.ap-south-1.amazonaws.com/uploads/slider/1638450935Brochure.pdf">

                <div className="sub_card blue_card"></div>
                <div className="sub_card white_card">
                    <div className="pdf_img">
                        <img src={pdfimage}></img>

                    </div>
                    <div className="pdf_text">Wordings.pdf</div>
                </div>
            </a> */}
        </>
    )
}

export default PdfDocuments