import { Box, Typography } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import React from 'react'
import TextError from '../TextError'

const TextAreaFormik = (props) => {

    const { label, name, validation, customeValidation, hidden, url, errors, ...rest } = props

    return (
        <Box >
            <div>
                <Typography  >{label}</Typography>

                <Field name={name}
                >
                    {
                        ({ form, field }) => {
                            const { setFieldValue } = form
                            const { value } = form

                            const handleChange = (val) => {

                                if (name === 'proposerDob') {

                                }
                                if (url !== undefined) {
                                    console.log('url', url)

                                }
                                //if(name=)
                                setFieldValue(name, val.target.value)
                                //console.log('val', val.target.value.trim())
                            }
                            return (
                                <Field
                                    as='textarea'
                                    label={label}
                                    {...rest}
                                    id={name}
                                    hidden={hidden}
                                    name={name}
                                    {...rest}
                                    placeholder={label} className="form-control"
                                    onChange={val => handleChange(val)}

                                />
                            )

                        }
                    }

                </Field>
                <ErrorMessage name={name} component={TextError} />
            </div>
        </Box >
    )
}

export default TextAreaFormik