import { FieldArray, useFormikContext } from "formik";
import  React, {useState, useEffect } from "react";
import _ from "lodash";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import FormikControl from "../../../Components/HealthInsFormikComponent/FormikControl";

import CreateQuoteObjectView from "./CreateQuoteObjectView"

const CreateQuoteDependent = (props) => {
    const {
        field,
        setSchemeId,
        transperData,
        dependentIndex,
        previousIndex,
        prevObject,
        quickQuoteDetails,
        name,
        arrindex,
        innerField,
        ...rest
    } = props;
    const [arrayItem, setArrayItem] = useState({})
    const { values, submitForm } = useFormikContext();

    //   console.log(values,"valiuues",FieldArray)
    var arrayObject
    useEffect(() => {
        // console.log(values,"valiuues",values[`${field.name}`])
        if (values[`${field.name}`]) {
            values[`${field.name}`].map((formikValue, formikIndex) => {
                // console.log('item',formikValue)
                arrayObject = formikValue
                setArrayItem(formikValue)
            })

        }

        if (name === `primaryDetail[${previousIndex}].partyIdentityDOList[0].identityTypeCd`) {
            if (values.primaryDetail[`${previousIndex}`].partyIdentityDOList[0].identityTypeCd === "") {

            } else if (values.primaryDetail[`${previousIndex}`].partyIdentityDOList[0].identityTypeCd === "PAN") {
                if (document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[0].panNumber`)
                    .closest('.mb-3') !== 'null') {
                    document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[0].panNumber`)
                        .closest('.mb-3').removeAttribute('hidden')
                }
                if (document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[0].passportNumber`)
                    .closest('.mb-3') !== 'null') {
                    document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[0].passportNumber`)
                        .closest('.mb-3').setAttribute('hidden', true)
                }


            } else if (values.primaryDetail[`${previousIndex}`].partyIdentityDOList[0].identityTypeCd === "PASSPORT") {
                document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[0].panNumber`)
                    .closest('.mb-3').setAttribute('hidden', true)
                document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[0].passportNumber`)
                    .closest('.mb-3').removeAttribute('hidden')
            }

            console.log('qqq', quickQuoteDetails)


        }

    }, [])


   
    return (
        <div>
            {Object.keys(field).length > 0 ? (
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        // console.log(arrayHelpers,"Field Array")
                        // if(dependentIndex>1 && field.name==="primaryDetail"){
                        //     arrayHelpers.push(arrayItem)
                        // }



                        return (
                            <div>
                                {_.times(dependentIndex, (i) => {
                                    const nestedName = `${name}.${field.name}[${i}]`
                                    return (
                                        <div key={i}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Typography style={{ justifyContent: "center" }}>
                                                    {field.label} {i + 1}
                                                </Typography>
                                            </Box>
                                            {field.obj.map((arrayField, arrayIndex) => {

                                                return (
                                                    <div >
                                                        {arrayField.htmlElement === "TextField" ? (
                                                            <div
                                                                className="mb-3"
                                                                key={arrayIndex}
                                                                hidden={arrayField.hidden}
                                                            >
                                                                <FormikControl
                                                                    control="input"
                                                                    //name={`${nestedName}.${arrayField.name}`}
                                                                    name={`${name}.${field.name}[${i}].${arrayField.name}`}
                                                                    arrayindex={i}
                                                                    //errors={arrayField.errors}
                                                                    url={arrayField.url}
                                                                    previousIndex={previousIndex}
                                                                    quickQuoteDetails={quickQuoteDetails}
                                                                    // transperData={transperData}
                                                                    readOnly={arrayField.readOnly}
                                                                    label={arrayField.label}
                                                                    type={arrayField.dataType}
                                                                />
                                                            </div>
                                                        ) : arrayField.htmlElement === "Select" ? (
                                                            <div
                                                                className="mb-3"
                                                                key={arrayIndex}
                                                                hidden={arrayField.hidden}
                                                            >
                                                                <FormikControl
                                                                    control="select"
                                                                    name={`${nestedName}.${arrayField.name}`}
                                                                    readOnly={arrayField.readOnly}
                                                                    arrayindex={i}
                                                                    quickQuoteDetails={quickQuoteDetails}
                                                                    previousIndex={previousIndex}
                                                                    errors={arrayField.errors}
                                                                    label={arrayField.label}
                                                                    type={arrayField.dataType}
                                                                    options={arrayField.options}
                                                                //value={arrayHelpers.form.values}
                                                                />
                                                            </div>
                                                        ) : arrayField.htmlElement === "TextArea" ? (
                                                            <div
                                                                className="mb-3"
                                                                key={arrayIndex}
                                                                hidden={arrayField.hidden}
                                                            >
                                                                <FormikControl
                                                                    control="textarea"
                                                                    name={`${nestedName}.${arrayField.name}`}
                                                                    arrayindex={i}
                                                                    errors={arrayField.errors}
                                                                    previousIndex={previousIndex}
                                                                    url={arrayField.url}
                                                                    // transperData={transperData}
                                                                    readOnly={arrayField.readOnly}
                                                                    label={arrayField.label}
                                                                    type={arrayField.dataType}
                                                                />
                                                            </div>
                                                        ) : arrayField.htmlElement === 'MultiSelect' ?
                                                            <div className="mb-3" key={arrayIndex} hidden={arrayField.hidden}>

                                                                <FormikControl
                                                                    control='MultiSelect'
                                                                    readOnly={arrayField.readOnly}
                                                                    name={arrayField.name}
                                                                    errors={arrayField.errors}
                                                                    previousIndex={previousIndex}
                                                                    label={arrayField.label}
                                                                    type={arrayField.dataType}
                                                                    touched={arrayField.touched}
                                                                    options={arrayField.options}
                                                                //onChange={(e)=>onChange(e,field.name)}  
                                                                //onChange={(e) => formik.handleChange(e)}

                                                                />

                                                            </div>

                                                            : arrayField.htmlElement === "DatePicker" ? (
                                                                <div
                                                                    className="mb-3"
                                                                    key={arrayIndex}
                                                                    hidden={arrayField.hidden}
                                                                >
                                                                    <FormikControl
                                                                        control="date"
                                                                        name={`${nestedName}.${arrayField.name}`}
                                                                        errors={arrayField.errors}
                                                                        readOnly={arrayField.readOnly}
                                                                        label={arrayField.label}
                                                                        type={arrayField.dataType}
                                                                        previousIndex={previousIndex}

                                                                        setSchemeId={setSchemeId}
                                                                        arrayindex={i}
                                                                    />
                                                                </div>
                                                            ) : arrayField.htmlElement === "checkbox" ? (
                                                                <div
                                                                    className="mb-3"
                                                                    key={arrayIndex}
                                                                    hidden={arrayField.hidden}
                                                                >
                                                                    <FormikControl
                                                                        control="checkBox"
                                                                        name={`${nestedName}.${arrayField.name}`}
                                                                        errors={arrayField.errors}
                                                                        arrayindex={i}
                                                                        previousIndex={previousIndex}
                                                                        label={arrayField.label}
                                                                        type={arrayField.dataType}
                                                                        options={arrayField.options}
                                                                    />
                                                                </div>
                                                            ) : arrayField.htmlElement === "Radio" ? (
                                                                <div
                                                                    className="mb-3"
                                                                    key={arrayIndex}
                                                                    hidden={arrayField.hidden}
                                                                >
                                                                    <FormikControl
                                                                        control="radio"
                                                                        name={`${nestedName}.${arrayField.name}`}

                                                                        arrayindex={i}
                                                                        depindex={dependentIndex}
                                                                        arrayvalues={arrayHelpers.form.values}
                                                                        errors={arrayField.errors}
                                                                        previousIndex={previousIndex}
                                                                        quickQuoteDetails={quickQuoteDetails}

                                                                        label={arrayField.label}
                                                                        type={arrayField.dataType}
                                                                        options={arrayField.options}
                                                                    />
                                                                </div>
                                                            ) : arrayField.htmlElement === "Array" ? (
                                                                <div
                                                                    className="mb-3"
                                                                    key={arrayIndex}
                                                                    style={{ marginTop: "24px" }}
                                                                >
                                                                    <div className="mb-3">
                                                                        <CreateQuoteDependent
                                                                            name={`${nestedName}.${arrayField.name}`}
                                                                            field={arrayField}
                                                                            dependentIndex={1}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : arrayField.htmlElement === "Object" ? (
                                                                <div>
                                                                    <CreateQuoteObjectView
                                                                        field={arrayField}
                                                                        dependentIndex={1}
                                                                        prevObject={prevObject}
                                                                        name={name}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                    </div>
                                                );
                                            })}
                                            <hr className="bg-dark" />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }}
                />
            ) : null}
        </div>
    );
};

export default CreateQuoteDependent;
