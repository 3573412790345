import moment from "moment";

var starHealth = {
    APIKEY: "ddd81d1e8853445d812e8e2ed9827f8a",
    SECRETKEY: "b1bf9940bb5c4531bb4a1ecfafc2cfd2",
    //insureds[0]: { name: 'sha', dob: '1990-11-02', relationshipId: '', sumInsuredId: '' },
    period: "1",
    policyTypeName: "STARYSIND",
    postalCode: "125367",
    schemeId: 0,
    sumInsuredId: "300000",
}



const starQuickReqBody = (data) => {
    console.log('dta from submit', data)
    let relationship;
    let countAdult = 0;
    let countChild = 0;
    let schemeId = 0;
    let sumInsuredId = '';

    let insureArray = [];

    data['insureds'].map((item, index) => {
        console.log(`insured item ${index}`, item)
        /*  let insureObject={
             name: item.name, 
             dob: '1990-11-02', 
             relationshipId: item.relationShip , 
             sumInsuredId: data
         }; */

        if (item.relationShip === 'Dependent Child') {
            countChild++;
        } else {
            countAdult++;
        }
    })
    if (countChild > 0) {
        relationship = `${countAdult}A+${countChild}C`
    } else {
        relationship = `${countAdult}A`
    }
    //console.log('adult and child', countAdult, countChild)
    console.log('relationship', relationship)

    switch (relationship) {
        case '1A':
            schemeId = 0;
            break;
        case '2A':
            schemeId = 1;
            break;
        case '1A+1C':
            schemeId = 2;
            break;
        case '1A+2C':
            schemeId = 3;
            break;
        case '1A+3C':
            schemeId = 4;
            break;
        case '2A+1C':
            schemeId = 5;
            break;
        case '2A+2C':
            schemeId = 6;
            break;
        case '2A+3C':
            schemeId = 7;
            break;
        default:
            schemeId = 0;
            break;
    }
    console.log('schemeId', schemeId)


    // let custStar={
    //     APIKEY: "ddd81d1e8853445d812e8e2ed9827f8a",
    //     SECRETKEY: "b1bf9940bb5c4531bb4a1ecfafc2cfd2",
    //     period: `${data.period}`,
    //     policyTypeName: "STARYSIND",
    //     postalCode: `${data.pincode}`,
    //     schemeId: 0,
    //     scheme: relationship,
    //     sumInsuredId: data.sumInsuredValue,
    // }

    let star = {
        "APIKEY": "ddd81d1e8853445d812e8e2ed9827f8a",
        "SECRETKEY": "b1bf9940bb5c4531bb4a1ecfafc2cfd2",
        "policyTypeName": "COMPREHENSIVEIND",

        "postalCode": "",
        "period": '',
        "sumInsured": '',
        "schemeId": '',
        /* "insureds[0]": {
            "name": "",
            "dob": "",
            "Relationship": '',
            
        } */
    }
    let insuredsArray = []
    let insuredsObject = {}

    data.insureds.map((item, index) => {

        let dateYear = new Date().getFullYear()
        let dateMonth = new Date().getMonth()

        let RelationShip;
        let dob;
        if(item.Age === "6 Month"){
            let month = dateMonth - 6
            console.log('aga', month)
            dob = moment({ year: dateYear, month: month, day: 1 }).format('YYYY-MM-DD');
            console.log('dob', dob)

        }else{
            let year = dateYear - item.Age
            console.log('aga', year)
            dob = moment({ year: year, month: 0, day: 1 }).format('YYYY-MM-DD');
            console.log('dob', dob)
        }
       
        switch (item.relationShip) {
            case 'Self':
                RelationShip = '1'
                break;
            case 'Spouse':
                RelationShip = '2'
                break;
            case 'Dependent Child':
                RelationShip = '3'
                break;
            case 'Dependent Parent':
                RelationShip = '4'
                break;
            case 'Dependent In Law':
                RelationShip = '5'
                break;
            case 'Dependent In Law':
                RelationShip = '50'
            case 'Others':
                RelationShip = '17'
                break;
            default:
                break;
        }

        let insurObj = {
            "name": item.name,
            "dob": dob,
            "Relationship": RelationShip,
        }
        console.log('sssasas', insurObj)
        insuredsArray.push(insurObj)
    })
    console.log('insureds', insuredsArray)
    insuredsArray.map((item, index) => {
        insuredsObject = { ...insuredsObject, [`insureds[${index}]`]: item }
    })
    console.log('insuredsObject', insuredsObject)
    star = {
        ...star,
        "postalCode": String(data.pincode),
        "period": data.period,
        "sumInsured": data.sumInsuredValue,
        "schemeId": schemeId,
        "scheme": relationship,
        "insureds": insuredsArray
    }
    console.log('star', star)
    return star

    // console.log('cust',custStar)

    //data = { ...data, ['schemeId']: schemeId, ['scheme']: relationship }
    //console.log("Updated submit Form", data)

}

export default starQuickReqBody;