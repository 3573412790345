import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import { Route, Routes, Link } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { FieldArray, Form, Formik } from "formik";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import FormikControl from "../../../Components/LifeInsFormikComponent/FormikControl";
import createInitialValue from "../../../Utilities/InitialValuesSetup/createInitialValue";
import * as Yup from "yup";
import { Box } from "@mui/system";
//import GetBIvalues from "../JSONbodies/GetBI";
import { useNavigate,useParams } from 'react-router';
import automaticValidationSchema from "../../../Utilities/ValidationSchemaSetup/automaticValidationSchema";
import Swal from 'sweetalert2'
//var serialize = require('form-serialize');

function GetBiForm(props) {
  const [productDetails, setProductDetails] = useState([]);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [pdfLink,setPdfLink]=useState('')
  const navigate=useNavigate()
  const { id } = useParams();
  const [transferData,setTransferData]=useState({});
  console.log(id)

console.log(transferData,"transferData")
  var initialValues={}
  var finalIni={};
  if(Object.keys(productDetails).length>0){
    initialValues=createInitialValue(productDetails)
    console.log(initialValues,"initialvalues")
    Object.entries(initialValues).map((field,ind)=>{
      if (!transferData.hasOwnProperty(field[0])) {
        finalIni[`${field[0]}`] = field[1];
      }
    })
    finalIni={...finalIni,...transferData}
  }
   console.log(finalIni,"final")


  var validationSchema = Yup.object().shape({});
  if(Object.keys(productDetails).length>0){
    validationSchema=automaticValidationSchema(productDetails.requestBody.formFields.fields);
  }

  useEffect(() => {
    //setProductDetails(GetBIvalues)
    let id_split=id.split('+')
    Axios.get(`http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/shriram/plp-validate-response/${id_split[0]}/`).

    then(response=>{
      setTransferData(response.data)
      console.log(response,"transdata in bi")
    })
  }, []);

  // const onSubmit=(values)=>{
  //   console.log("Getbi Form valies",values)
  //   console.log('ts',transferData)
  //   let id_split=id.split('+')
  // alert(Object.entries(values).map((field,index)=>{
  //      return `${field[0]}:${field[1]}`
  
       
  // }))
  // navigate(`/paypremium/${id_split[0]}`)
  // }


  const onSubmit = (values) => {
    console.log("Getbi Form valies", values)
    Axios.post(" http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/life-insurance/bi/", values).then(response => {
      console.log(response, "response in GETBI")
      if (response.data.ResultSet.ResponseCode === "200") {
        setIsSubmitForm(true)
        setPdfLink(response.data.ResultSet.Response.BILink)
        console.log('link', response.data.ResultSet.Response.BILink)
        Swal.fire({
          title: 'You can download the details by using this link',
          html:
    `<a href=http://${response.data.ResultSet.Response.BILink}>${response.data.ResultSet.Response.BILink}</a> `,
   
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'OK',
          denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            let id_split=id.split('+')
            navigate(`/life-insurance/paypremium/${id_split[0]}`)
            // Swal.fire('Saved!', '', 'success')
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        })
        //window.open(`http://${response.data.ResultSet.Response.BILink}`)
       
      }

      // 

    })



  }
  return (
    <div id="content">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
            <div className="bg-white shadow-md rounded-card  pt-sm-4 pb-sm-5 px-sm-5 card-responsive ">
              <h3 className="fw-400 text-center my-4 heading-text">
               Benefit Illustration
              </h3>
              {Object.keys(initialValues).length > 0 ? (
                <Formik
                  enableReinitialize
                  initialValues={finalIni}
                  // validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formik) => {
                    console.log("formik val", formik);
                    return (
                      <div className="justify-content-center ">
                        <Form>
                          {Object.keys(productDetails).length> 0
                            ? productDetails.requestBody.formFields.fields.map(
                                (field, index) => {
                                  return field.htmlElement ===
                                  "TextField" ? (
                                  <div
                                    className="mb-3"
                                    key={index}
                                    hidden={field.hidden}
                                  >
                                    <FormikControl
                                      control="input"
                                      errors={formik.errors}
                                      name={field.name}
                                      readOnly={field.readOnly}
                                      url={field.url}
                                      label={field.label}
                                      type={field.dataType}
                                      touched={formik.touched}
                                    />
                                  </div>
                                  ) : field.htmlElement ===
                                  "Select" ? (
                                  <div
                                    className="mb-3"
                                    key={index}
                                    hidden={field.hidden}
                                  >
                                    <FormikControl
                                      control="select"
                                      name={field.name}
                                      errors={formik.errors}
                                      readOnly={field.readOnly}
                                      url={field.url}
                                      options={field.options}
                                      label={field.label}
                                      type={field.dataType}
                                      touched={formik.touched}
                                    />
                                  </div>
                                  ): 
                                  field.htmlElement ===
                                  "Radio" ? (
                                  <div
                                    className="mb-3"
                                    key={index}
                                    hidden={field.hidden}
                                  >
                                    <FormikControl
                                      control="radio"
                                      name={field.name}
                                      errors={formik.errors}
                                      readOnly={field.readOnly}
                                      url={field.url}
                                      label={field.label}
                                      type={field.dataType}
                                      options={field.options}
                                      touched={formik.touched}
                                    />
                                  </div>):null;
                                }
                              )
                            : null}

                         
                            <div className="d-grid mb-3 ">
                              {console.log("isSubmit", formik.isSubmitting)}

                             
                            
                                <button
                                  className="submitBtn mt-4"
                                  type="submit"
                                 
                                >
                                  Submit
                                </button>
                             
                            </div>
                         
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              ) : (
                <Typography>Loading....</Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetBiForm;
