import { Box, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import TextError from '../TextError'
import CustomeDatePicker from './CustomeDatePicker'

const FormikDatePicker = (props) => {
    const { label, name, validation, options, readOnly, setSchemeId, arrayindex, errors, ...rest } = props
    const [erro, setErro] = useState('')
    const [errorMessage, setErrorMessage] = useState({})
    // const [child, setChild] = useState();
    // const [adult, setAdult] = useState();
    // let initialVal = 0;
    var currendate = new Date();
    var day = currendate.getDate();
    day = day.toString()
    var month = currendate.getMonth() + 1;
    //console.log(month)
    month = month.toString()
    // const fdate=`${d.toLocaleString('default',{month:'number'})} ${day.length===1?`0${day}`:day},${d.getFullYear()} ` 
    const fdate = `${currendate.getFullYear()}-${month.length === 1 ? `0${month}` : month}-${day.length === 1 ? `0${day}` : day}`
    //fdate = moment(fdate).format('mm-dd-yyyy')
    //console.log(currendate,fdate)
    //console.log(setSchemeId)

    // const utility = "https://acuvisor.auxtomate.in/assets/js/proposalform/General-2.js"

    // useEffect(() => {

    //     if(initialVal === 0) {
    //         let utilityScript = document.createElement("script");
    //         utilityScript.src = utility;
    //         document.body.appendChild(utilityScript);
    //         alert("useeffect")

    //         initialVal++;
    //     }


    // }, [])


    return (
        <Box>
            <div>
                <Typography  >{label}</Typography>
                <Field name={name}
                >
                    {
                        ({ form, field }) => {
                            const { setFieldValue, setFieldError, errors } = form

                            //console.log('form', form, errors)

                            const handleChange = (val) => {
                                //console.log('date', val.target.value)
                                //setFieldValue(name,val.target.value) name === insured[2].dob

                                setFieldValue(name, val.target.value)

                                console.log('ddooo', name, arrayindex)
                                if (name === `primaryDetail[${arrayindex}].dob`) {
                                    let isAfter = moment(val.target.value).isAfter(fdate);
                                    console.log('yessss', fdate, val.target.value, isAfter)
                                    if (isAfter) {
                                        setFieldValue(name, '')
                                        setFieldError(name, 'Future date')


                                    } else {
                                        setFieldValue(name, (val.target.value))

                                    }

                                }

                                /*  if (true) {
 
                                     let externalResponse = window['ValidateDOB'](val.target.value)
 
                                     console.log('external time', externalResponse)
                                     //setErrorMessage(window['ValidateDOB'](val.target.value));
                                     if (externalResponse.message === '') {
 
                                         setFieldValue(name, val.target.value)
                                         setFieldValue('schemeId', externalResponse.schemeId)
 
                                     } else {
                                         setFieldError(name, externalResponse.message)
                                     }
                                     /* if (errorMessage.schemeId !== '') {
                                         setSchemeId({ errorMessage })
                                     } 
                                 } else {
                                     setFieldValue(name, val.target.value)
 
                                 } */






                                /*  if(name === 'insured[0].dob'){
                                     /ValidateDOB(val.target.value)
                                 } */


                            }

                            const blurEffect = () => {

                            }
                            return (
                               /*  <>
                                    {
                                        readOnly !== undefined ?
                                            <Field
                                                label={label}

                                                {...rest}
                                                id={name}
                                                name={name}
                                                {...rest}
                                                max={fdate}
                                                // format="MM/DD/YYYY"
                                                disabled={Boolean(readOnly)}
                                                placeholder={label}
                                                className="form-control"
                                                //value={'04/02/2022'}
                                                onChange={(val) => handleChange(val)}
                                           
                                            />
                                            :
                                            <Field
                                                label={label}

                                                {...rest}
                                                id={name}
                                                name={name}
                                                max={fdate}
                                                {...rest}
                                               
                                                placeholder={label}
                                                className="form-control"
                                                onChange={(val) => handleChange(val)}
                                           
                                            />
                                    }
                                </> */
                                <>
                                    <Field
                                     label={label}

                                     {...rest}
                                     id={name}
                                     name={name}
                                     {...rest}
                                     //max={fdate}
                                     // format="MM/DD/YYYY"
                                     //disabled={Boolean(readOnly)}
                                     placeholder={label}
                                     className="form-control"
                                     //value={'04/02/2022'}
                                     //onChange={(val) => handleChange(val)}
                                    component={CustomeDatePicker}
                                    />
                                </>
                            )

                        }
                    }

                </Field>
                <ErrorMessage name={name} component={TextError} />
                {/*  {
                    errors ? <p style={{ color: 'red', fontSize: '16px' }}>{errors}</p> : null
                } */}
                {/*  {
                        errorMessage.message !==''? <p style={{color:'red',fontSize:'16px'}}>{errorMessage.message}</p>:null
                    }  */}
                {/* <ErrorMessage name={name} component={TextError} /> */}
            </div>
        </Box >
    )
}

export default FormikDatePicker