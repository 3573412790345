import React, { useState } from "react";

//import "./ProductDetailPage.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, Paper } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//import Carelogo from "../CareImages/Carelogo.jpg"
//import Acurightlogo from '../../Assets/images/Acurightlogo.PNG';
//import Acuvisorlogo from '../../Assets/images/Acuvisorlogo.PNG';
import Axios from "axios";
import CryptoJS from 'crypto-js'

import { useEffect } from "react";
import DocumentsNeeded from "./DocumentsNeeded";
import PdfDocuments from "./PdfDocuments";
import Highlights from "./Highlights";




const PolicyHighlightPage = () => {

    const [quickQuoteDetails, setQuickQuoteDetails] = useState({});
    //const [depInd, setdepInd] = useState(1)
    const [acuviserProductDetail, setAcuviserProductDetail] = useState({});
    const [videoUrl, setVideoUrl] = useState('')

    const location = useLocation();
    // console.log(location.state.S_data)

    const { productId } = useParams();

    const productIdArray = productId.split('*')
    const productRef = productIdArray[0]
    const responseId = productIdArray[1]
    const Acuvisor_product_id = productIdArray[2]


    const navigate = useNavigate();

    const productRefURI = decodeURIComponent(productRef).toString()
    const responseIdURI = decodeURIComponent(responseId).toString()
    const Acuvisor_product_idURI = decodeURIComponent(Acuvisor_product_id).toString()



    const bytesProductRefURI = CryptoJS.AES.decrypt(productRefURI, 'secret key @123');
    const bytesResponseIdURI = CryptoJS.AES.decrypt(responseIdURI, 'secret key @124');
    const bytesAcuvisor_product_idURI = CryptoJS.AES.decrypt(Acuvisor_product_idURI, 'secret key @124');



    const decryptedProductRef = JSON.parse(bytesProductRefURI.toString(CryptoJS.enc.Utf8));
    const decryptedResponseId = JSON.parse(bytesResponseIdURI.toString(CryptoJS.enc.Utf8));
    const decryptedAcuvisor_product_id = JSON.parse(bytesAcuvisor_product_idURI.toString(CryptoJS.enc.Utf8));

    console.log('decryptedResponseId',decryptedResponseId)


    useEffect(() => {
        let formdata = new FormData();
        formdata.append("policy_product_id", decryptedAcuvisor_product_id);
        console.log('form Data', formdata)


        Axios.post('https://dev.acuvisor.com/App2/Application/get_policydetal/', formdata, {

            headers: {
                "Authorization": "Basic VC0sLDlfZDYzWUdZSWM4NyheNTpSbjcyXjhZS3FSaXA4dl5hI3w=",
            }

        }).then((res) => {
            console.log('php', res)
            let videoUrlArray;
            if (res.status === 200) {
                setAcuviserProductDetail(res.data[0])

                //let dd = 'https://www.youtube.com/watch?v=w17lCIX_P54';

                //console.log('ggg', dd.split('/'))
                //let ss = dd.split('/')
                //let aa = ss[3].split('=')
                //console.log('sss', ss[3], aa[1])
                videoUrlArray = res.data[0].video_url.split('/')
                console.log('kkk', videoUrlArray[videoUrlArray.length - 1].includes('watch'))
                if (videoUrlArray[videoUrlArray.length - 1].includes('watch')) {
                    let splitWatch = videoUrlArray[videoUrlArray.length - 1].split('=')
                    setVideoUrl(splitWatch[splitWatch.length - 1])
                } else {
                    setVideoUrl(videoUrlArray[videoUrlArray.length - 1])
                }

            }
        })

      

        if (window.location.hostname === 'localhost') {
            Axios.get(`http://localhost:8000/api/health/transfer-data/${decryptedResponseId}`).then((response) => {
                if (response.status === 200) {
                    console.log('response from djamgoo ', response)
                    setQuickQuoteDetails(response.data)
                    //setdepInd(response.data.num_of_insured)
                    // productDetailsArray.push(response.data)
                    //setProductDetails(productDetailsArray);
                    // setProductDetails(response.data);

                }

            })
        } else {
            Axios.get(`http://65.0.70.70:8000/api/health/transfer-data/${decryptedResponseId}`).then((response) => {
                if (response.status === 200) {
                    console.log('response from djamgoo ', response)
                    setQuickQuoteDetails(response.data)
                    //setdepInd(response.data.num_of_insured)
                    // productDetailsArray.push(response.data)
                    //setProductDetails(productDetailsArray);
                    // setProductDetails(response.data);

                }

            })
        } 

    }, [])

    const buyPlanHandler = () => {

        let encryptedProduct_ref = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(decryptedProductRef), 'secret key @123').toString());
        let encryptedDataId = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(decryptedResponseId), 'secret key @124').toString());

        let path = `/health-insurance/product/${encryptedProduct_ref}*${encryptedDataId}`;

        navigate(path)
    }

    const arrowBackHandler = () => {
        navigate(-1);
    }


    return (
        <>
            {
                Object.keys(quickQuoteDetails).length > 0 && Object.keys(acuviserProductDetail).length > 0 ?
                    <div className="detailpage">
                        <Paper className="detailpage_header">
                            <div className="header_img">
                                <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={() => arrowBackHandler()}></ArrowBackIcon>
                            </div>
                            <div className="header_img">
                               {/*  <img src={Acuvisorlogo} /> */}
                            </div>
                            <div className="header_img">
                               {/*  <img src={Acurightlogo} /> */}
                            </div>
                        </Paper>
                        <Card style={{ backgroundColor: "#0c409a", color: "white", padding: 20 }}>
                            <div className="pro_heading">
                                <div className="pro_image">
                                    <img className="bg-white img-thumbnail rounded" src={acuviserProductDetail.company_logo} />
                                </div>
                                <div>
                                    <div className="text-capitalize">{acuviserProductDetail.policy_name} - {quickQuoteDetails.coverType}</div>
                                    <div>{acuviserProductDetail.company_name} {/* - {quickQuoteDetails.coverType} */}</div>

                                </div>
                            </div>
                            <div className="details_container">
                                <div>
                                    <div>
                                        <div>Sum Assured</div>
                                        <div>&#8377; {quickQuoteDetails.sumInsuredValue /* || quickQuoteDetails?.sumInsured */}</div>
                                    </div>
                                </div>
                                <div className="linewithdata">
                                    <div className="line"></div>
                                    <div>
                                        <div>Premium Amount</div>
                                        <div>&#8377; {quickQuoteDetails.premium}</div>
                                    </div>
                                </div>
                                <div className="linewithdata">
                                    <div className="line"></div>
                                    <div>
                                        <div>Term</div>
                                        <div>{quickQuoteDetails.period} Years</div>
                                    </div>
                                </div>
                            </div>

                        </Card>
                        <div>
                            <p style={{ marginLeft: 20, marginTop: 20, fontSize: 20 }}>
                                {acuviserProductDetail.company_name}
                            </p>
                            {/* <iframe
                                //src="https://www.youtube.com/embed/E7wJTI-1dvQ"
                                src={acuviserProductDetail.video_url}
                                //frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="video"
                            /> */}
                            {console.log('video url', acuviserProductDetail.video_url)}
                            {console.log('gdg', videoUrl)}
                            <iframe id="video_url"
                                src={`https://www.youtube.com/embed/${videoUrl}`}
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="video"
                            />



                        </div>

                        <Card className="id_container">
                            <div style={{ marginLeft: 20, marginTop: 20 }}>
                                Document Needed
                            </div>
                            <DocumentsNeeded
                                doc1={acuviserProductDetail.document1}
                                doc2={acuviserProductDetail.document2}
                                doc3={acuviserProductDetail.document3}
                                doc4={acuviserProductDetail.document4}
                            />
                            {/* <div className="id_grid">
                                <div className='ids'>
                                    <CheckCircleIcon style={{ color: "#18c6b1" }} />
                                    Kyc-pan Card
                                </div>
                                <div className="ids">
                                    <CheckCircleIcon style={{ color: "#18c6b1" }} />
                                    Aadhaar Card
                                </div>
                                <div className="ids">
                                    <CheckCircleIcon style={{ color: "#18c6b1" }} />
                                    Passport
                                </div>
                            </div> */}

                        </Card>

                        <div className="pdf_container">
                            <PdfDocuments
                                doc1={acuviserProductDetail.document_pdf1}
                                doc2={acuviserProductDetail.document_pdf2}
                                doc3={acuviserProductDetail.document_pdf3}
                                doc4={acuviserProductDetail.document_pdf4}
                                doc5={acuviserProductDetail.document_pdf5}
                                docName1={acuviserProductDetail.document_pdfname1}
                                docName2={acuviserProductDetail.document_pdfname2}
                                docName3={acuviserProductDetail.document_pdfname3}
                                docName4={acuviserProductDetail.document_pdfname4}
                                docName5={acuviserProductDetail.document_pdfname5}
                            />
                        </div>

                        <div className="accor_container">
                            <p style={{ marginLeft: 20, marginTop: 20 }}>
                                Policy Highlight
                            </p>
                            <Highlights
                                highlights={acuviserProductDetail.highlight}
                            />
                        </div>
                        <div className="mt-3 btn-sec">
                            <button className="Goback_btn text-capitalize" onClick={() => arrowBackHandler()}>Go back</button>
                            <button className="submitBtn text-capitalize" onClick={() => buyPlanHandler()}>buy plan</button>
                        </div>
                    </div>
                    : <h3>Please wait</h3>

            }
        </>

    );
}

export default PolicyHighlightPage