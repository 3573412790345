import { Typography, Box } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../TextError";
import './FirmikField.css'

const FirmikFileField = (props) => {
  const {
    label,
    name,
    validation,
    options,
    dependent,
    hidden,
    arrayindex,
    manualValidation,
    errors,
    arrayvalues,
    len,
    ...rest
  } = props;

  return (
    <div className=" form-control ">
      <div className="">
        <div>
          <label>{label}</label>
        </div>
        <div>
          <Field
            name={name}
            {...rest}
           
          >
            {({ field, form, setErrors }) => {
              const { setFieldValue, setFieldError, values } = form;
              const fileToBase64 = (file, cb) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function () {
                  cb(null, reader.result)
                }
                reader.onerror = function (error) {
                  cb(error, null)
                }
              }
                //console.log('radio', field)
                const onUploadFileChange = ({ target }) => {
                  console.log(target.files[0],"fileConsole")
                  
                  if (target.files < 1 || !target.validity.valid) {
                    return
                  }
                  fileToBase64(target.files[0], (err, result) => {
                    if (result) {
                      setFieldValue(name,result)
                      // setFieldValue(name)
                    }
                  })
                }

                return (
                  <div
                    className="FileUpload"
                    
                  >
                    {
                      <Field
                        type="file"
                        className=" "
                        name={name}
                        id={field.name}
                        {...field}
                        onChange={onUploadFileChange}
                        value ={values[`${name}`]}
                      />
                   
                      
                    }

                    <label htmlFor={field.name}>{field.label}</label>
                  </div>
                );
              
            }}
          </Field>
        </div>
      </div>

      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FirmikFileField;
