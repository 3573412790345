import { Box, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../TextError";
import { useState } from "react";

const FormikSelect = (props) => {
  const {
    label,
    arrind,
    setPdata,
    name,
    validation,
    readOnly,
    options,
    parentname,
    arrayindex,
    errors,
    setProposer,
    arrayHelpers,
    arrayvalues,
    ...rest
  } = props;

  // console.log(parentname,"......")
  return (
    <div>
      <div>
        <Typography>{label}</Typography>

        <Field name={name}>
          {({ form, field }) => {
            const { setFieldValue, values, setFieldError } = form;

            var prevalue = 0;
            var selvalue = [];
            const handleChange = (val) => {

              /*  if(val.target.value==="901"){
                 const elem=document.getElementById(`Family[${arrind}]`)
                 const childs=elem.childNodes
                 for(let i=0;i<childs.length;i++){
                   if(childs[i].id==="DateofDeath" || childs[i].id==="DeathReason" || childs[i].id==="AgeatDeath"){
                     childs[i].setAttribute("hidden", true);
                   }
                   else if(childs[i].id==="age"){
                     childs[i].removeAttribute("hidden");
                     console.log(`${parentname}.${childs[i].id}`,"childname")
                     setFieldError(`${parentname}.${childs[i].id}`,"required")
                   }
                 }
             }
             else if(val.target.value==="902"){
               const elem=document.getElementById(`Family[${arrind}]`)
               const childs=elem.childNodes
               for(let i=0;i<childs.length;i++){
                 if(childs[i].id==="DateofDeath" || childs[i].id==="DeathReason" || childs[i].id==="AgeatDeath"){
                   childs[i].removeAttribute("hidden");
                   // console.log(`${parentname}.${childs[i].id}`,"childname")
                   setFieldError(`${parentname}.${childs[i].id}`,"required")
                 }
                 else if(childs[i].id==="age"){
                   childs[i].setAttribute("hidden",true);
                 }
               }
             }else if(val.target.value==="S"){
               setPdata(true)
             }else if(val.target.value==="O"){
               setPdata(false)
             } */

              if (name === `primaryDetails[0].Family[${arrayindex}].Status`) {
                if (val.target.value === '901') {
                  document.getElementById(`primaryDetails[0].Family[${arrayindex}].DeathReason`).closest('div').closest('.mb-3').setAttribute('hidden', true)
                  document.getElementById(`primaryDetails[0].Family[${arrayindex}].DateofDeath`).closest('div').closest('.mb-3').setAttribute('hidden', true)

                } else {
                  document.getElementById(`primaryDetails[0].Family[${arrayindex}].DeathReason`).closest('.mb-3').removeAttribute('hidden')
                  document.getElementById(`primaryDetails[0].Family[${arrayindex}].DateofDeath`).closest('.mb-3').removeAttribute('hidden')

                }
              }

              if (name === 'primaryDetails[0].LifeAssured.Nationality') {
                if (val.target.value === '501') {
                  document.getElementById('primaryDetails[0].LifeAssured.NRI_Country').closest('div').closest('.mb-3').setAttribute('hidden', true)
                  setFieldValue('primaryDetails[0].LifeAssured.NRI_Country', 'null')
                } else {
                  setFieldValue('primaryDetails[0].LifeAssured.NRI_Country', '')
                  document.getElementById('primaryDetails[0].LifeAssured.NRI_Country').closest('div').closest('.mb-3').removeAttribute('hidden')

                }
              }

             /*  if (name === `primaryDetails[0].LifeType`) {
                console.log('gsg', val.target.value)
                if (val.target.value === "S") {
                  setPdata(true)
                } else if (val.target.value === "O") {
                  setPdata(false)
                }
              } */
              if (name === "primaryDetails[0].LifeAssured.Occupation") {
                console.log('gsg', val.target.value)
                if (val.target.value === "837") {
                  setFieldValue('primaryDetails[0].LifeAssured.HusbandOccupation','')
                  setFieldValue('primaryDetails[0].LifeAssured.HusbandAnnualIncome','')
                  setFieldValue('primaryDetails[0].LifeAssured.HusbandInsuranceDetails','')

                  document.getElementById('primaryDetails[0].LifeAssured.HusbandOccupation').closest('.mb-3').removeAttribute('hidden')
                  document.getElementById('primaryDetails[0].LifeAssured.HusbandAnnualIncome').closest('.mb-3').removeAttribute('hidden')
                  document.getElementById('primaryDetails[0].LifeAssured.HusbandInsuranceDetails').closest('.mb-3').removeAttribute('hidden')
                }else{
                  document.getElementById('primaryDetails[0].LifeAssured.HusbandOccupation').closest('div').closest('.mb-3').setAttribute('hidden', true)
                  document.getElementById('primaryDetails[0].LifeAssured.HusbandAnnualIncome').closest('div').closest('.mb-3').setAttribute('hidden', true)
                  document.getElementById('primaryDetails[0].LifeAssured.HusbandInsuranceDetails').closest('div').closest('.mb-3').setAttribute('hidden', true)

                }

              }

              

            
            setFieldValue(name, val.target.value);
          };

          return (
          <>
            {readOnly !== undefined ? (
              <Field
                as="select"
                id={name}
                name={name}
                // disabled={Boolean(readOnly)}
                {...rest}
                placeholder="select"
                className="form-select mb-3 form-control"
                onChange={(val) => handleChange(val, name)}
              >
                {options.map((option) => {
                  return (
                    <option key={option.value} value={option.value} className="h6">
                      {option.label}
                    </option>
                  );
                })}
              </Field>
            ) : (
              <Field
                as="select"
                id={name}
                name={name}
                {...rest}
                placeholder="select"
                className="form-select mb-3 form-control"
                onChange={(val) => handleChange(val, selvalue)}
              >
                {options.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </Field>
            )}
          </>
          );
          }}
        </Field>
        <ErrorMessage name={name} component={TextError}></ErrorMessage>
      </div>
    </div>
  );
};

export default FormikSelect;
