import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import Axios from "axios";
import moment from "moment";
import { FieldArray, Form, Formik } from "formik";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

import createInitialValue from "../../Utilities/InitialValuesSetup/createInitialValue";
import automaticValidationSchema from "../../Utilities/ValidationSchemaSetup/automaticValidationSchema";
import FormikControl from '../../Components/LifeInsFormikComponent/FormikControl';
import OTP_Dialog from "../../Components/Modals/OTP_Dialog";
import uuid from "react-uuid";


const LifeInsuranceQQ = () => {

    const [productDetails, setProductDetails] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [verifyOTP, setVerifyOTP] = useState(false);
    const [OTPSuccessful, setOTPSuccessful] = useState(false);
    const [responseGetOTP, setResponseGetOTP] = useState();
    const [UserID, setuid] = useState();
    const [ApplicationNo, setAppno] = useState();
    const [initial, setInitial] = useState({
        mobileNumber: "",
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setVerifyOTP(true);
        setOpen(false);
    };
    const navigate = useNavigate();

    let initialValues = {};
  if (Object.keys(productDetails).length > 0) {
    
    initialValues = createInitialValue(productDetails[0]);
    console.log(initialValues, "initialvalues");
  }

  var validationSchema = Yup.object().shape({});
  if (Object.keys(productDetails).length > 0) {
    validationSchema = automaticValidationSchema(
      productDetails[0].requestBody.formFields.fields
    );
  }

  useEffect(() => {

    // setProductDetails(shriQuick);
    Axios.get("http://localhost:3002/api/v1/lifeInsurance/quick-quote").then(
      (response) => {
        if (response.status === 200) {
          console.log("response", response);
          setProductDetails(response.data);
        }
      }
    );
    setuid(uuid().split("-").join("").toUpperCase());
    setAppno(uuid().split("-").join("").toUpperCase().slice(0, 15));
  }, []);

  const onSubmit = (values) => {
    const withtime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    values = {
      ...values,
      ["UserID"]: "AXN80DMKYQ",
      ["PlanNo"]: "106",
      ["TranDate"]: withtime,
      ["BenefitOption"]: "",
      ["ApplicationNo"]: ApplicationNo,

    };
    delete values["TransactionDate"];
    console.log("Form submit", values);
    if (verifyOTP === false) {
      handleClickOpen();
    }
    if (OTPSuccessful === true) {
      Axios.post(
        "http://65.0.70.70:8000/api/life-insurance/quickquote/",
        //"http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/life-insurance/quickquote/",
        values
      ).then((response) => {
          if (
            response.data.responses.ResultSet.ResponseCode === "200"

          ) {

            console.log("response data", response.data)

            navigate(`/life-insurance/products-list/${response.data.response_id}`, { state: { value: initial.mobileNumber } });
          }
          else {
            alert(response.data.responses.ResultSet.ResponseMsg)
          }

        })
        .catch((e) => {
          console.log(e, "error from getotp");
        });

    }

    // alert(
    //   Object.entries(values).map((field, index) => {
    //     return `${field[0]}:${field[1]}`;
    //   })
    // );
    // navigate("/submitproposal");
  };


    return (
        <div id="content">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
              <div className="bg-white shadow-md rounded-card  pt-sm-4 pb-sm-5 px-sm-5 card-responsive ">
                <h3 className="fw-400 text-center my-4 heading-text">
                  Quick Quote
                </h3>
                {Object.keys(initialValues).length > 0 ? (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {(formik) => {
                      console.log("formik val", formik);
                      return (
                        <div className="justify-content-center ">
                          <Form>
                            {productDetails.length > 0
                              ? productDetails[0].requestBody.formFields.fields.map(
                                (field, index) => {
                                  return field.htmlElement === "TextField" ? (
                                    <div
                                      className="mb-3"
                                      key={index}
                                      hidden={field.hidden}
                                    >
                                      <FormikControl
                                        control="input"
                                        errors={formik.errors}
                                        name={field.name}
                                        readOnly={field.readOnly}
                                        url={field.url}
                                        label={field.label}
                                        type={field.dataType}
                                        touched={formik.touched}
                                      />
                                    </div>
                                  ) : field.htmlElement === "Select" ? (
                                    <div
                                      className="mb-3"
                                      key={index}
                                      hidden={field.hidden}
                                    >
                                      <FormikControl
                                        control="select"
                                        name={field.name}
                                        errors={formik.errors}
                                        readOnly={field.readOnly}
                                        url={field.url}
                                        label={field.label}
                                        type={field.dataType}
                                        options={field.options}
                                        touched={formik.touched}
                                      />
                                    </div>
                                  ) : field.htmlElement === "Radio" ? (
                                    <div
                                      className="mb-3"
                                      key={index}
                                      hidden={field.hidden}
                                    >
                                      <FormikControl
                                        control="radio"
                                        name={field.name}
                                        errors={formik.errors}
                                        readOnly={field.readOnly}
                                        url={field.url}
                                        label={field.label}
                                        type={field.dataType}
                                        options={field.options}
                                        touched={formik.touched}
                                      />
                                    </div>
                                  ) : null;
                                }
                              )
                              : null}
  
                            <div className="d-grid mb-3 ">
                              {console.log("isSubmit", formik.isSubmitting)}
  
                              <button className="submitBtn mt-4" type="submit">
                                Submit
                              </button>
                            </div>
                          </Form>
                        </div>
                      );
                    }}
                  </Formik>
                ) : (
                  <Typography>Loading....</Typography>
                )}
  
                <OTP_Dialog
                  OTPSuccessful={OTPSuccessful}
                  setOTPSuccessful={setOTPSuccessful}
                  setResponseGetOTP={setResponseGetOTP}
                  responseGetOTP={responseGetOTP}
                  ApplicationNo={ApplicationNo}
                  open={open}
                  onClose={handleClose}
                  initial={initial}
                  setInitial={setInitial}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default LifeInsuranceQQ