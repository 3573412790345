import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Link,useParams } from "react-router-dom";
import { CircularProgress, Card } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import Axios from "axios";
import CryptoJS from 'crypto-js'
import SortHealthInsureProduct from "../../Components/Modals/SortHealthInsureProduct";
import '../../Assets/css/listOfProducts.css'



const ListOfProducts = () => {

    let navigate = useNavigate();


    const [data, setData] = useState([]);
    const [productData, setProductData] = useState([])
    const [activeindex, setactiveindex] = useState();
    const [dialogState, setDialogState] = useState(false);


    const { responseId } = useParams();
    const responseIdURI = decodeURIComponent(responseId).toString()
    const bytesResponseIdURI = CryptoJS.AES.decrypt(responseIdURI, 'secret key 124');
    const decryptedResponseId = JSON.parse(bytesResponseIdURI.toString(CryptoJS.enc.Utf8));


    console.log('responseId', responseId)
    console.log('decryptedResponseId', decryptedResponseId)


    const goBack = () => {
        navigate(-1);
    }

    const handleSearch = (val) => {
        console.log('search val', val.target.value, data)
        let incomingVal = val.target.value;
        let filterData = data.filter((item) => {
            if (incomingVal === '') {
                return item
            } else if (item.product_name.toLowerCase() === (incomingVal.toLowerCase())) {
                return item
            }
        })
        console.log('kkk', filterData.length)
        if (filterData.length > 0) {
            setProductData(filterData)
        } else {
            setProductData([])
        }
    }


    const sortByPremium = (val) => {
        console.log('sss', val)
        if (val === 'LOW') {

            let filterData = data.sort((a, b) => {
                return parseFloat(a.premium) - parseFloat(b.premium);
            })
            setProductData(filterData)
            console.log('low sort', filterData)

        } else {
            let filterData = data.sort((a, b) => {
                return parseFloat(b.premium) - parseFloat(a.premium);
            })
            setProductData(filterData)
        }
    }
    const handleSort = () => {
        console.log('sort')
        setDialogState(true)
    }
    const handleCloseSort = () => {
        setDialogState(false)
    };


    const getPolicy = (index) => {
        let passData = []
        console.log('index', index)

        /*  const selectedProdata=data[activeindex]
         console.log("....",data,activeindex,selectedProdata)
         navigate(`/policy_detail/${selectedProdata.product_ref}`,{state:{S_data:selectedProdata}}); */

        productData.map((item, itemIndex) => {

            if (itemIndex === index) {
                console.log('ITEM', item)
                //console.log('responseId', responseId)
                console.log('ref', item.product_ref)
                let encryptedAcuvisor_product_id = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(item.Acuvisor_product_id), 'secret key @124').toString());
                //console.log('ref', item.period)
                let encryptedProduct_ref = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(item.product_ref), 'secret key @123').toString());
                // console.log('encry Prod ref', encryptedProduct_ref)

              

                 if (window.location.hostname === 'localhost') {
                    Axios.get(`http://localhost:8000/api/health/quickquote-details/${decryptedResponseId}/${item.product_id}/`).then((response) => {
                        if (response.status === 200) {
                            console.log('response  from django', response)

                            let encryptedDataId = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(response.data.id), 'secret key @124').toString());
                            console.log('encry Respnse Daat Id', encryptedDataId)

                            let path = `/health-insurance/product-detail/${encryptedProduct_ref}*${encryptedDataId}*${encryptedAcuvisor_product_id}`;

                            navigate(path)
                        }
                    })
                } else {
                    Axios.get(`http://65.0.70.70:8000/api/health/quickquote-details/${decryptedResponseId}/${item.product_id}/`).then((response) => {
                        if (response.status === 200) {
                            console.log('response  from django', response)

                            let encryptedDataId = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(response.data.id), 'secret key @124').toString());
                            console.log('encry Respnse Daat Id', encryptedDataId)



                            let path = `/health-insurance/product-detail/${encryptedProduct_ref}*${encryptedDataId}*${encryptedAcuvisor_product_id}`;

                            navigate(path)
                        }
                    })
                } 
            }

            //   if (index === data.length - 1) {
            //    passData.push({ responseid: responseId, productcode: item.product_code, plan: item.plan, product_ref: item.product_ref })

            //  }



        })
        // console.log('pass', passData)
        // console.log('responseid', passData[0].responseid)
        //console.log('id',passData[0])
        /*  if (passData.length > 0) {
           Axios.get(`http://127.0.0.1:8000/api/health/quickquote-details/${passData[0].responseid}/${passData[0].productcode}/${passData[0].plan}/`).then((response) => {
             if (response.status === 200) {
               console.log('response', response)
     
               console.log('prod ref', passData[0].product_ref)
     
               //console.log('JSON', JSON.parse(response.data))
               let path = `/product_details/${passData[0].product_ref}+${response.data.id}`;
               navigate(path)
             }
           })
         }
      */

    };



    useEffect(() => {
        // setData(Data.responses);
        // console.log(data)

      

        if (window.location.hostname === 'localhost') {
            Axios.get(`http://localhost:3001/api/v1/healthCare/quickQuoteList/${decryptedResponseId}`).then((response) => {
                if (response.status === 200) {

                    console.log('response', response.data)
                    let dd = response.data;
                    dd.map((item) => {
                        // console.log(item)
                        if (item.response.response_id === decryptedResponseId) {
                            console.log("data:", item)
                            setData(item.response.responses)
                            setProductData(item.response.responses)
                        }
                    })
                    // productDetailsArray.push(response.data)
                    //setProductDetails(productDetailsArray);
                    // setProductDetails(response.data);
                    // setData(response.data[0]["response"]["responses"])
                }

            })
        } else {
            Axios.get(`http://65.0.70.70:81/api/v1/healthCare/quickQuoteList/${decryptedResponseId}`).then((response) => {
                if (response.status === 200) {

                    console.log('response', response.data)
                    let dd = response.data;
                    dd.map((item) => {
                        // console.log(item)
                        if (item.response.response_id === decryptedResponseId) {
                            console.log("data:", item)
                            setData(item.response.responses)
                            setProductData(item.response.responses)

                        }
                    })
                    // productDetailsArray.push(response.data)
                    //setProductDetails(productDetailsArray);
                    // setProductDetails(response.data);
                    // setData(response.data[0]["response"]["responses"])
                }

            })
        } 


    }, []);


    return (
        <div className="">
            <div className="header-text">
                Plan Suggested For You
                <KeyboardArrowDownIcon />
            </div>
            <div className="form-container">

                <div className="searchbox_container">
                    <div className="search_box">
                        {""}
                        <SearchIcon></SearchIcon>
                        <input type="search"
                            className='input_searchbox'
                            placeholder="Search for plans"
                            onChange={(val) => handleSearch(val)}

                        />

                    </div>

                    <button className="sort_btn" onClick={handleSort}>sort</button>

                    <SortHealthInsureProduct
                        open={dialogState}
                        close={handleCloseSort}

                        sortByPremium={sortByPremium}
                    />
                </div>
                <div >

                    {productData &&
                        productData.length > 0 ?
                        productData.map((item, index) => (
                            <Card className={activeindex === index ? "card-Sec_active" : "card-Sec"} key={index} onClick={() => { setactiveindex(index) }}>
                                <div className="pro_img">
                                    <img className=" rounded " src={item.logo} />
                                </div>
                                <div className="pro_content">
                                    <div className="p_namepart">
                                        <div className="Care_text">{item.product_name}</div>
                                        {activeindex === index ? <CheckCircleIcon className="circle_tic" /> : <CheckCircleOutlineRoundedIcon />}
                                    </div>
                                    <div>
                                        <div id="pro_name" >{item.product_name}-{item.coverType}</div>
                                    </div>
                                    <div className="Amount">
                                        <div>
                                            <div className="Care_text">Sum Assured</div>
                                            <div className="Care_text">
                                                <b className="rupee_symp">&#8377;</b>
                                                {item.sumInsuredValue}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="Care_text">Premium Amount</div>
                                            <div className="Care_text"><b className="rupee_symp">&#8377;</b> {item.premium}</div>
                                        </div>
                                    </div>
                                </div>
                            </Card>

                        ))
                        :
                        <div className="text-center m-4 p-4">
                            <CircularProgress />
                        </div>
                    }
                </div>




                <div className="mt-3 btn-sec  ">
                    <button className="Goback_btn text-capitalize" onClick={() => goBack()}>Go back</button>

                    <button className="submitBtn text-capitalize"
                        onClick={() => getPolicy(activeindex)}
                        disabled={activeindex === undefined ? true : false}
                    >Buy Now</button>

                </div>
            </div>
        </div>
    )
}

export default ListOfProducts