import { FieldArray } from 'formik';
import React from 'react'
import FormikControl from '../../Components/HealthInsFormikComponent/FormikControl';
import CreateQuoteDependent from './Dependents/CreateQuoteDependent';
import CreateQuoteObjectView from './Dependents/CreateQuoteObjectView';
import _ from "lodash";


const ArrayMainComponent = ({ Fields, activeStep, depInd, quickQuoteDetails, formik }) => {
    console.log('hggg', Fields)
    return (
        <>
            <FieldArray
                name={Fields[activeStep].name}
                touched={formik.touched}
                render={(arrayHelpers) => {
                    //  console.log(depInd, "888", Fields[activeStep].name)
                    return (
                        <div>
                            {_.times(Fields[activeStep].name === "primaryDetail" ? depInd : 1, (i) => {
                                const objName = Fields[activeStep].name;
                                const arrObjName = `${objName}[${i}]`;
                                //  console.log("name...",objName,arrObjName)
                                return (
                                    <div key={i}>
                                        {
                                            Fields[activeStep].name === "primaryDetail" ?
                                                <><h3 className='text-capitalize'>primary detail  {i + 1}</h3></>
                                                : null
                                        }
                                        {Fields[activeStep].obj.map(
                                            (field, index) => {
                                                return field.htmlElement ===
                                                    "TextField" ? (
                                                    <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                    >
                                                        <FormikControl
                                                            control="input"
                                                            name={`${arrObjName}.${field.name}`}
                                                            /*  manualValidation={
                                                               field.manualValidation
                                                             } */
                                                            //errors={formik.errors}
                                                            readOnly={field.readOnly}
                                                            /*  transperData={
                                                               productDetails.transperData
                                                             }*/
                                                            url={field.url}
                                                            label={field.label}
                                                            type={field.dataType}
                                                        // touched={formik.touched}
                                                        />
                                                    </div>
                                                ) : field.htmlElement ===
                                                    "Select" ? (
                                                    <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                    >
                                                        <FormikControl
                                                            control="select"
                                                            readOnly={field.readOnly}
                                                            arrayindex={i}
                                                            quickQuoteDetails={quickQuoteDetails}
                                                            name={`${arrObjName}.${field.name}`}
                                                            //errors={formik.errors}
                                                            label={field.label}
                                                            type={field.dataType}
                                                            //touched={formik.touched}
                                                            options={field.options}
                                                        />
                                                    </div>
                                                ) : field.htmlElement ===
                                                    "TextArea" ? (
                                                    <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                    >
                                                        <FormikControl
                                                            control="textarea"
                                                            name={`${arrObjName}.${field.name}`}
                                                            //errors={formik.errors}
                                                            label={field.label}
                                                            type={field.dataType}
                                                        //touched={formik.touched}
                                                        />
                                                    </div>
                                                ) : field.htmlElement ===
                                                    "Radio" ? (
                                                    <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                    >
                                                        <FormikControl
                                                            control="radio"
                                                            name={`${arrObjName}.${field.name}`}
                                                            //errors={formik.errors}
                                                            dependent={
                                                                field.dependent
                                                            }

                                                            options={field.options}
                                                            manualValidation={
                                                                field.manualValidation
                                                            }
                                                            arrayindex={i}
                                                            label={field.label}
                                                            type={field.dataType}
                                                        //touched={formik.touched}
                                                        />
                                                    </div>
                                                ) : field.htmlElement ===
                                                    "DatePicker" ? (
                                                    <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                    >
                                                        <FormikControl
                                                            control="date"
                                                            name={`${arrObjName}.${field.name}`}
                                                            //errors={field.errors}
                                                            readOnly={field.readOnly}
                                                            label={field.label}
                                                            type={field.dataType}
                                                        />
                                                    </div>
                                                ) : field.htmlElement ===
                                                    "checkbox" ? (
                                                    <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                    >
                                                        <FormikControl
                                                            control="checkBox"
                                                            name={`${arrObjName}.${field.name}`}
                                                            //errors={field.errors}
                                                            arrayindex={i}
                                                            label={field.label}
                                                            type={field.dataType}
                                                            options={field.options}
                                                        />
                                                    </div>
                                                )
                                                    : field.htmlElement === 'MultiSelect' ?
                                                        <div className="mb-3" key={index} hidden={field.hidden}>

                                                            <FormikControl
                                                                control='MultiSelect'
                                                                readOnly={field.readOnly}
                                                                name={field.name}
                                                                //errors={formik.errors}
                                                                label={field.label}
                                                                type={field.dataType}
                                                                //touched={formik.touched}
                                                                options={field.options}
                                                            //onChange={(e)=>onChange(e,field.name)}  
                                                            //onChange={(e) => formik.handleChange(e)}

                                                            />

                                                        </div>
                                                        : field.htmlElement ===
                                                            "Array" ? (
                                                            <div
                                                                className="mb-3"
                                                                key={index}
                                                                style={{
                                                                    marginTop: "24px",
                                                                }}
                                                            >
                                                                <h3 className="py-3 second-heading-text">
                                                                    {field.label}
                                                                </h3>
                                                                <div className="mb-3">
                                                                    <CreateQuoteDependent
                                                                        field={field}
                                                                        name={`${arrObjName}`}
                                                                        previousIndex={i}
                                                                        dependentIndex={1}
                                                                        quickQuoteDetails={quickQuoteDetails}

                                                                    /*  transperData={
                                                                       productDetails.transperData
                                                                     } */
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : field.htmlElement ===
                                                            "Object" ? (
                                                            <div
                                                                className="mb-3"
                                                                key={index}
                                                                style={{
                                                                    marginTop: "24px",
                                                                }}
                                                            >
                                                                <CreateQuoteObjectView
                                                                    data={field}
                                                                    quickQuoteDetails={quickQuoteDetails}

                                                                />
                                                            </div>
                                                        ) : null;
                                            }
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    );
                }}
            ></FieldArray>
        </>

    )
}

export default ArrayMainComponent