import automaticValidationSchema from "../../../Utilities/ValidationSchemaSetup/automaticValidationSchema";
import React, { useEffect, useState } from "react";
import createInitialValue from "../../../Utilities/InitialValuesSetup/createInitialValue";
import { useParams } from "react-router-dom";
import Axios from "axios";
import ShriRamreqbodyGen from "../../../Utilities/LifeInsuranceRequestBodies/CreateQuoteRequestBodies/ShriRamReqBodyGen";
import '../../../Assets/css/LifeInsurance/CreateQuoteForm.css';
// import PEDvalues2 from "../JSONbodies/PED2";
//import SPJson from "../JSONbodies/submitproposalJson";
import _ from "lodash";
import CircularProgressWithLabel from "../../../Components/CircularProgress/CircularProgressWithLabel";
import { FieldArray, Form, Formik } from "formik";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import CreateQuoteObject from "../CreateQuoteObject";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import CheckIcon from '@mui/icons-material/Check';
import CreateQuoteArray from "../CreateQuoteArray";
import FormikControl from "../../../Components/LifeInsFormikComponent/FormikControl";
// import JSON_TO_YUP from "./FormikComponents/YupValidation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import CreateQuoteDependent from "./QuickQoutes/CreateQuoteDependent";
// import CreateQuoteObjectView from "./FormikComponents/CreatQuoteChilds/CreateQuoteObjectView";
import { useNavigate } from 'react-router';

function CreateQuoteForm(props) {
  const [productName, setProductName] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [requestBody, setrequestBody] = useState({});
  const [newRequestBody, setNewRequestBody] = useState({});
  const [productDetails, setProductDetails] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [depInd, setdepInd] = useState(1);
  const [progress, setProgress] = React.useState(0);
  const { productId } = useParams();
 const[familyArray,setfamilyArray]=useState(2)

 const payobject={
  TransationAmount: "10450",
 TransactionRefNo: "072022SLICPP1048257",
 TransactionDate: "2022-07-20"

}


  let navigate = useNavigate();
  var initialValues;
  var FinalIni;

  if (Object.keys(productDetails).length > 0) {
    console.log("count....")
    initialValues = createInitialValue(productDetails);
    FinalIni=initialValues;
    FinalIni.primaryDetails[0].Payment=payobject
  }

  var Fields = [];
  if (Object.keys(productDetails).length > 0) {
    productDetails.requestBody.formFields.fields.map((field, index) => {
      Fields.push(field);
    });
  }
  // console.log("Fields", Fields);

  if (Object.keys(productDetails).length > 0) {
    var SchemaArray = [];
    productDetails.requestBody.formFields.fields.map((field, index) => {
      let seperatearray = [];

      seperatearray.push(field);

      SchemaArray.push(seperatearray);
    });
  }

  var validationSchema = Yup.object().shape({});
  if (Object.keys(productDetails).length > 0) {
    validationSchema = automaticValidationSchema(SchemaArray[activeStep]);
  }
  // console.log("validSchema", validationSchema);

  const utility =
    "https://acuvisor.auxtomate.in/assets/js/proposalform/General-2.js";

  useEffect(() => {
    let productDetailsArray = [];
    // const timer = setInterval(() => {
    //   setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    // }, 800);
    // return () => {
    //   clearInterval(timer);
    // };
    setdepInd(1);
    //setProductDetails(SPJson);
    let utilityScript = document.createElement("script");
    utilityScript.src = utility;
    document.body.appendChild(utilityScript);
  }, []);

  const onSubmit = (values,actions) => {
    if (isLastStep()) {
      console.log(values,"Submit form data")
      const ShriRamValues = ShriRamreqbodyGen(values);
      console.log(ShriRamValues, "FinalRequest");
      navigate("/stringify",{state:{value:ShriRamValues}})

Axios.post("http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/life-insurance/submit-proposal/",ShriRamValues).then(request=>{
  console.log(request,"request in submit proposal")
})

      // setProgress(100)  
      // setActiveStep(activeStep);

     
    } else {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 25));
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
      // const newActiveStep =
      // isLastStep() && !allStepsCompleted()
      //   ? steps.findIndex((step, i) => !(i in completed))
      //   : activeStep + 1;
      //   setActiveStep(newActiveStep)
      
  };
  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  const handleBack = () => {
    setActiveStep((prevActiveStep) =>prevActiveStep===0?activeStep: prevActiveStep - 1);
  };
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const iniVal = initialValues;
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:"#00008B",
      // theme.palette.mode === "dark" ? theme.palette.grey[700] : "",
    zIndex: 1,
    color: "white",
    width: 110,
    height: 38,
    fontSize:10,
    display: "flex",

    justifyContent: "center",

    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "white",
      color: "black",
      borderWidth: "solid",
      borderColor: "black",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#00008B",
      color: "white",
      fontSize:10
    }),
  }));
  const steps = ["PRIMARY DETAILS", "PED QUESTION", "PROPOSER DETAILS","NOMINEE DETAILS"];
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: "PRIMARY DETAILS",
      2: "PED QUESTION",
      3: "DEPENDENT DETAILS",
      4: "NOMINEE DETAILS"
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <>
      <div className="header-sec">
      </div>
      <div id="content">
        <div className="container py-3">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
              <h3 className="fw-400 text-center my-5 heading-text">
                Submit Proposal Form
              </h3>
              <div className="Form_container">
              <div className="mx-auto stepper-container">
                <div className="Stepper_container">
                    <div className="steppers">
                      {
                        steps.map((field,index)=>{
                          return(
                            <div className={activeStep===index?"active":`step${index}`}>
                            {field}
                          </div>
                          )
                          
                        })
                      }

                    </div>
                    <div className="circularRep">
                      <div className="circular">
                      {
                      progress!==100?<CircularProgressWithLabel value={progress}/>:<CheckIcon/>
                      }
                      </div>
                    
                    {steps[activeStep]}
                    </div>
                </div>
                <p className="stepper"></p>
              </div>
              <div className="bg-white shadow-md rounded-card  pt-sm-4 pb-sm-5 px-sm-5 card-responsive">
                {initialValues ? (
                  <Formik
                    initialValues={FinalIni}
                    // validationSchema={validationSchema}
                    enableReinitialize={false}
                    onSubmit={onSubmit}
                  >
                    {(formik) => {
                      console.log("formik val", formik);
                      var newTouch = {};
                     

                      return (
                        <Form autoComplete="off">
                          <div>
                            {Object.keys(Fields[activeStep]).length > 0 ? (
                              <FieldArray
                                name={Fields[activeStep].name}
                                render={(arrayHelpers) => {
                                  console.log(depInd, "888");
                                  return (
                                    <div>
                                      {_.times(depInd, (i) => {
                                        const objName = Fields[activeStep].name;
                                        const arrObjName = `${objName}[${i}]`;
                                        //  console.log("name...",objName,arrObjName)
                                        return (
                                          <div>
                                            {Fields[activeStep].obj.map(
                                              (field, index) => {
                                                return field.htmlElement ===
                                                  "TextField" ? (
                                                  <div
                                                    className="mb-3"
                                                    key={index}
                                                    hidden={field.hidden}
                                                  >
                                                    <FormikControl
                                                      control="input"
                                                      name={`${arrObjName}.${field.name}`}
                                                      manualValidation={
                                                        field.manualValidation
                                                      }
                                                      errors={formik.errors}
                                                      readOnly={field.readOnly}
                                                      transperData={
                                                        productDetails.transperData
                                                      }
                                                      url={field.url}
                                                      label={field.label}
                                                      type={field.dataType}
                                                      touched={formik.touched}
                                                    />
                                                  </div>
                                                ) : field.htmlElement ===
                                                  "Select" ? (
                                                  <div
                                                    className="mb-3"
                                                    key={index}
                                                    hidden={field.hidden}
                                                  >
                                                    <FormikControl
                                                      control="select"
                                                      readOnly={field.readOnly}
                                                      name={`${arrObjName}.${field.name}`}
                                                      errors={formik.errors}
                                                      label={field.label}
                                                      type={field.dataType}
                                                      touched={formik.touched}
                                                      options={field.options}
                                                    />
                                                  </div>
                                                ) : field.htmlElement ===
                                                  "TextArea" ? (
                                                  <div
                                                    className="mb-3"
                                                    key={index}
                                                    hidden={field.hidden}
                                                  >
                                                    <FormikControl
                                                      control="textarea"
                                                      name={`${arrObjName}.${field.name}`}
                                                      errors={formik.errors}
                                                      label={field.label}
                                                      type={field.dataType}
                                                      touched={formik.touched}
                                                    />
                                                  </div>
                                                ) : field.htmlElement ===
                                                  "Radio" ? (
                                                  <div
                                                    className="mb-3"
                                                    key={index}
                                                    hidden={field.hidden}
                                                  >
                                                    <FormikControl
                                                      control="radio"
                                                      name={`${arrObjName}.${field.name}`}
                                                      errors={formik.errors}
                                                      dependent={
                                                        field.dependent
                                                      }
                                                      options={field.options}
                                                      manualValidation={
                                                        field.manualValidation
                                                      }
                                                      label={field.label}
                                                      type={field.dataType}
                                                      touched={formik.touched}
                                                    />
                                                  </div>
                                                ) : field.htmlElement ===
                                                  "DatePicker" ? (
                                                  <div
                                                    className="mb-3"
                                                    key={index}
                                                    hidden={field.hidden}
                                                  >
                                                    <FormikControl
                                                      control="date"
                                                      name={`${arrObjName}.${field.name}`}
                                                      errors={field.errors}
                                                      readOnly={field.readOnly}
                                                      label={field.label}
                                                      type={field.dataType}
                                                    />
                                                  </div>
                                                ) : field.htmlElement ===
                                                  "checkbox" ? (
                                                  <div
                                                    className="mb-3"
                                                    key={index}
                                                    hidden={field.hidden}
                                                  >
                                                    <FormikControl
                                                      control="checkBox"
                                                      name={`${arrObjName}.${field.name}`}
                                                      errors={field.errors}
                                                      label={field.label}
                                                      type={field.dataType}
                                                      options={field.options}
                                                    />
                                                  </div>
                                                ) : 
                                                field.htmlElement ===
                                                  "Object" ? (
                                                  <div
                                                    className="mb-3"
                                                    key={index}
                                                    hidden={field.hidden}
                                                  >
                                                    <CreateQuoteObject
                                                      name={`${arrObjName}.${field.name}`}
                                                      Field={field}
                                                    />
                                                  </div>)
                                                :
                                                field.htmlElement ===
                                                "Array" ? (
                                                <div
                                                  className="mb-3"
                                                  key={index}
                                                  hidden={field.hidden}
                                                >
                                                  <CreateQuoteArray
                                                    name={`${arrObjName}`}
                                                    Field={field}
                                                    familyArray={familyArray}
                                                    setfamilyArray={setfamilyArray}
                                                  />
                                                </div>)
                                                : field.htmlElement ===
                                                "File" ? (
                                                <div
                                                  className="mb-3"
                                                  key={index}
                                                  hidden={field.hidden}
                                                >
                                                  <FormikControl
                                                      control="file"
                                                      name={`${arrObjName}.${field.name}`}
                                                      errors={field.errors}
                                                      label={field.label}
                                                      type={field.dataType}
                                                      options={field.options}
                                                    />
                                                </div>):null;
                                              }
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                }}
                              ></FieldArray>
                            ) : null}
                          </div>

                          <div className="button-sec">
                              <button className=" btn" type="button"
                              onClick={handleBack}
                              >
                               Back
                              </button>
                              <button
                                className="btn"
                                type="submit"
                                
                              >
                               {
                                isLastStep()?"Submit":" Next"
                              }
                              </button>
                            </div>
                        </Form>
                      );
                    }}
                  </Formik>
                ) : (
                  <Typography>Loading....</Typography>
                )}
              </div>
              </div>
             

              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateQuoteForm;
