import React from 'react'
import TextField from '@mui/material/TextField';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, } from '@mui/x-date-pickers/DatePicker';
import { ErrorMessage } from 'formik';
import moment from 'moment';
import {  Input, InputBase } from '@mui/material';
import {alpha,styled} from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const CustomeDatePicker = ({ field, form, ...props }) => {

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 16,
          width: 'auto',
          padding: '10px 12px',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }));


      const RedditTextField = styled((props) => (
        <TextField InputProps={{ disableUnderline: true }} {...props} />
      ))(({ theme }) => ({
        '& .MuiFilledInput-root': {
          border: '1px solid #e2e2e1',
          overflow: 'hidden',
          borderRadius: 4,
          backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ]),
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
          },
          "& label.Mui-focused": {
            display: "none"
          },
          "& legend": {
            display: "none"
          }
        },
      }));

    //console.log('fff',field,props)

    const currentError = form.errors[field.name];
    const currentTouch = form.touched[field.name];
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
       disableFuture
       id={field.name}
        
        inputFormat="DD-MMM-YYYY"
        value={field.value}
        name={field.name}
        touched={Boolean(currentTouch)}
        {...field} 
       // InputLabelProps={{ shrink: true }}
       {...form}
        //{...props}
         onChange={(newValue) => {
            //console.log('hdhhdh',moment(newValue).format("DD-MMM-YYYY"))
          form.setFieldValue(field.name,moment(newValue).format("YYYY-MM-DD"))
        }} 
        
       
        renderInput={(params) => {
            //console.log('param',params)
            return(
                
                <TextField 
        //touched={form.touched}
        name={field.name}
        id={field.name}
                 sx={{
                    /* "& label.Mui-focused": {
                      display: "none"
                    }, */
                    
                    "& legend": {
                      display: "none"
                    },
                    '& .MuiOutlinedInput-root': {
                       
                        "&.Mui-error fieldset ": {
                            borderColor: "rgba(0, 0, 0, 0.23) !important"  // default
                          },
                        
                        
                       
                      },
                      
                     
                  }}
                  //error={Boolean(currentError)}
                  
                  fullWidth
                  {...params}  />
            )
        }}
      />
     
    </LocalizationProvider>
  )
}

export default CustomeDatePicker