import { Typography, Box } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../TextError";

const FormikRadio = (props) => {
  const {
    label,
    name,
    validation,
    options,
    dependent,
    hidden,
    arrayindex,
    manualValidation,
    errors,
    arrayvalues,
    len,
    ...rest
  } = props;
console.log(name,options,"uuuuu")
  return (
    <div className=" form-control ">
      <div className="d-flex justify-content-between">
        <div>
          <label>{label}</label>
        </div>
        <div>
          <Field
            name={name}
            {...rest}
           
          >
            {({ field, form, setErrors }) => {
              const { setFieldValue, setFieldError, values } = form;

              const handleChange = (val) => {
                console.log('name in radio', name)
                console.log('val in radio', val.target.value)
               
                if (name === 'pedQuestionnPrimary[0].GQ_IsDeformity.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDeformity.Reason', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDeformity.Type', '')
                    console.log('hhf', document.getElementById('pedQuestionnPrimary[0].GQ_IsDeformity.Reason').closest('.mb-3'))
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsDeformity.Reason').closest('.mb-3').removeAttribute('hidden')
                    console.log('doc', document.getElementById('pedQuestionnPrimary[0].GQ_IsDeformity.Type').closest('div').closest('.mb-3'))
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsDeformity.Type').closest('div').closest('.mb-3').removeAttribute('hidden')


                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDeformity.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDeformity.Type', '')

                  } else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsDeformity.Reason').closest('.mb-3').setAttribute('hidden', true)
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsDeformity.Type').closest('div').closest('.mb-3').setAttribute('hidden', true)

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDeformity.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDeformity.Type', 'null')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDeformity.Reason', '')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].GQ_IsLeave.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsLeave.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsLeave.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsLeave.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsLeave.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsLeave.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsLeave.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsLeave.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsLeave.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].GQ_IsTreatment.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTreatment.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsTreatment.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTreatment.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTreatment.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsTreatment.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTreatment.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTreatment.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTreatment.Reason', 'null')

                  }
                }

                if (name === 'pedQuestionnPrimary[0].FQ_IsGynaecological.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsGynaecological.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsGynaecological.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].FQ_IsGynaecological.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsGynaecological.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsGynaecological.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsGynaecological.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsGynaecological.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsGynaecological.Reason', 'null')

                  }
                }

                if (name === 'pedQuestionnPrimary[0].FQ_IsMiscarriages.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsMiscarriages.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsMiscarriages.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].FQ_IsMiscarriages.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsMiscarriages.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsMiscarriages.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsMiscarriages.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsMiscarriages.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsMiscarriages.Reason', '')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].GQ_IsAdventurious.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAdventurious.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsAdventurious.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAdventurious.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAdventurious.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsAdventurious.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAdventurious.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAdventurious.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAdventurious.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].GQ_IsCriminal.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsCriminal.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsCriminal.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsCriminal.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsCriminal.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsCriminal.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsCriminal.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsCriminal.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsCriminal.Reason', 'null')

                  }
                }

                if (name === 'pedQuestionnPrimary[0].GQ_IsExistRejected.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsExistRejected.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsExistRejected.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsExistRejected.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsExistRejected.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsExistRejected.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsExistRejected.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsExistRejected.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsExistRejected.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsAliment.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAliment.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsAliment.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAliment.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAliment.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsAliment.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAliment.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAliment.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAliment.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsAnaemia.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAnaemia.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsAnaemia.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAnaemia.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAnaemia.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsAnaemia.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAnaemia.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAnaemia.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAnaemia.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsAsthma.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAsthma.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsAsthma.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAsthma.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAsthma.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsAsthma.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAsthma.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAsthma.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAsthma.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsAdventurous.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAdventurous.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsAdventurous.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAdventurous.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAdventurous.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsAdventurous.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAdventurous.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAdventurous.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsAdventurous.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsENT.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsENT.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsENT.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsENT.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsENT.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsENT.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsENT.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsENT.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsENT.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsGoodhealth.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsGoodhealth.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsGoodhealth.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsGoodhealth.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsGoodhealth.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsGoodhealth.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsGoodhealth.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsGoodhealth.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsGoodhealth.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsHIV.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHIV.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsHIV.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHIV.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHIV.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsHIV.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHIV.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHIV.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHIV.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsHernia.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHernia.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsHernia.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHernia.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHernia.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsHernia.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHernia.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHernia.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHernia.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsHospital.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHospital.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsHospital.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHospital.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHospital.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsHospital.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHospital.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHospital.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsHospital.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].MQ_IsNervous.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsNervous.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsNervous.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].MQ_IsNervous.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsNervous.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].MQ_IsNervous.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsNervous.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsNervous.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].MQ_IsNervous.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].FQ_IsdeliveryDate.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsdeliveryDate.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsdeliveryDate.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].FQ_IsdeliveryDate.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsdeliveryDate.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsdeliveryDate.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsdeliveryDate.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsdeliveryDate.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsdeliveryDate.Reason', 'null')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].FQ_IsPregnant.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsPregnant.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsPregnant.Reason').closest('.mb-3').removeAttribute('hidden')
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsPregnant.Quantity').closest('.mb-3').removeAttribute('hidden')
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsPregnant.Type').closest('.mb-3').removeAttribute('hidden')


                    setFieldValue('pedQuestionnPrimary[0].FQ_IsPregnant.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsPregnant.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsPregnant.Reason').closest('.mb-3').setAttribute('hidden', true)
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsPregnant.Quantity').closest('.mb-3').setAttribute('hidden', true)
                    document.getElementById('pedQuestionnPrimary[0].FQ_IsPregnant.Type').closest('.mb-3').setAttribute('hidden', true)

                    setFieldValue('pedQuestionnPrimary[0].FQ_IsPregnant.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsPregnant.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].FQ_IsPregnant.Reason', '')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].GQ_IsTobacco.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTobacco.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsTobacco.Reason').closest('.mb-3').removeAttribute('hidden')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsTobacco.Quantity').closest('.mb-3').removeAttribute('hidden')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsTobacco.Type').closest('.mb-3').removeAttribute('hidden')


                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTobacco.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTobacco.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsTobacco.Reason').closest('.mb-3').setAttribute('hidden', true)
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsTobacco.Quantity').closest('.mb-3').setAttribute('hidden', true)
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsTobacco.Type').closest('.mb-3').setAttribute('hidden', true)

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTobacco.Quantity', '0')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTobacco.Type', 'null')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsTobacco.Reason', '')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].GQ_IsAlcohol.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAlcohol.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsAlcohol.Reason').closest('.mb-3').removeAttribute('hidden')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsAlcohol.Quantity').closest('.mb-3').removeAttribute('hidden')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsAlcohol.Type').closest('.mb-3').removeAttribute('hidden')


                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAlcohol.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAlcohol.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsAlcohol.Reason').closest('.mb-3').setAttribute('hidden', true)
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsAlcohol.Quantity').closest('.mb-3').setAttribute('hidden', true)
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsAlcohol.Type').closest('.mb-3').setAttribute('hidden', true)

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAlcohol.Quantity', '0')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAlcohol.Type', 'null')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsAlcohol.Reason', '')

                  }
                }
                if (name === 'pedQuestionnPrimary[0].GQ_IsDrugs.QuestionValue') {
                  if (val.target.value === 'Y') {
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDrugs.Reason', '')
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsDrugs.Reason').closest('.mb-3').removeAttribute('hidden')

                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDrugs.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDrugs.Type', '')
                  }else {
                    document.getElementById('pedQuestionnPrimary[0].GQ_IsDrugs.Reason').closest('.mb-3').setAttribute('hidden', true)
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDrugs.Quantity', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDrugs.Type', '')
                    setFieldValue('pedQuestionnPrimary[0].GQ_IsDrugs.Reason', 'null')

                  }
                }
                setFieldValue(name, val.target.value)

              }

              return options.map((option) => {
                //console.log('radio', field)

                return (
                  <div
                    className="form-check form-check-inline "
                    key={option.value}
                  >
                    {manualValidation !== undefined ? (
                      <Field
                        type="radio"
                        className="form-check-input "
                        id={field.name}
                        {...field}
                        value={option.value}
                        onChange={(val) => handleChange(val, name)}
                      />
                    ) : (
                      <Field
                        type="radio"
                        className="form-check-input "
                        id={field.name}
                        {...field}
                        value={option.value}
                        onChange={(val) => handleChange(val, name)}
                      />
                    )}

                    <label htmlFor={field.name}>{option.label}</label>
                  </div>
                );
              });
            }}
          </Field>
        </div>
      </div>

      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikRadio;
