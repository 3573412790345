export const reInitialiseTabCareIntitialValues = (initialValues, quickValues) => {
    //console.log('reinitialize', initialValues, refValues)
    Object.entries(initialValues).map((tab, tabInd) => {
        if (Array.isArray(tab[1])) {
            switch (tab[0]) {
                case "primaryDetail":
                    tab[1].map((primaryObj, ind) => {
                        initialValues['primaryDetail'][`${ind}`] = {
                            ...initialValues['primaryDetail'][`${ind}`],
                            ["roleCd"]: "PRIMARY",
                            // ['abacusId']: quickValues['abacusId'],
                            // ['term']: quickValues.period,
                            // ['coverType']: quickValues.coverType,
                            // ['quotationPremium']: quickValues.premium,
                            // ['quotationReferenceNum']: quickValues.abacusId,
                            // ['isPremiumCalculation']: "YES",
                            // ['eldestMemberAge']: quickValues.eldestMemberAge,
                            // ['baseAgentId']: "20008325",
                            // ['baseProductId']: "10001102",
                            // ['sumInsured']: quickValues.sumInsured,
                            // ['sumInsuredValue']: quickValues.sumInsuredValue
                        }
                        console.log('pri', primaryObj)

                        primaryObj.partyAddressDOList[0] = {
                            ...primaryObj.partyAddressDOList[0],
                            'addressTypeCd': "PERMANENT",
                            'addressTypeCd2': "COMMUNICATION",
                            'countryCd': 'IND',
                            'countryCd2': 'IND',
                        }
                        primaryObj.partyContactDOList[0] = {
                            ...primaryObj.partyContactDOList[0],
                            'stdCode': "+91",
                        }

                        primaryObj.partyAddressDOList[0] = {
                            ...primaryObj.partyAddressDOList[0],
                            'addressTypeCd': "PERMANENT",
                            "addressTypeCd2": "COMMUNICATION"
                        }
                    })

                    let relation = '';

                    tab[1].map((primaryObj, ind) => {

                        quickValues.insureds.map((insure, insureIndex) => {
                            relation = insure.Relationship === '1' ? 'SELF' : ''
                            initialValues['primaryDetail'][`${insureIndex}`] = {
                                ...initialValues['primaryDetail'][`${insureIndex}`],
                                ['birthDt']: insure.dob,
                                ['firstName']: insure.name,
                                ['relationCd']: relation


                            }
                            if (parseInt(quickValues.premium) >= 50) {
                                //console.log('iisn',primaryObj.partyIdentityDOList)
                                initialValues['primaryDetail'][`${insureIndex}`].partyIdentityDOList[0] = {
                                    ...initialValues['primaryDetail'][`${insureIndex}`].partyIdentityDOList[0],
                                    'identityTypeCd': "PAN"
                                }
                            }
                        })
                    })

                    break;
                case "dependentDetail":
                    tab[1].map((primaryObj, ind) => {

                        console.log('pri', primaryObj)
                        primaryObj.partyAddressDOList[0] = {
                            ...primaryObj.partyAddressDOList[0],
                            'countryCd': 'IND',
                            'countryCd2': 'IND',
                        }
                        primaryObj.partyContactDOList[0] = {
                            ...primaryObj.partyContactDOList[0],
                            'stdCode': "+91",
                        }
                    })
                    tab[1].map((primaryObj, ind) => {
                        initialValues['dependentDetail'][`${ind}`] = {
                            ...initialValues['dependentDetail'][`${ind}`],
                            ["roleCd"]: "PROPOSER",
                        }
                    })
                    tab[1].map((primaryObj, ind) => {
                        console.log('hsh', primaryObj)
                        quickValues.insureds.map(insure => {
                            initialValues['dependentDetail'][`${ind}`] = {
                                ...initialValues['dependentDetail'][`${ind}`],
                                /*  ['birthDt']: insure.dob,
                                 ['firstName']: insure.name,
                                 ['Relationship']: insure.relationCd, */
                                relationCd: "SELF"

                            }
                        })
                    })

                    break;
                case "nomineeDetail":
                    break;
            }
        } else {
            switch (tab[0]) {
                case "dependentDetail":
                    let primaryObj = tab[1];

                    // if(parseInt(quickValues.premium) >= 50){
                    //     //console.log('iisn',primaryObj.partyIdentityDOList)
                    //     primaryObj.partyIdentityDOList[0]={
                    //         ...primaryObj.partyIdentityDOList[0],
                    //         'identityTypeCd':"PAN"
                    //     }
                    // }

                    primaryObj.partyAddressDOList[0] = {
                        ...primaryObj.partyAddressDOList[0],
                        'addressTypeCd': "PERMANENT",
                        'addressTypeCd2': "COMMUNICATION",
                        'countryCd': 'IND',
                        'countryCd2': 'IND',
                    }
                    primaryObj.partyContactDOList[0] = {
                        ...primaryObj.partyContactDOList[0],
                        'stdCode': "+91",
                    }
                    primaryObj.policyAdditionalFieldsDOList= {
                        ...primaryObj.policyAdditionalFieldsDOList,
                        ['field1']:"Partner_Acuvisor"
                    }

                    initialValues['dependentDetail'] = {
                        ...initialValues['dependentDetail'],
                        ["roleCd"]: "PROPOSER",
                        ["relationCd"]: "SELF",
                        ['abacusId']: quickValues['abacusId'],
                        ['term']: quickValues.period,
                        ['coverType']: quickValues.coverType,
                        ['quotationPremium']: quickValues.premium,
                        ['quotationReferenceNum']: quickValues.abacusId,
                        ['isPremiumCalculation']: "YES",
                        ['eldestMemberAge']: quickValues.eldestMemberAge,
                        ['baseAgentId']: "20008325",
                        ['baseProductId']: "10001102",
                        ['sumInsured']: quickValues.sumInsured,
                        ['sumInsuredValue']: quickValues.sumInsuredValue
                    }

                    break
            }
        }
        console.log('reini', initialValues)

    })
    return initialValues
}

export const reInitialiseTabCareSeniorIntitialValues = (initialValues, quickValues) => {
    //console.log('reinitialize', initialValues, refValues)
    Object.entries(initialValues).map((tab, tabInd) => {
        if (Array.isArray(tab[1])) {
            switch (tab[0]) {
                case "primaryDetail":
                    tab[1].map((primaryObj, ind) => {
                        initialValues['primaryDetail'][`${ind}`] = {
                            ...initialValues['primaryDetail'][`${ind}`],
                            ["roleCd"]: "PRIMARY",


                            /*       ['abacusId']: quickValues['abacusId'],
                                  ['term']: quickValues.period,
                                  ['coverType']: quickValues.coverType,
                                  ['quotationPremium']: quickValues.premium,
                                  ['quotationReferenceNum']: quickValues.abacusId,
                                  ['isPremiumCalculation']: "YES",
                                  ['eldestMemberAge']: quickValues.eldestMemberAge,
                                  ['baseAgentId']: "20008325",
                                  ['baseProductId']: "10001101",
                                  ['sumInsured']: quickValues.sumInsured,
                                  ['sumInsuredValue']: quickValues.sumInsuredValue */
                        }
                        console.log('pri', primaryObj)
                        primaryObj.partyAddressDOList[0] = {
                            ...primaryObj.partyAddressDOList[0],
                            'addressTypeCd': "PERMANENT",
                            'addressTypeCd2': "COMMUNICATION",
                            'countryCd': 'IND',
                            'countryCd2': 'IND',
                        }
                        primaryObj.partyContactDOList[0] = {
                            ...primaryObj.partyContactDOList[0],
                            'stdCode': "+91",
                        }
                    })

                    tab[1].map((primaryObj, ind) => {
                        quickValues.insureds.map((insure, insureIndex) => {
                            initialValues['primaryDetail'][`${insureIndex}`] = {
                                ...initialValues['primaryDetail'][`${insureIndex}`],
                                ['birthDt']: insure.dob,
                                ['firstName']: insure.name,

                                //['Relationship']: insure.relationCd,

                            }
                        })
                    })

                    let relation = '';

                    tab[1].map((primaryObj, ind) => {

                        quickValues.insureds.map((insure, insureIndex) => {
                            relation = insure.Relationship === '1' ? 'SELF' : ''
                            initialValues['primaryDetail'][`${insureIndex}`] = {
                                ...initialValues['primaryDetail'][`${insureIndex}`],
                                ['birthDt']: insure.dob,
                                ['firstName']: insure.name,
                                ['relationCd']: relation


                            }
                            if (parseInt(quickValues.premium) >= 50) {
                                //console.log('iisn',primaryObj.partyIdentityDOList)
                                initialValues['primaryDetail'][`${insureIndex}`].partyIdentityDOList[0] = {
                                    ...initialValues['primaryDetail'][`${insureIndex}`].partyIdentityDOList[0],
                                    'identityTypeCd': "PAN"
                                }
                            }
                        })

                    })

                    break;
                case "dependentDetail":
                    tab[1].map((primaryObj, ind) => {

                        console.log('pri', primaryObj)
                        primaryObj.partyAddressDOList[0] = {
                            ...primaryObj.partyAddressDOList[0],
                            'countryCd': 'IND',
                            'countryCd2': 'IND',
                        }
                        primaryObj.partyContactDOList[0] = {
                            ...primaryObj.partyContactDOList[0],
                            'stdCode': "+91",
                        }
                    })
                    tab[1].map((primaryObj, ind) => {
                        initialValues['dependentDetail'][`${ind}`] = {
                            ...initialValues['dependentDetail'][`${ind}`],
                            ["roleCd"]: "PROPOSER",
                        }
                    })
                    tab[1].map((primaryObj, ind) => {
                        console.log('hsh', primaryObj)
                        quickValues.insureds.map(insure => {
                            initialValues['dependentDetail'][`${ind}`] = {
                                ...initialValues['dependentDetail'][`${ind}`],
                                /*  ['birthDt']: insure.dob,
                                 ['firstName']: insure.name,
                                 ['Relationship']: insure.relationCd, */
                                relationCd: "SELF"

                            }
                        })
                    })
                    /*   tab[1].map((primaryObj, ind) => {
                          console.log('hsh', primaryObj)
                          quickValues.insureds.map(insure => {
                              initialValues['dependentDetail'][`${ind}`] = {
                                  ...initialValues['dependentDetail'][`${ind}`],
                                  ['birthDt']: insure.dob,
                                  ['firstName']: insure.name,
                                  ['Relationship']: insure.relationCd,
  
                              }
                          })
                      })
   */
                    break;
                case "nomineeDetail":
                    break;
            }
        } else {
            switch (tab[0]) {
                case "dependentDetail":
                    let primaryObj = tab[1]

                    // if(parseInt(quickValues.premium) >= 50){
                    //     //console.log('iisn',primaryObj.partyIdentityDOList)
                    //     primaryObj.partyIdentityDOList[0]={
                    //         ...primaryObj.partyIdentityDOList[0],
                    //         'identityTypeCd':"PAN"
                    //     }
                    // }
                    primaryObj.policyAdditionalFieldsDOList= {
                        ...primaryObj.policyAdditionalFieldsDOList,
                        ['field1']:"Partner_Acuvisor"
                    }
                    primaryObj.partyAddressDOList[0] = {
                        ...primaryObj.partyAddressDOList[0],
                        'addressTypeCd': "PERMANENT",
                        'addressTypeCd2': "COMMUNICATION",
                        'countryCd': 'IND',
                        'countryCd2': 'IND',
                    }
                    primaryObj.partyContactDOList[0] = {
                        ...primaryObj.partyContactDOList[0],
                        'stdCode': "+91",
                    }
                    initialValues['dependentDetail'] = {
                        ...initialValues['dependentDetail'],
                        ["roleCd"]: "PROPOSER",
                        ["relationCd"]: "SELF",
                        ['abacusId']: quickValues['abacusId'],
                        ['term']: quickValues.period,
                        ['coverType']: quickValues.coverType,
                        ['quotationPremium']: quickValues.premium,
                        ['quotationReferenceNum']: quickValues.abacusId,
                        ['isPremiumCalculation']: "YES",
                        ['eldestMemberAge']: quickValues.eldestMemberAge,
                        ['baseAgentId']: "20008325",
                        ['baseProductId']: "10001102",
                        ['sumInsured']: quickValues.sumInsured,
                        ['sumInsuredValue']: quickValues.sumInsuredValue
                    }
                    


                    /*   tab[1].map((primaryObj, ind) => {
                          console.log('hsh', primaryObj)
                          quickValues.insureds.map(insure => {
                              initialValues['dependentDetail'][`${ind}`] = {
                                  ...initialValues['dependentDetail'][`${ind}`],
                                  ['birthDt']: insure.dob,
                                  ['firstName']: insure.name,
                                  ['Relationship']: insure.relationCd,
  
                              }
                          })
                      })
   */
                    break;
            }
        }
    })
    console.log('reini', initialValues)
    return initialValues
}

export const reInitialiseTabCareFreedomIntitialValues = (initialValues, quickValues) => {
    //console.log('reinitialize', initialValues, refValues)
    Object.entries(initialValues).map((tab, tabInd) => {
        if (Array.isArray(tab[1])) {
            switch (tab[0]) {
                case "primaryDetail":
                    tab[1].map((primaryObj, ind) => {
                        initialValues['primaryDetail'][`${ind}`] = {
                            ...initialValues['primaryDetail'][`${ind}`],
                            //['term']: quickValues.period,
                            ["roleCd"]: "PRIMARY",
                            // ['coverType']: quickValues.coverType,
                            // ['quotationReferenceNum']: quickValues.abacusId,
                            // ['isPremiumCalculation']: "YES",
                            // ['baseAgentId']: "20008325",
                            // ['baseProductId']: "12001009",
                            // ['sumInsured']: quickValues.sumInsuredValue,
                        }
                        console.log('pri', primaryObj)
                        primaryObj.partyAddressDOList[0] = {
                            ...primaryObj.partyAddressDOList[0],
                            'addressTypeCd': "PERMANENT",
                            'addressTypeCd2': "COMMUNICATION",
                            'countryCd': 'IND',
                            'countryCd2': 'IND',
                        }
                        primaryObj.partyContactDOList[0] = {
                            ...primaryObj.partyContactDOList[0],
                            'stdCode': "+91",
                        }
                    })
                    tab[1].map((primaryObj, ind) => {
                        quickValues.insureds.map((insure, insureIndex) => {
                            initialValues['primaryDetail'][`${insureIndex}`] = {
                                ...initialValues['primaryDetail'][`${insureIndex}`],
                                ['birthDt']: insure.dob,
                                ['firstName']: insure.name,

                                //['Relationship']: insure.relationCd,

                            }
                        })
                    })

                    let relation = '';

                    tab[1].map((primaryObj, ind) => {



                        quickValues.insureds.map((insure, insureIndex) => {
                            relation = insure.Relationship === '1' ? 'SELF' : ''
                            initialValues['primaryDetail'][`${insureIndex}`] = {
                                ...initialValues['primaryDetail'][`${insureIndex}`],
                                ['birthDt']: insure.dob,
                                ['firstName']: insure.name,
                                ['relationCd']: relation


                            }
                            if (parseInt(quickValues.premium) >= 50) {
                                //console.log('iisn',primaryObj.partyIdentityDOList)primaryDetail[0].partyIdentityDOList[0].identityTypeCd
                                console.log('kkk', document.getElementById(`primaryDetail[${ind}].partyIdentityDOList[0].identityTypeCd`))

                                initialValues['primaryDetail'][`${insureIndex}`].partyIdentityDOList[0] = {
                                    ...initialValues['primaryDetail'][`${insureIndex}`].partyIdentityDOList[0],
                                    'identityTypeCd': "PAN"
                                }
                            }
                        })
                    })

                    break;
                case "dependentDetail":
                    tab[1].map((primaryObj, ind) => {

                        console.log('pri', primaryObj)
                        primaryObj.partyAddressDOList[0] = {
                            ...primaryObj.partyAddressDOList[0],
                            'countryCd': 'IND',
                            'countryCd2': 'IND',
                        }
                        primaryObj.partyContactDOList[0] = {
                            ...primaryObj.partyContactDOList[0],
                            'stdCode': "+91",
                        }
                    })
                    tab[1].map((primaryObj, ind) => {
                        initialValues['dependentDetail'][`${ind}`] = {
                            ...initialValues['dependentDetail'][`${ind}`],
                            ["roleCd"]: "PROPOSER",
                        }
                    })
                    tab[1].map((primaryObj, ind) => {
                        console.log('hsh', primaryObj)
                        quickValues.insureds.map(insure => {
                            initialValues['dependentDetail'][`${ind}`] = {
                                ...initialValues['dependentDetail'][`${ind}`],
                                /* ['birthDt']: insure.dob,
                                ['firstName']: insure.name,
                                ['Relationship']: insure.relationCd, */
                                relationCd: "SELF"

                            }
                        })
                    })

                    break;
                case "nomineeDetail":
                    break;
            }
        } else {
            switch (tab[0]) {
                case "dependentDetail":
                    let primaryObj = tab[1]

                    // if(parseInt(quickValues.premium) >= 50){
                    //     //console.log('iisn',primaryObj.partyIdentityDOList)
                    //     primaryObj.partyIdentityDOList[0]={
                    //         ...primaryObj.partyIdentityDOList[0],
                    //         'identityTypeCd':"PAN"
                    //     }
                    // }

                    primaryObj.partyAddressDOList[0] = {
                        ...primaryObj.partyAddressDOList[0],
                        'addressTypeCd': "PERMANENT",
                        'addressTypeCd2': "COMMUNICATION",
                        'countryCd': 'IND',
                        'countryCd2': 'IND',
                    }
                    primaryObj.partyContactDOList[0] = {
                        ...primaryObj.partyContactDOList[0],
                        'stdCode': "+91",
                    }

                    primaryObj.policyAdditionalFieldsDOList= {
                        ...primaryObj.policyAdditionalFieldsDOList,
                        ['field1']:"Partner_Acuvisor"
                    }

                    initialValues['dependentDetail'] = {
                        ...initialValues['dependentDetail'],
                        ["roleCd"]: "PROPOSER",
                        ["relationCd"]: "SELF",
                        ['term']: quickValues.period,
                        ['coverType']: quickValues.coverType,
                        ['quotationReferenceNum']: quickValues.abacusId,
                        ['isPremiumCalculation']: "YES",
                        ['baseAgentId']: "20008325",
                        ['baseProductId']: "12001009",
                        ['sumInsured']: quickValues.sumInsured,
                    }


                    break;
            }
        }
    })
    console.log('reini', initialValues)
    return initialValues
}

export const reInitialiseTabStarInitialValues = (initialValues, quickValues) => {
    //console.log('isni', initialValues.primaryDetail, quickValues)
    Object.entries(initialValues).map((tab, tabInd) => {
        if (Array.isArray(tab[1])) {
            switch (tab[0]) {
                case "primaryDetail":
                    tab[1].map((primaryObj, ind) => {
                        // console.log('hhh', primaryObj)
                        console.log('ksdd', quickValues.insureds[ind].relationshipId)
                        if (quickValues.insureds[ind].relationshipId === '1') {
                            initialValues['primaryDetail'][`${ind}`] = {
                                ...initialValues['primaryDetail'][`${ind}`],
                                ['name']: quickValues.insureds[ind].name,
                                //['dob']: quickValues.insureds[ind].dob,
                                ['sumInsuredId']: quickValues.sumInsuredId,
                                ['relationshipId']: quickValues.insureds[ind].relationshipId,
                                ['sex']: quickValues.gender
                                /*  ['code']: quickValues.code,
                                 ['codeType']: quickValues.codeType,
                                 ['period']: quickValues.period,
                                 ['postalCode']: quickValues.postalCode,
                                 ['schemeId']: quickValues.schemeId,
                                 ['sumInsuredId']: quickValues.sumInsuredId,
                                 ['proposerName']: quickValues.proposerName,
                                 ['proposerAreaId']: quickValues.proposerAreaId,
      */
                            }
                        } else {
                            initialValues['primaryDetail'][`${ind}`] = {
                                ...initialValues['primaryDetail'][`${ind}`],
                                ['name']: quickValues.insureds[ind].name,
                                //['dob']: quickValues.insureds[ind].dob,
                                ['sumInsuredId']: quickValues.sumInsuredId,
                                ['relationshipId']: quickValues.insureds[ind].relationshipId,
                                /*  ['code']: quickValues.code,
                                 ['codeType']: quickValues.codeType,
                                 ['period']: quickValues.period,
                                 ['postalCode']: quickValues.postalCode,
                                 ['schemeId']: quickValues.schemeId,
                                 ['sumInsuredId']: quickValues.sumInsuredId,
                                 ['proposerName']: quickValues.proposerName,
                                 ['proposerAreaId']: quickValues.proposerAreaId,
      */
                            }
                        }

                    })
                    break;
                case "dependentDetail":

                    initialValues['dependentDetail'] = {
                        ...initialValues['dependentDetail'],
                        /*  ['name']: quickValues.insureds[ind].name,
                         ['dob']: quickValues.insureds[ind].dob,
                         ['sumInsuredId']: quickValues.sumInsuredId,
                         ['relationshipId']: quickValues.insureds[ind].relationshipId, */
                        //['code']: quickValues.code,
                        ['codeType']: quickValues.codeType,
                        ['period']: quickValues.period,
                        ['postalCode']: quickValues.postalCode,
                        ['schemeId']: quickValues.schemeId,
                        ['sumInsuredId']: quickValues.sumInsuredId,
                        ['proposerName']: quickValues.proposerName,
                        ['proposerAreaId']: quickValues.proposerAreaId,
                        ['startOn']: quickValues.startOn,
                        ['endOn']: quickValues.endOn,
                        ['policyTypeName']: quickValues.product_code,


                    }
                    break
                case "nomineeDetail":
                    break;

            }
        } else {
            switch (tab[0]) {
                case "dependentDetail":

                    initialValues['dependentDetail'] = {
                        ...initialValues['dependentDetail'],
                        /*  ['name']: quickValues.insureds[ind].name,
                         ['dob']: quickValues.insureds[ind].dob,
                         ['sumInsuredId']: quickValues.sumInsuredId,
                         ['relationshipId']: quickValues.insureds[ind].relationshipId, */
                        //['code']: quickValues.code,
                        ['codeType']: quickValues.codeType,
                        ['period']: quickValues.period,
                        ['postalCode']: quickValues.postalCode,
                        ['schemeId']: quickValues.schemeId,
                        ['sumInsuredId']: quickValues.sumInsuredId,
                        ['proposerName']: quickValues.proposerName,


                        ['proposerAreaId']: quickValues.proposerAreaId,
                        ['startOn']: quickValues.startOn,
                        ['endOn']: quickValues.endOn,
                        ['policyTypeName']: quickValues.product_code,


                    }
                    break
            }
        }
    })

    return initialValues

}
