import { ErrorMessage, Field, FieldArray, useFormik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import TextError from '../../Components/HealthInsFormikComponent/TextError'


const QuickFieldArray = ({ formik }) => {

    const { values, setFieldValue } = useFormikContext()
    const [arrayObject, setArrayObject] = useState({})
    console.log('eerr', formik)

    const handleChange = (val, index) => {
        console.log('age val', typeof (val.target.value))
        if (val.target.value === "0-1") {
            setFieldValue(`insureds[${index}].Age`, "6 Month")
        } else {
            setFieldValue(`insureds[${index}].Age`, val.target.value)
        }
    }

    useEffect(() => {

        console.log('value', values.insureds[0].relationShip)
        let custObject = values.insureds[0]
        Object.entries(custObject).map(entry => {
            custObject = { ...custObject, [entry[0]]: '' }
        })

        console.log('custObject', custObject)
        setArrayObject(custObject)

    }, [formik])


    return (
        <>
            <div >
                <h4 className="p-2 fw-bold">Insureds Persons</h4>
            </div>
            <FieldArray name='insureds' >
                {({ push, remove }) => (
                    <React.Fragment>
                        {values.insureds &&
                            values.insureds.length > 0 &&
                            values.insureds.map((insure, index) => (
                                <div key={index}>

                                    <div className="form-group mb-3 p-2">
                                        <label htmlFor="inputRelationShip" className='fw-bold' >Who would you like to get insureds?</label>
                                        <Field as='select'
                                            name={`insureds[${index}].relationShip`}
                                            id="inputRelationShip"
                                            className={

                                                formik.touched.insureds && formik.errors.insureds &&
                                                    formik.touched.insureds[index] && formik.errors.insureds[index] &&
                                                    formik.errors.insureds[index].relationShip ? "errorStyle form-control bg-white"
                                                    : formik.touched.insureds && formik.errors.insureds &&
                                                        formik.touched.insureds[index] && formik.errors.insureds[index]
                                                        && !formik.errors.insureds[index].relationShip && formik.touched.insureds[index].relationShip ? "successField form-control bg-white"
                                                        : "form-control bg-white"
                                            }

                                        >
                                            <option value="">Select Member</option>
                                            <option value="Self">Self</option>
                                            <option value="Spouse">Spouse</option>
                                            <option value="Dependent Child">Dependent Child</option>
                                            <option value="Dependent Parent">Dependent Parent</option>
                                            <option value="Dependent In Law">Dependent In Law</option>
                                            <option value="Others">Others</option>

                                        </Field>
                                        <ErrorMessage name={`insureds[${index}].relationShip`} component={TextError} />

                                    </div>
                                    {
                                        values.insureds[index].relationShip !== '' ?
                                            <div >
                                                {/*  <div className="mb-3">
                                                    <label htmlFor="inputDOB" className="p-2 fw-light">{values.insureds[index].relationShip}(Date Of Birth)</label>
                                                    <Field type="Date" className="form-control bg-white" name={`insureds[${index}].DOB`} id="inputDOB" aria-describedby="nameHelp" />

                                                </div> */}
                                                <div className="mb-3 p-2">
                                                    <label htmlFor="inputInsureName" className="fw-light">{values.insureds[index].relationShip} (Name)</label>
                                                    <Field type="text" id="inputInsureName" aria-describedby="nameHelp"
                                                        name={`insureds[${index}].name`}
                                                        className={
                                                            formik.touched.insureds && formik.errors.insureds &&
                                                                formik.touched.insureds[index] && formik.errors.insureds[index] &&
                                                                formik.errors.insureds[index].name ? "errorStyle form-control bg-white"
                                                                : formik.touched.insureds && formik.errors.insureds &&
                                                                    formik.touched.insureds[index] && formik.errors.insureds[index]
                                                                    && !formik.errors.insureds[index].name && formik.touched.insureds[index].name ? "successField form-control bg-white"
                                                                    : "form-control bg-white"
                                                        }
                                                    />
                                                    <ErrorMessage name={`insureds[${index}].name`} component={TextError} />

                                                </div>

                                                <div className="mb-3 p-2">
                                                    <label htmlFor="inputAge">{values.insureds[index].relationShip} (Age)</label>
                                                    <Field as='select'
                                                        name={`insureds[${index}].Age`}
                                                        id="inputAge"
                                                        className={
                                                            formik.touched.insureds && formik.errors.insureds &&
                                                                formik.touched.insureds[index] && formik.errors.insureds[index] &&
                                                                formik.errors.insureds[index].Age ? "errorStyle form-control bg-white"
                                                                : formik.touched.insureds && formik.errors.insureds &&
                                                                    formik.touched.insureds[index] && formik.errors.insureds[index]
                                                                    && !formik.errors.insureds[index].Age && formik.touched.insureds[index].Age ? "successField form-control bg-white"
                                                                    : "form-control bg-white"
                                                        }
                                                    //onChange={(val) => handleChange(val,index)}
                                                    >
                                                        <option value="">Select Age</option>
                                                        <option value="6 Month">0-1 year</option>
                                                        <option value="1">1 year</option>
                                                        <option value="2">2 year</option>
                                                        <option value="3">3 year</option>
                                                        <option value="4">4 year</option>
                                                        <option value="5">5 year</option>
                                                        <option value="6">6 year</option>
                                                        <option value="7">7 year</option>
                                                        <option value="8">8 year</option>
                                                        <option value="9">9 year</option>
                                                        <option value="10">10 year</option>
                                                        <option value="11">11 year</option>
                                                        <option value="12">12 year</option>
                                                        <option value="13">13 year</option>
                                                        <option value="14">14 year</option>
                                                        <option value="15">15 year</option>
                                                        <option value="16">16 year</option>
                                                        <option value="17">17 year</option>
                                                        <option value="18">18 year</option>
                                                        <option value="19">19 year</option>
                                                        <option value="20">20 year</option>
                                                        <option value="21">21 year</option>
                                                        <option value="22">22 year</option>
                                                        <option value="23">23 year</option>
                                                        <option value="24">24 year</option>
                                                        <option value="25">25 year</option>
                                                        <option value="26">26 year</option>
                                                        <option value="27">27 year</option>
                                                        <option value="28">28 year</option>
                                                        <option value="29">29 year</option>
                                                        <option value="30">30 year</option>
                                                        <option value="31">31 year</option>
                                                        <option value="32">32 year</option>
                                                        <option value="33">33 year</option>
                                                        <option value="34">34 year</option>
                                                        <option value="35">35 year</option>
                                                        <option value="36">36 year</option>
                                                        <option value="37">37 year</option>
                                                        <option value="38">38 year</option>
                                                        <option value="39">39 year</option>
                                                        <option value="40">40 year</option>
                                                        <option value="41">41 year</option>
                                                        <option value="42">42 year</option>
                                                        <option value="43">43 year</option>
                                                        <option value="44">44 year</option>
                                                        <option value="45">45 year</option>
                                                        <option value="46">46 year</option>
                                                        <option value="47">47 year</option>
                                                        <option value="48">48 year</option>
                                                        <option value="49">49 year</option>
                                                        <option value="50">50 year</option>
                                                        <option value="51">51 year</option>
                                                        <option value="52">52 year</option>
                                                        <option value="53">53 year</option>
                                                        <option value="54">54 year</option>
                                                        <option value="55">55 year</option>
                                                        <option value="56">56 year</option>
                                                        <option value="57">57 year</option>
                                                        <option value="58">58 year</option>
                                                        <option value="59">59 year</option>
                                                        <option value="60">60 year</option>
                                                        <option value="61">61 year</option>
                                                        <option value="62">62 year</option>
                                                        <option value="63">63 year</option>
                                                        <option value="64">64 year</option>
                                                        <option value="65">65 year</option>
                                                        <option value="66">66 year</option>
                                                        <option value="67">67 year</option>
                                                        <option value="68">68 year</option>
                                                        <option value="69">69 year</option>
                                                        <option value="70">70 year</option>
                                                        <option value="71">71 year</option>
                                                        <option value="72">72 year</option>
                                                        <option value="73">73 year</option>
                                                        <option value="74">74 year</option>
                                                        <option value="75">75 year</option>
                                                        <option value="76">76 year</option>
                                                        <option value="77">77 year</option>
                                                        <option value="78">78 year</option>
                                                        <option value="79">79 year</option>
                                                        <option value="80">80 year</option>
                                                        <option value="81">81 year</option>
                                                        <option value="82">82 year</option>
                                                        <option value="83">83 year</option>
                                                        <option value="84">84 year</option>
                                                        <option value="85">85 year</option>
                                                        <option value="86">86 year</option>
                                                        <option value="87">87 year</option>
                                                        <option value="88">88 year</option>
                                                        <option value="89">89 year</option>
                                                        <option value="90">90 year</option>
                                                        <option value="91">91 year</option>
                                                        <option value="92">92 year</option>
                                                        <option value="93">93 year</option>
                                                        <option value="94">94 year</option>
                                                        <option value="95">95 year</option>
                                                        <option value="96">96 year</option>
                                                        <option value="97">97 year</option>
                                                        <option value="98">98 year</option>
                                                        <option value="99">99 year</option>
                                                        <option value="100">100 year</option>

                                                    </Field>
                                                    <ErrorMessage name={`insureds[${index}].Age`} component={TextError} />

                                                </div>
                                            </div> : null
                                    }

                                    < div className="mb-3 d-flex justify-content-end" >
                                        {
                                            values.insureds.length > 1 ?
                                                <p type="button" className=" text-danger" onClick={() => remove(index)}>
                                                    Remove
                                                </p> : null
                                        }

                                    </div>

                                </div>
                            ))}
                        {
                            values.insureds[0].relationShip !== '' ?

                                <div className="mb-3 d-flex justify-content-end">

                                    <button type="button" className="btn btn-success text-dark"
                                        onClick={() => push(arrayObject)}
                                    >
                                        <i className="bi bi-plus"></i>
                                        Add Members
                                    </button>
                                </div> : null
                        }


                    </React.Fragment>
                )
                }
            </FieldArray >
        </>
    )
}

export default QuickFieldArray