
var FinalReqbody = {
    Request: {

    }
}
var Proposal = {}
const ShriRamreqbodyGen = (data) => {
    console.log(data, "....")

    Object.entries(data).map((field, index) => {
        const array = [];
        switch (field[0]) {
            case "primaryDetails":

                field[1].map((innerField, index) => {
                    Object.entries(innerField).map((nesinnerField, index) => {
                        if (nesinnerField[0] === "LifeAssured" || nesinnerField[0] === "Address" || nesinnerField[0] === "Family" || nesinnerField[0] === "Plan" || nesinnerField[0] === "PartnerRef" || nesinnerField[0] === "Payment") {

                            Proposal[`${nesinnerField[0]}`] = nesinnerField[1]
                        } else if (nesinnerField[0] === "Documents") {
                            Proposal[`${nesinnerField[0]}`] = [];
                            Object.entries(nesinnerField[1][0]).map((docField, ind) => {
                                let obj = {}
                                obj["ProofType"] = docField[0]
                                obj = { ...obj, ...docField[1] }
                                Proposal.Documents.push(obj)
                            })

                        }
                        // else{
                        //     if(typeof nesinnerField[1]==="object" ){

                        //         Proposal.Plan[`${nesinnerField[0]}`]=nesinnerField[1]
                        //     }
                        //     else{
                        //         Proposal[`${nesinnerField[0]}`]=nesinnerField[1]
                        //     }

                        // }
                    })
                })

                break;
            case "pedQuestionnPrimary":
                field[1].map((innerField, index) => {
                    Proposal["Questions"] = innerField;
                })
                break;
            case "Proposer":
                field[1].map((innerField, index) => {
                    Proposal["Proposer"] = innerField;
                })
                break;
            case "nomineeDetails":
                Proposal["Nominee"] = field[1]
                break;
        }


    }

    )
    console.log(Proposal, "....lll")
    FinalReqbody.Request["Proposal"] = Proposal
    return FinalReqbody
}
export default ShriRamreqbodyGen;