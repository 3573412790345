import React, { useEffect, useState } from "react";
// import { Navigate, useParams } from "react-router-dom";
import { Route, Routes, Link } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { FieldArray, Form, Formik } from "formik";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import FormikControl from "../../../Components/LifeInsFormikComponent/FormikControl";
import createInitialValue from "../../../Utilities/InitialValuesSetup/createInitialValue";
import * as Yup from "yup";
import { Box } from "@mui/system";
//import payPremium from "../JSONbodies/Paypremium";
import automaticValidationSchema from "../../../Utilities/ValidationSchemaSetup/automaticValidationSchema";
import { useNavigate ,useParams} from "react-router";
import TextField from "@mui/material/TextField";
//import { Dialogbox } from "../Modal/Dialog";

//var serialize = require('form-serialize');



function PaypremiumForm(props) {
  const [productDetails, setProductDetails] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [verifyOTP,setVerifyOTP]=useState(false)
  const [transferData,setTransferData]=useState({});

  const { id } = useParams();
  const navigate = useNavigate();


  var initialValues={}
  var finalIni={};
  if(Object.keys(productDetails).length>0){
    initialValues=createInitialValue(productDetails)
    console.log(initialValues,"initialvalues")

    finalIni=initialValues
    Object.entries(finalIni).map((field, ind) => {
      Object.entries(transferData).map((trans, transInd) => {
        if (field[0] === trans[0]) {
          console.log('sdd',field[0],trans[1])
          //field[0]=trans[1]
           finalIni={...finalIni,[`${field[0]}`]:`${trans[1]}`} 
        }
      })

    })
    Object.entries(finalIni).map((field, ind) => {
      if(field[0]==="PayableAmount"){
        //console.log('total prem',transferData.TotalPremium)
        finalIni={...finalIni,[`${field[0]}`]:`${transferData.TotalPremium}`} 
      }
      if(field[0]==="CustomerName"){
        finalIni={...finalIni,[`${field[0]}`]:`${transferData.LAName}`} 
      }
      if(field[0]==="MobileNumber"){
        finalIni={...finalIni,[`${field[0]}`]:`${transferData.MobileNo}`} 
      }
    })



   /*  Object.entries(initialValues).map((field,ind)=>{
      if (!transferData.hasOwnProperty(field[0])) {
        finalIni[`${field[0]}`] = field[1];
      }
    })
    finalIni={...finalIni,...transferData} */
  }
console.log(finalIni,"finalIni in payPremium")
  var validationSchema = Yup.object().shape({});
  if (Object.keys(productDetails).length > 0) {
    validationSchema = automaticValidationSchema(
      productDetails.requestBody.formFields.fields
    );
  }
console.log(transferData,"transData in payPremium")
  useEffect(() => {
    //let id_split=id.split('+')
    Axios.get(`http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/shriram/plp-validate-response/${id}/`).

    then(response=>{
      setTransferData(response.data)
      console.log('trans of pay premium',response)
    })

    //setProductDetails(payPremium);
  },[]);

  const onSubmit = (values) => {
    console.log("Form submit", values);
     Axios.post(" http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/life-insurance/pay-premium/",JSON.stringify(values)).then(response=>{
      console.log(response,"response in pay premium")
      if(response.data.ResultSet.ResponseCode ==='200'){
        window.open(`http://${response.data.ResultSet.Response.PaymentUrl}`)
      }
     })
     // navigate("/life-insurance/submitproposal");
   
  
    
  };
  return (
    <div id="content">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
            <div className="bg-white shadow-md rounded-card  pt-sm-4 pb-sm-5 px-sm-5 card-responsive ">
              <h3 className="fw-400 text-center my-4 heading-text">
               Pay premium Form
              </h3>
              {Object.keys(initialValues).length > 0 ? (
                <Formik
                  enableReinitialize
                  initialValues={finalIni}
                //   validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formik) => {
                    console.log("formik val", formik);
                    return (
                      <div className="justify-content-center ">
                        <Form>
                          {Object.keys(productDetails).length > 0
                            ? productDetails.requestBody.formFields.fields.map(
                                (field, index) => {
                                  return field.htmlElement === "TextField" ? (
                                    <div
                                      className="mb-3"
                                      key={index}
                                      hidden={field.hidden}
                                    >
                                      <FormikControl
                                        control="input"
                                        errors={formik.errors}
                                        name={field.name}
                                        readOnly={field.readOnly}
                                        url={field.url}
                                        label={field.label}
                                        type={field.dataType}
                                        touched={formik.touched}
                                      />
                                    </div>
                                  ) : field.htmlElement === "Select" ? (
                                    <div
                                      className="mb-3"
                                      key={index}
                                      hidden={field.hidden}
                                    >
                                      <FormikControl
                                        control="select"
                                        name={field.name}
                                        errors={formik.errors}
                                        readOnly={field.readOnly}
                                        url={field.url}
                                        label={field.label}
                                        type={field.dataType}
                                        options={field.options}
                                        touched={formik.touched}
                                      />
                                    </div>
                                  ) : field.htmlElement === "Radio" ? (
                                    <div
                                      className="mb-3"
                                      key={index}
                                      hidden={field.hidden}
                                    >
                                      <FormikControl
                                        control="radio"
                                        name={field.name}
                                        errors={formik.errors}
                                        readOnly={field.readOnly}
                                        url={field.url}
                                        label={field.label}
                                        type={field.dataType}
                                        options={field.options}
                                        touched={formik.touched}
                                      />
                                    </div>
                                  ) : null;
                                }
                              )
                            : null}
                        

                          <div className="d-grid mb-3 ">
                            {console.log("isSubmit", formik.isSubmitting)}

                            <button 
                            className="submitBtn mt-4" 
                            type="submit"
                            disabled={formik.isSubmitting}
                            >
                              Submit
                            </button>
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              ) : (
                <Typography>Loading....</Typography>
              )}
              
             
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
}

export default PaypremiumForm;
