import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Axios from "axios";
//import '../App.css';
import { Form, Formik, FieldArray, useFormikContext } from 'formik';
import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
//import FormikControl from './FormikComponents/FormikControl';
import * as Yup from 'yup';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
/* import JSON_TO_YUP from './FormikComponents/YupValidation';
import Dependent from './QuickQoutes/Dependent';
import CreateQuoteDependent from "./CreateQuoteDep&Obj/CreateQuoteDependent"
import CreateQuoteObjectView from "./CreateQuoteDep&Obj/CreateQuoteObjectView" */
// import CreateQuoteDependent from './QuickQoutes/CreateQuoteDependent';
import createInitialValue from '../../Utilities/InitialValuesSetup/createInitialValue';
import automaticValidationSchema from '../../Utilities/ValidationSchemaSetup/automaticValidationSchema'
import _ from "lodash";

// import CreateQuoteObjectView from './QuickQoutes/CreateQuoteObjectView';
//import { createCareReqBody, createCareFreedomReqBody, createCareSeniorReqBody } from '../Utilities/Create_Care_Req_Body/createCareReqBody';
import {
    createTabCareFreedomReqBdy,
    createTabCareReqBdy,
    createTabCareSeniorReqBdy,
    createTabStarReqBdy
}
    from '../../Utilities/HealthInsuanceRequestBodies/CreateQuoteRequestBodis/careRequestBodiesCQ';
import {
    reInitialiseIntitialValues,
    reInitialiseTabCareFreedomIntitialValues,
    reInitialiseTabCareIntitialValues,
    reInitialiseTabCareSeniorIntitialValues,
    reInitialiseTabStarInitialValues
} from '../../Utilities/HealthInsuanceRequestBodies/ReInitializeValues/reInitializeValues';
/* import CreateQuoteArray from './CreateQuoteDep&Obj/CreateQuoteArray';
import CreateQuoteMainObjectView from './CreateQuoteDep&Obj/CreateQuoteMainObjectView';
import ArrayMainComponent from './CreateQuoteDep&Obj/ArrayMainComponent'; */
import HealthInsuranceForm from './HealthInsuranceForm';
import CryptoJS from 'crypto-js'
import '../../Assets/css/policy.css'
import '../../Assets/css/globalStyle.css'



//var serialize = require('form-serialize');


function Policy(props) {
    const [productName, setProductName] = useState("");
    const [disableBuy, setDisableBuy] = useState(false);
    const [productUrl, setProductUrl] = useState("");
    const [requestBody, setrequestBody] = useState({});
    const [newRequestBody, setNewRequestBody] = useState({});
    const [productDetails, setProductDetails] = useState({});
    const [quickQuoteDetails, setQuickQuoteDetails] = useState({});
    const [responseData, setResponseData] = useState({});
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    // const steps = ["1", "2", "3"]
    const [depInd, setdepInd] = useState(1)
    //const [listUpdated,setListUpdated] = useState(false);
    //const productId = props.match.params.productId;
    const { productId } = useParams();

    const productIdArray = productId.split('*')
    const productRef = productIdArray[0]
    const responseId = productIdArray[1]
    //console.log('split', productId)
    console.log('split', productRef)
    // let initialValues = {}
    const navigate = useNavigate();

    //const formikContext = useFormikContext()

    const productRefURI = decodeURIComponent(productRef).toString()
    const responseIdURI = decodeURIComponent(responseId).toString()


    const bytesProductRefURI = CryptoJS.AES.decrypt(productRefURI, 'secret key @123');
    const bytesResponseIdURI = CryptoJS.AES.decrypt(responseIdURI, 'secret key @124');


    const decryptedProductRef = JSON.parse(bytesProductRefURI.toString(CryptoJS.enc.Utf8));
    const decryptedResponseId = JSON.parse(bytesResponseIdURI.toString(CryptoJS.enc.Utf8));

    var initialValues;

    if (Object.keys(productDetails).length > 0) {
        //console.log("count....")
        initialValues = createInitialValue(productDetails);
        console.log("initialValues", initialValues);

        /* initialValues.primaryDetail.map((field, ind) => { */
        Object.entries(initialValues).map((field, ind) => {
            if (field[0] === "primaryDetail") {
                initialValues.primaryDetail.map((innefield) => {
                    for (let i = 0; i < depInd - 1; i++) {
                        initialValues.primaryDetail.push(innefield);
                    }
                });
            }/*  else if (field[0] === "dependentDetail") {
        initialValues.dependentDetail.map((innefield) => {
          for (let i = 0; i <=0; i++) {
            initialValues.dependentDetail.push(innefield);
          }
        });
      } */ /* else {
        initialValues.nomineeDetail.map((innefield) => {
          for (let i = 0; i <= 0; i++) {
            initialValues.nomineeDetail.push(innefield);
          }
        });
      } */
        });

        /*  }); */
        // console.log("updatedInitialValue", initialValues);
    }
    /* Reinitialze initial values  */
    if (Object.keys(quickQuoteDetails).length > 0 && Object.keys(productDetails).length > 0) {
        //console.log('prod na', productDetails.productName)
        if (productDetails.productName === "Tab_Care") {
            initialValues = reInitialiseTabCareIntitialValues(initialValues, quickQuoteDetails)


        } else if (productDetails.productName === "Tab_Senior") {
            initialValues = reInitialiseTabCareSeniorIntitialValues(initialValues, quickQuoteDetails)


        } else if (productDetails.productName === "Tab_CareFreedom") {
            initialValues = reInitialiseTabCareFreedomIntitialValues(initialValues, quickQuoteDetails)


        } else if (productDetails.productName === "POS Medi Classic Individual New Tab" || productDetails.productName === 'POS Family Health Optima New Tab') {
            initialValues = reInitialiseTabStarInitialValues(initialValues, quickQuoteDetails)
        }
    }
    /* Reinitialze initial values  ends*/


    /*--------------------------------------------------*/

    var Fields = [];
    if (Object.keys(productDetails).length > 0) {
        productDetails.requestBody.formFields.fields.map((field, index) => {
            Fields.push(field);
        });
    }

    console.log('ddaa', Fields)
    /*--------------------------------------------------*/
    var SchemaArray = [];

    if (Object.keys(productDetails).length > 0) {
        //console.log(productDetails,"productDetailsforError")
        productDetails.requestBody.formFields.fields.map((field, index) => {
            let seperatearray = [];

            seperatearray.push(field);

            SchemaArray.push(seperatearray);
        });
    }
    console.log("SchemaArray", SchemaArray)
    /*--------------------------------------------------*/

    var validationSchema = Yup.object().shape({});
    if (Object.keys(productDetails).length > 0) {
        console.log('premium', parseInt(quickQuoteDetails.premium))

        if (parseInt(quickQuoteDetails.premium) >= 50) {
            console.log('premium inside', quickQuoteDetails.premium)
            console.log('schhh', SchemaArray[activeStep])
            SchemaArray[activeStep].map((item) => {
                if (item.name === "primaryDetail") {
                    console.log('item', item)
                    item.obj.map((innerField) => {
                        if (innerField.name === "partyIdentityDOList") {
                            innerField.obj.map((identityArrayEle) => {
                                if (identityArrayEle.name === "panNumber") {
                                    console.log('paan', identityArrayEle)
                                    identityArrayEle.required = true
                                }
                            })
                        }
                    })
                }

            })
            console.log('final', SchemaArray[activeStep])

            validationSchema = automaticValidationSchema(SchemaArray[activeStep]);

        } else {
            validationSchema = automaticValidationSchema(SchemaArray[activeStep]);

        }
    }
    console.log('validation', validationSchema)
    /*--------------------------------------------------*/
    //const utility = "https://acuvisor.auxtomate.in/externalJS/manualValidation.js"

    useEffect(() => {
        let productDetailsArray = [];

      



         if (window.location.hostname === 'localhost') {
             Axios.get(`http://localhost:3001/api/v1/healthCare/product/${decryptedProductRef}`).then((response) => {
                 if (response.status === 200) {
                     console.log('response node', response.data)
                     //productDetailsArray.push(response.data)
                     //setProductDetails(productDetailsArray);
                     setProductDetails(response.data);
 
                 }
 
             })
         } else {
             Axios.get(` http://65.0.70.70:81/api/v1/healthCare/product/${decryptedProductRef}`).then((response) => {
                 if (response.status === 200) {
                     console.log('response node', response.data)
                     //productDetailsArray.push(response.data)
                     //setProductDetails(productDetailsArray);
                     setProductDetails(response.data);
 
                 }
 
             })
         } 

         if (window.location.hostname === 'localhost') {
            Axios.get(`http://localhost:8000/api/health/transfer-data/${decryptedResponseId}`).then((response) => {
                if (response.status === 200) {
                    console.log('response from djamgoo ', response)
                    setQuickQuoteDetails(response.data)
                    setdepInd(response.data.number_of_insured)
                    // productDetailsArray.push(response.data)
                    //setProductDetails(productDetailsArray);
                    // setProductDetails(response.data);

                }

            })
        } else {
            Axios.get(`http://65.0.70.70:8000/api/health/transfer-data/${decryptedResponseId}`).then((response) => {
                if (response.status === 200) {
                    console.log('response from djamgoo ', response)
                    setQuickQuoteDetails(response.data)
                    setdepInd(response.data.number_of_insured)
                    // productDetailsArray.push(response.data)
                    //setProductDetails(productDetailsArray);
                    // setProductDetails(response.data);

                }

            })
        } 

        /* let utilityScript = document.createElement("script");
        utilityScript.src = utility;
        document.body.appendChild(utilityScript); */


    }, []);

    /*--------------------------------------------------*/
    // useEffect(() => {
    //   let productDetailsArray = [];
    //   setdepInd(2)
    //   setProductDetails(careJSONM);
    //   let utilityScript = document.createElement("script");
    //   utilityScript.src = utility;
    //   document.body.appendChild(utilityScript);
    // }, []);

    //const iniVal = initialValues
    const totalSteps = () => {
        return steps.length;
    };
    const handleBack = () => {
        if (activeStep !== 0) {
            window.scrollTo(0, 0)
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
            //formikContext.validateForm()
        } else {
            navigate(-1)
        }
    };
    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        //setDisableBuy(true)
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    // const iniVal = initialValues;
    const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
        backgroundColor: "#00008B",
        // theme.palette.mode === "dark" ? theme.palette.grey[700] : "",
        color: "white",
        zIndex: 1,
        fontSize: 12,
        width: 150,
        height: 50,
        display: "flex",

        justifyContent: "center",

        alignItems: "center",
        ...(ownerState.active && {
            backgroundColor: "white",
            color: "black",
            borderWidth: "solid",
            borderColor: "black",
            boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        }),
        ...(ownerState.completed && {
            backgroundColor: "#00008B",
            color: "white",
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: "PRIMARY DETAILS",
            2:/*  productDetails.productName === "Tab_Senior" || productDetails.productName === "Tab_Care"
        || productDetails.productName === "Tab_CareFreedom"
        ?  */"PROPOSER DETAILS" /* : "DEPENDENT DETAILS" */,
            3: "NOMINEE DETAILS",
        };

        return (
            <ColorlibStepIconRoot
                ownerState={{ completed, active }}
                className={className}
            >
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    /*--------------------------------------------------*/

    const onSubmit = async (data, {resetForm},actions) => {
        var FinalStarReq = {};
        let FinalCare;
        let FinalStarHealth;
        if (isLastStep()) {
            setDisableBuy(true)
            // console.log("submitted data", values);
            if (productDetails.productName === "Tab_Care") {
                FinalCare = createTabCareReqBdy(data);
                console.log('Tab_Care req bdy', FinalCare);
                console.log('Tab_Care req bdy in JSON', JSON.stringify(FinalCare));

                let updatedData = {
                    // "A_id":quickQuoteDetails.A_id,
                    // "Acuvisor_product_id": quickQuoteDetails.Acuvisor_product_id,
                    "care": FinalCare
                }

                if (window.location.hostname === 'localhost') {
                    await Axios.post(`http://localhost:8000/api/health/create-quote/${quickQuoteDetails.A_id}/${quickQuoteDetails.Acuvisor_product_id}/`, JSON.stringify(updatedData)).
                        then(
                            response => {

                                if (response.data.errorLists) {
                                    console.log('if part', response.data)
                                    alert(response.data.errorLists[0].errDescription)
                                } else {
                                    console.log('else part', response)
                                    navigate(`/health-insurance/product-stastus/${response.data.id}`);
                                }
                            }
                        ).catch(errors => {
                            console.log(errors)
                            alert(errors)
                            resetForm(initialValues);
                            setDisableBuy(false)
                        })
                } else {
                    await Axios.post(`http://65.0.70.70:8000/api/health/create-quote/${quickQuoteDetails.A_id}/${quickQuoteDetails.Acuvisor_product_id}/`, JSON.stringify(updatedData)).
                        then(
                            response => {
                                if (response.data.details) {
                                    console.log('if part', response.data)
                                    alert(response.data.details + '\n' + response.data.message)

                                } else {
                                    console.log('else part', response)
                                    if (response.data.errorLists) {
                                        alert(response.data.errorLists.map(err => {
                                            return err.errDescription
                                        }))
                                        //resetForm(formData= initialValues)

                                    } else {
                                        navigate(`/health-insurance/product-stastus/${response.data.id}`);

                                    }
                                }
                            }
                        ).catch(errors => {
                            console.log(errors)
                            // resetForm(formData = initialValues);

                        })
                }

            } else if (productDetails.productName === "Tab_Senior") {
                console.log('action', actions)

                FinalCare = createTabCareSeniorReqBdy(data, productDetails.initialValues);
                console.log('Tab_Senior req bdy', FinalCare);
                console.log('Tab_Senior req bdy in JSON', JSON.stringify(FinalCare));

                let updatedData = {
                    // "A_id":quickQuoteDetails.A_id,
                    // "Acuvisor_product_id": quickQuoteDetails.Acuvisor_product_id,
                    "care": FinalCare
                }

                if (window.location.hostname === 'localhost') {
                    await Axios.post(`http://localhost:8000/api/health/create-quote/${quickQuoteDetails.A_id}/${quickQuoteDetails.Acuvisor_product_id}/`, JSON.stringify(updatedData)).
                        then(
                            response => {

                                if (response.data.details) {
                                    console.log('if part', response.data)
                                    alert(response.data.details + '\n' + response.data.message)

                                } else {
                                    console.log('else part', response)
                                    if (response.data.errorLists) {
                                        alert(response.data.errorLists.map(err => {
                                            return err.errDescription
                                        }))
                                        //resetForm(formData= initialValues)

                                    } else {
                                        navigate(`/health-insurance/product-stastus/${response.data.id}`);

                                    }
                                }
                            }
                        ).catch(errors => {
                            console.log(errors)
                            alert(errors)
                            //resetForm(formData = initialValues);

                        })
                } else {
                    await Axios.post(`http://65.0.70.70:8000/api/health/create-quote/${quickQuoteDetails.A_id}/${quickQuoteDetails.Acuvisor_product_id}/`, JSON.stringify(updatedData)).
                        then(
                            response => {

                                if (response.data.details) {
                                    console.log('if part', response.data)
                                    alert(response.data.details + '\n' + response.data.message)

                                } else {
                                    console.log('else part', response)
                                    if (response.data.errorLists) {
                                        alert(response.data.errorLists.map(err => {
                                            return err.errDescription
                                        }))
                                        //resetForm(formData= initialValues)

                                    } else {
                                        navigate(`/health-insurance/product-stastus/${response.data.id}`);

                                    }
                                }
                            }
                        ).catch(errors => {
                            console.log(errors)
                            alert(errors)
                            // resetForm(formData = initialValues);

                        })
                }

            } else if (productDetails.productName === "Tab_CareFreedom") {
                FinalCare = createTabCareFreedomReqBdy(data);
                console.log('Tab_CareFreedom req bdy', FinalCare);
                console.log('Tab_CareFreedom req bdy in JSON', JSON.stringify(FinalCare));

                let updatedData = {
                    // "A_id":quickQuoteDetails.A_id,
                    // "Acuvisor_product_id": quickQuoteDetails.Acuvisor_product_id,
                    "care": FinalCare,

                }

                if (window.location.hostname === 'localhost') {
                    await Axios.post(`http://localhost:8000/api/health/create-quote/${quickQuoteDetails.A_id}/${quickQuoteDetails.Acuvisor_product_id}/`, JSON.stringify(updatedData)).
                        then(
                            response => {

                                if (response.data.details) {
                                    console.log('if part', response.data)
                                    alert(response.data.details + '\n' + response.data.message)
                                } else {
                                    console.log('else part', response)
                                    console.log('else part id', response.data.id)

                                    navigate(`/health-insurance/product-stastus/${response.data.id}`);
                                }
                            }
                        ).catch(errors => {
                            console.log(errors)
                            //resetForm(formData = initialValues);

                        })
                } else {
                    await Axios.post(`http://65.0.70.70:8000/api/health/create-quote/${quickQuoteDetails.A_id}/${quickQuoteDetails.Acuvisor_product_id}/`, JSON.stringify(updatedData)).
                        then(
                            response => {

                                if (response.data.details) {
                                    console.log('if part', response.data)
                                    alert(response.data.details + '\n' + response.data.message)

                                } else {
                                    console.log('else part', response)
                                    if (response.data.errorLists) {
                                        alert(response.data.errorLists.map(err => {
                                            return err.errDescription
                                        }))
                                        //resetForm(formData= initialValues)

                                    } else {
                                        navigate(`/health-insurance/product-stastus/${response.data.id}`);

                                    }
                                }
                            }
                        ).catch(errors => {
                            console.log(errors)
                            // resetForm(formData = initialValues);

                        })
                }

            } else if (productDetails.productName === "POS Medi Classic Individual New Tab" || productDetails.productName === "POS Family Health Optima New Tab") {
                FinalStarHealth = createTabStarReqBdy(data, productDetails.initialValues);
                console.log(`${productDetails.productName}`, FinalStarHealth);
                console.log(`${productDetails.productName}`, JSON.stringify(FinalStarHealth));

                let updatedData = {
                    // "A_id":quickQuoteDetails.A_id,
                    // "Acuvisor_product_id": quickQuoteDetails.Acuvisor_product_id,
                    'starHealth': FinalStarHealth
                }

                if (window.location.hostname === 'localhost') {
                    await Axios.post(`http://localhost:8000/api/health/create-quote/${quickQuoteDetails.A_id}/${quickQuoteDetails.Acuvisor_product_id}/`, JSON.stringify(updatedData)).
                        then(
                            response => {

                                if (response.data.details) {
                                    console.log('if part', response.data)
                                    alert(response.data.details + '\n' + response.data.message)
                                } else {
                                    console.log('else part', response)
                                    navigate(`/health-insurance/product-stastus/${response.data.id}`);
                                }
                            }
                        ).catch(errors => {
                            console.log(errors)
                            //resetForm(formData = initialValues);

                        })
                } else {
                    await Axios.post(`http://65.0.70.70:8000/api/health/create-quote/${quickQuoteDetails.A_id}/${quickQuoteDetails.Acuvisor_product_id}/`, JSON.stringify(updatedData)).
                        then(
                            response => {

                                if (response.data.details) {
                                    console.log('if part', response.data)
                                    alert(response.data.details + '\n' + response.data.message)

                                } else {
                                    console.log('else part', response)
                                    if (response.data.errorLists) {
                                        alert(response.data.errorLists.map(err => {
                                            return err.errDescription
                                        }))
                                        //resetForm(formData= initialValues)

                                    } else {
                                        navigate(`/health-insurance/product-stastus/${response.data.id}`);

                                    }
                                }
                            }
                        ).catch(errors => {
                            console.log(errors)
                            // resetForm(formData = initialValues);

                        })
                }
            }

            /* else {
              Object.entries(data).map((field, index) => {
                switch (field[0]) {
                  case "primaryDetail":
                    field[1].map((nestedField, ind) => {
                      FinalStarReq = { ...FinalStarReq, ...nestedField };
                    });
      
                    break;
                  case "dependentDetail":
                    const dependentObj = {};
                    field[1].map((nestedField, ind) => {
                      dependentObj[`insureds[${ind}]`] = nestedField;
                    });
                    FinalStarReq = { ...FinalStarReq, ...dependentObj };
                    break;
                  case "nomineeDetail":
                    field[1].map((nestedField, ind) => {
                      FinalStarReq = { ...FinalStarReq, ...nestedField };
                    });
                    break;
                }
              });
            } */

            //console.log("FinalCare", FinalCare);

            //////     console.log("StarReq3", FinalStarReq);


            if (productDetails.productName === "Tab_Care" || productDetails.productName === "Tab_Senior" || productDetails.productName === "Tab_CareFreedom") {


            }

        } else {
            setDisableBuy(false)
            window.scrollTo(0, 0)
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
        // const newActiveStep =
        //   isLastStep() && !allStepsCompleted()
        //     ? steps.findIndex((step, i) => !(i in completed))
        //     : activeStep + 1;
        // setActiveStep(newActiveStep);
    };

    //console.log('decript ProductRef' ,decryptedProductRef)
    //console.log('decript DataId',decryptedResponseId)


    //console.log('response Array',responseNumOfInsureds,responseSumInsuredId,responsePlan)

    /* initializing initialValue starts*/
    //productDetails && console.log('true',productDetails)
    // if (Object.keys(productDetails).length > 0 && Object.keys(quickQuoteDetails).length > 0) {
    //   // setInitialValue(productDetails)
    //   // reInitialise()
    //   // if (Object.keys(productDetails).length > 0) {
    //   // setInitialValue(productDetails)

    //   initialValues = createInitialValue(productDetails)

    //   if (productDetails.productName === "Care Freedom" || productDetails.productName === "Care" || productDetails.productName === "Care Senior") {

    //     const careReInitializeValues = (data, array) => {
    //       let newInitialValue = {};

    //       let length = array.num_of_insured

    //       Object.entries(data).map((item) => {
    //         if (Array.isArray(item[1])) {

    //           if (item[0] === 'partyDOList') {
    //             //console.log('ddo list', item[1][0])
    //             let partyObj = item[1][0]
    //             for (let i = 0; i <= length; i++) {

    //               if (newInitialValue[item[0]]) {
    //                 newInitialValue[item[0]][i] = partyObj
    //               } else {
    //                 newInitialValue[item[0]] = item[1]
    //               }

    //             }

    //           }
    //           else {
    //             newInitialValue[item[0]] = item[1]
    //           }
    //         } else if (typeof (item[1]) === 'object') {

    //           newInitialValue[item[0]] = careReInitializeValues(item[1], array)

    //         } else {
    //           newInitialValue[item[0]] = item[1]
    //         }

    //       })
    //       return newInitialValue

    //     }

    //     initialValues = careReInitializeValues(initialValues, quickQuoteDetails)


    //     const insertQuickQuoteData = (values, array) => {
    //       let newInsertedData = {}
    //       //console.log('arra',array)
    //       Object.entries(values).map((item) => {
    //         if (Array.isArray(item[1])) {
    //           if (item[0] === 'partyDOList') {
    //             let newPartyData = item[1]
    //             //console.log('artat', item[1])
    //             //    console.log('insueee',array.insureds)

    //             newPartyData.map((formData, formIndex) => {
    //               array.map((quickData, quickIndex) => {
    //                 //console.log('dfdf',formData,quickData)
    //                 //console.log('indexs',formIndex,quickIndex)
    //                 if (formIndex === quickIndex) {
    //                   newPartyData[formIndex] = { ...formData, ['firstName']: quickData.name, ['birthDt']: quickData.dob, ['roleCd']: "PRIMARY" }
    //                 }
    //                 newPartyData[newPartyData.length - 1] = { ...formData, ['roleCd']: "PROPOSER" }
    //               })
    //             })

    //             console.log('updated new partyDatt', newPartyData)

    //           } else {
    //             newInsertedData[item[0]] = item[1]
    //           }

    //         } else if (typeof (item[1]) === 'object') {
    //           newInsertedData[item[0]] = insertQuickQuoteData(item[1], array)
    //         } else {
    //           newInsertedData[item[0]] = item[1];
    //         }

    //       })
    //       //console.log('newInsertedData',newInsertedData)
    //       return newInsertedData
    //     }

    //     const addInfoPolicy = (values, quickValues) => {
    //       let newPolicyValues = {}
    //       console.log('quickValues', quickValues)
    //       console.log('va', values.intPolicyDataIO.policy)
    //       if (productDetails.productName === "Care Freedom") {
    //         values.intPolicyDataIO.policy = {
    //           ...values.intPolicyDataIO.policy,
    //           ['term']: quickValues.period,
    //           ['coverType']: quickValues.coverType,
    //           ['quotationReferenceNum']: quickValues.abacusId,
    //           ['isPremiumCalculation']: "YES",
    //           ['baseAgentId']: "20008325",
    //           ['baseProductId']: "12001009",
    //           ['sumInsured']: quickValues.sumInsured,
    //         }
    //         values.intPolicyDataIO.policy.partyDOList.map(item => {
    //           console.log('first ', item)
    //           item.partyAddressDOList[0] = {
    //             ...item.partyAddressDOList[0],
    //             'countryCd': 'IND',
    //             'countryCd2': 'IND',
    //           }
    //           item.partyContactDOList[0] = {
    //             ...item.partyContactDOList[0],
    //             'stdCode': "+91",
    //           }
    //         })

    //       } else if (productDetails.productName === "Care") {
    //         values.intPolicyDataIO.policy = {
    //           ...values.intPolicyDataIO.policy,
    //           ['abacusId']: quickValues.abacusId,
    //           ['term']: quickValues.period,
    //           ['coverType']: quickValues.coverType,
    //           ['quotationPremium']: quickValues.premium,
    //           ['quotationReferenceNum']: quickValues.abacusId,
    //           ['isPremiumCalculation']: "YES",
    //           ['eldestMemberAge']: quickValues.eldestMemberAge,
    //           ['baseAgentId']: "20008325",
    //           ['baseProductId']: "10001102",
    //           ['sumInsured']: quickValues.sumInsured,
    //           ['sumInsuredValue']: quickValues.sumInsuredValue
    //         }
    //         values.intPolicyDataIO.policy.partyDOList.map(item => {
    //           console.log('first ', item)
    //           item.partyAddressDOList[0] = {
    //             ...item.partyAddressDOList[0],
    //             'countryCd': 'IND',
    //             'countryCd2': 'IND',
    //           }
    //           item.partyContactDOList[0] = {
    //             ...item.partyContactDOList[0],
    //             'stdCode': "+91",
    //           }
    //         })
    //       } else if (productDetails.productName === "Care Senior") {
    //         values.intPolicyDataIO.policy = {
    //           ...values.intPolicyDataIO.policy,
    //           ['abacusId']: quickValues.abacusId,
    //           ['term']: quickValues.period,
    //           ['quotationPremium']: quickValues.premium,
    //           ['coverType']: quickValues.coverType,
    //           ['quotationReferenceNum']: quickValues.abacusId,
    //           ['isPremiumCalculation']: "YES",
    //           ['eldestMemberAge']: quickValues.eldestMemberAge,
    //           ['baseAgentId']: "20008325",
    //           ['baseProductId']: "10001101",
    //           ['sumInsured']: quickValues.sumInsured,
    //           ['sumInsuredValue']: quickValues.sumInsuredValue
    //         }
    //         values.intPolicyDataIO.policy.partyDOList.map(item => {
    //           //console.log('first ',item)
    //           item.partyAddressDOList[0] = {
    //             ...item.partyAddressDOList[0],
    //             'countryCd': 'IND',
    //             'countryCd2': 'IND',
    //           }
    //           item.partyContactDOList[0] = {
    //             ...item.partyContactDOList[0],
    //             'stdCode': "+91",
    //           }
    //         })
    //       }

    //       /*   Object.entries(values).map((item) => {
    //           if (Array.isArray(item[1])) {
    //             newPolicyValues[item[0]] = [addInfoPolicy(item[1], quickValues)]

    //           } else if (typeof (item[1]) === 'object') {
    //             newPolicyValues[item[0]] = addInfoPolicy(item[1], quickValues)
    //           } else {
    //             newPolicyValues[item[0]] = item[1];
    //           }

    //         }) */
    //       console.log('newPolicyValues', values)
    //     }
    //     insertQuickQuoteData(initialValues, quickQuoteDetails.insureds)
    //     addInfoPolicy(initialValues, quickQuoteDetails)

    //   }
    //   console.log("careinitial", initialValues);


    // }



    // function setInitialValue(product) {
    //   // console.log(product)
    //   let innerObj = product.requestBody.formFields.id_for_vendor
    //   Object.entries(innerObj).map(a =>
    //     initialValues[a[0]] = a[1]
    //   )

    //   product.requestBody.formFields.fields.map(field => {
    //     if (field.htmlElement !== 'Object') {
    //       initialValues[field.name] = ''
    //     } else {
    //       for (let index = 0; index < quickQuoteDetails.number_of_insured; index++) {
    //         let newArray = []
    //         let prevObject = {}
    //         field.obj.map((objField, objectIndex) => {

    //           if (initialValues[field.name]) {

    //             prevObject = { ...prevObject, [objField.name]: '' }
    //             //console.log({ ...initialValues[field.name][index], [objField.name]: '' })


    //             //initialValues[field.name] = initialValues[field.name].push({ ...initialValues[field.name][index], [objField.name]: '' })
    //           } else {
    //             //initialValues[field.name] = [{ [objField.name]: '' }]
    //             initialValues[field.name] = []

    //             prevObject = { ...prevObject, [objField.name]: '' }
    //           }

    //         })
    //         //console.log(prevObject)

    //         initialValues[field.name].push(prevObject)

    //       }

    //     }
    //   })


    // }
    //console.log('initial', initialValues)

    // function reInitialise() {
    //   console.log('ssaa',quickQuoteDetails.insureds)

    //  /*  quickQuoteDetails.insureds= quickQuoteDetails.insureds.map((item)=>
    //     item.relationshipId ==='2' ? {...item,['isPersonalAccidentApplicable']:'false' }:item

    //   ) */
    //   quickQuoteDetails.insureds= quickQuoteDetails.insureds.map((item)=>
    //   item.relationshipId ==='3' ? {...item,['isPersonalAccidentApplicable']:'false',['engageManualLabour']:'false',['engageWinterSports']:'false' }:item

    // )
    //   console.log('update ssaa',quickQuoteDetails.insureds)


    //   Object.entries(initialValues).map(values => {

    //     Object.entries(quickQuoteDetails).map(prevData => {
    //       //console.log(prevData[0],typeof(prevData[1]))

    //       if (values[0] === prevData[0]) {
    //         // console.log(values)
    //         if (typeof (prevData[1]) === 'string') {

    //           initialValues[prevData[0]] = prevData[1]
    //         }
    //         if (typeof (values[1]) === 'object' && typeof (prevData[1]) === 'object') {
    //           // console.log(values[1])
    //           values[1].map((objectItem, i) => {
    //             // console.log(`iteration${i}`, objectItem)

    //             prevData[1].map((newPrevData, j) => {

    //               Object.entries(objectItem).map(newValue => {

    //                 Object.entries(newPrevData).map((newPrevDataItem, k) => {
    //                   //console.log('compaIn', newPrevDataItem[0],j)
    //                   if (newValue[0] === newPrevDataItem[0]) {
    //                     //console.log('key', newPrevDataItem[0], j)
    //                     //console.log('value', newPrevDataItem[1], j)

    //                     initialValues[values[0]][j] = { ...initialValues[values[0]][j], [newPrevDataItem[0]]: newPrevDataItem[1] }

    //                   }
    //                 })


    //               })


    //             })

    //           })
    //         }
    //       }
    //       //console.log('val',values[0])

    //     })
    //   })
    // }

    // console.log('init', initialValues)

    /*  if (Object.keys(productDetails).length > 0 && Object.keys(initialValues).length > 0) {
       if (initialValues.insureds !== undefined) {
   
         console.log('not unde ini', initialValues.insureds)
         //product.requestBody.formFields.fields=product.requestBody.formFields.fields.map(a=>  a.name==="panNumber"? {...a,required:true}:a)
         initialValues.insureds.map((item) => {
           if (item.relationshipId === "3") {
             console.log('iitrr', item)
   
             productDetails.requestBody.formFields.fields = productDetails.requestBody.formFields.fields.map(a =>
               a.name === "insureds" ? a.obj.map()
             )
   
           }
         })
   
       }
   
   
     } */




    /*  Object.entries(quickQuoteDetails).map(prevData => {
       console.log('gaga',prevData)
     }) */
    /*  if (Object.keys(quickQuoteDetails).length > 0) {
       reInitialise()
     } */



    /* initializing initialValue ends*/


    /* validation  pushing normal value and Object value START*/
    // const requireFields = [];
    // const objectsOfData = [];
    // var objectWithField = [];
    // var validationSchema = Yup.object().shape({});
    // var objectValidationSchema = Yup.object().shape({});


    // if (Object.keys(productDetails).length > 0 && Object.keys(quickQuoteDetails).length > 0) {
    //   setRequiredFields(productDetails, quickQuoteDetails)
    // }

    // function setRequiredFields(product, quickDetail) {
    //   console.log('prod det', product)
    //   console.log('quickQu detail', quickDetail['totalPremium'])
    //   if (quickDetail['totalPremium'] >= 100000) {
    //     // console.log('moere')
    //     product.requestBody.formFields.fields = product.requestBody.formFields.fields.map(a => a.name === "panNumber" ? { ...a, required: true } : a)
    //     console.log('updated pro', product.requestBody.formFields.fields)
    //   }



    //   product.requestBody.formFields.fields.map(field =>
    //     field.required ?
    //       field.dataType !== 'object' ?
    //         requireFields.push(field)
    //         /* : field.dataType === 'object' ?
    //             objectWithField.push({ [field.name]: field.obj }) */

    //         : null
    //       : field.dataType === 'object' ?
    //         objectWithField.push({ [field.name]: field.obj })
    //         : null
    //   )

    //   if (objectWithField.length > 0) {
    //     // console.log('objFields', objectWithField)
    //     objectWithField.map(item => {
    //       Object.entries(item).map(aa => {
    //         objectsOfData.push(aa[0])
    //         //console.log(aa)
    //         aa[1].map(field => {
    //           //  console.log(field)
    //           if (field.required) {
    //             requireFields.push({ [aa[0]]: field })
    //           }
    //         })
    //       })
    //     })
    //     //console.log('objectwiData', objectsOfData)
    //   }

    // }
    // if (Object.keys(productDetails).length > 0) {

    //   validationSchema = automaticValidationSchema(productDetails.requestBody.formFields.fields)

    // }

    // console.log('valid schema', validationSchema)

    /* validation  pushing normal value and Object value ENDS*/

    // const utility = "https://acuvisor.auxtomate.in/assets/js/proposalform/General-2.js"





    /*  const getForm = (product, key, j) => {
       let elm;
       //console.log(product)
       switch (product.requestBody[key]["type"]) {
         case "text":
           elm = <div key={j}><label>{product.requestBody[key]["label"]}</label><input type={product.requestBody[key]["type"]} name={product.requestBody[key]["fieldName"]}></input></div>;
           break;
         case "email":
           elm = <div key={j}><label>{product.requestBody[key]["label"]}</label><input type={product.requestBody[key]["type"]} name={product.requestBody[key]["fieldName"]}></input></div>;
           break;
         case "select":
           elm = <div key={j}><label>{product.requestBody[key]["label"]}</label><select name={product.requestBody[key]["fielsName"]} >
             {product.requestBody[key]["options"].map((option, i) => {
               return (<option key={i} value={product.requestBody[key]["options"][i]["value"]}>
                 {product.requestBody[key]["options"][i]["label"]}
               </option>)
   
             })}
           </select></div>;
           break;
       }
   
       return elm;
   
   
   
     }
   
     const submitForm = (e, product) => {
       e.preventDefault();
       let form = document.querySelector("#form_" + product._id);
   
       Axios.post(product.productUrl, { policyDetails: serialize(form, { hash: true }) }).then((response) => {
         console.log(response.data.premium);
         setResponseData(response.data);
   
       });
       // console.log(serialize(form, { hash: true }));
     } */

    // const onSubmit = async (formData, { resetForm }) => {
    //   console.log('FORM Data', formData)
    //   let data = formData;

    //   let updatedData = {};

    //   console.log('submit form', formData)


    //   if (productDetails.productName === "Care Freedom") {
    //     updatedData = createCareFreedomReqBody(data)
    //     updatedData = { "care": updatedData }

    //   } else if (productDetails.productName === "Care") {
    //     updatedData = createCareReqBody(data)
    //     updatedData = { "care": updatedData }
    //   }
    //   else if (productDetails.productName === "Care Senior") {
    //     updatedData = createCareSeniorReqBody(data)
    //     updatedData = { "care": updatedData }
    //   } else {
    //     console.log('dd', formData['insureds'])
    //     let newInsured = formData['insureds']
    //     console.log('leng', newInsured)

    //     for (let i = 0; i < newInsured.length; i++) {
    //       console.log(`${i} index`, newInsured[`${i}`].illness)
    //       if (newInsured[`${i}`].illness === "YES") {

    //         console.log('inside YEs', newInsured[`${i}`].descriptionIllness)
    //         newInsured[`${i}`] = { ...newInsured[`${i}`], ['illness']: newInsured[`${i}`].descriptionIllness }
    //       }
    //     }
    //     console.log('newInsured', newInsured)

    //     formData = { ...formData, ['insureds']: newInsured }
    //     updatedData = formData
    //     updatedData = { "starHealth": updatedData }
    //     //console.log('updated form data', formData)

    //   }

    //   console.log('API POST DATA', updatedData)
    //   // console.log('api url', productDetails.productUrl)

    //   if (productDetails.productName === "Care Freedom" || productDetails.productName === "Care" || productDetails.productName === "Care Senior") {
    //     if (window.location.hostname === 'localhost') {
    //       await Axios.post(`http://localhost:8000/api/health/create-quote/`, JSON.stringify(updatedData)).
    //         then(
    //           response => {

    //             if (response.data.details) {
    //               console.log('if part', response.data)
    //               alert(response.data.details + '\n' + response.data.message)
    //             } else {
    //               console.log('else part', response)
    //               navigate(`/health-insurance/product-stastus/${response.data.id}`);
    //             }
    //           }
    //         ).catch(errors => {
    //           console.log(errors)
    //           resetForm(formData = initialValues);

    //         })
    //     } else {
    //       await Axios.post(`http://65.0.70.70:8000/api/health/create-quote/`, JSON.stringify(updatedData)).
    //         then(
    //           response => {

    //             if (response.data.details) {
    //               console.log('if part', response.data)
    //               alert(response.data.details + '\n' + response.data.message)

    //             } else {
    //               console.log('else part', response)
    //               if (response.data.errorLists) {
    //                 alert(response.data.errorLists.map(err => {
    //                   return err.errDescription
    //                 }))
    //                 resetForm(formData= initialValues)

    //               }else{
    //                 navigate(`/health-insurance/product-stastus/${response.data.id}`);

    //               }
    //             }
    //           }
    //         ).catch(errors => {
    //           console.log(errors)
    //           resetForm(formData = initialValues);

    //         })
    //     }

    //   } else {
    //     await Axios.post(`http://65.0.70.70:8000/api/health/create-quote/`, JSON.stringify(updatedData)).
    //       then(
    //         response => {

    //           if (response.data.details || response.data.errorLists !== undefined) {
    //             console.log('if part', response.data)
    //             // alert(response.data.details + '\n' + response.data.message)
    //             alert(response.data.errorLists.map((error) => {
    //               return (error.errDescription)

    //             }))

    //           } else {
    //             console.log('else part', response)
    //             navigate(`/health-insurance/product-stastus/${response.data.id}`);


    //           }
    //         }
    //       ).catch(errors => {
    //         console.log(errors.response)
    //         //resetForm(formData=initialValues);
    //       })
    //   }

    // }




    const steps = [
        "PRIMARY DETAILS",
        "PROPOSER DETAILS",
        "NOMINEE DETAILS",
    ]



    return (
        /*<>
           <div className="product-list">{productDetails.length > 0 ? "Products List" : "There are no Products in the list"}</div>
           {(productDetails.length > 0)
             ? productDetails.map((product, i) => {
               return (<div className="product-details">
                 <div key={i}><h5><b>Product Name : </b><span><b>{product.productName}</b></span></h5><h5> <span><b>{product.productUrl}</b></span></h5>
     
                   <form id={"form_" + product._id}>
                     {Object.keys(product.requestBody).map((key, j) => (
     
                       getForm(product, key, j)
     
                     ))
     
                     }
                     <button type="submit" onClick={(e) => { submitForm(e, product) }}>Create Quote</button>
     
                   </form>
                   <div id={"policy_details_for_" + product._id}>
                     {responseData.premium}
                   </div>
     
                 </div></div>
               )
             }) : <div></div>}
     
         </> */
        <Container id="content">
            <div className="container py-3">
                <div className="row">
                    <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">

                        <h3 className="fw-400 text-center my-5 heading-text">Create Quote</h3>
                        <div className="mx-auto stepper-container text-wraper">
                           {/*  <Stepper alternativeLabel activeStep={activeStep} className='text-wrap'>
                                {steps.map((label, index) => (
                                    <Step key={index} completed={completed[index]}>
                                        <StepLabel
                                            className='text-wrap'
                                            StepIconComponent={ColorlibStepIcon}
                                        ></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <p className="stepper"></p> */}
                             <div className="mx-auto stepper-container">
                                <div className="Stepper_container">
                                    <div className="steppers">
                                        {steps.map((field, index) => {
                                            return (
                                                <div
                                                    className={
                                                        activeStep === index ? "active" : `step${index}`
                                                    }
                                                    onClick={() => {
                                                        if (index < activeStep)
                                                            setActiveStep(index)
                                                    }}
                                                >
                                                    <p
                                                        className={
                                                            activeStep === index ? "active_text" : "text"
                                                        }
                                                    >
                                                        {field}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                   {/*  <div className="circularRep">
                                        <div className="circular">
                                            {
                                                progress !== 100 ? <CircularProgressWithLabel value={progress} /> : null
                                            }
                                        </div>

                                        {steps[activeStep]}
                                    </div> */}
                                </div>
                                  {/* <div className="circularRep">{steps[activeStep]}</div>   */}
                                <p className="stepper"></p>
                            </div>
                        </div>

                        <div className="bg-white shadow-md rounded-card  pt-sm-4 pb-sm-5 px-sm-5 card-responsive">
                            {console.log('inee', initialValues)}
                            {(initialValues) ? (
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    enableReinitialize={false}
                                    onSubmit={onSubmit}
                                >
                                    {(formik) => {
                                        console.log("formik val", formik);
                                        var newTouch = {};
                                        /*  if (
                                           formik.isSubmitting && Object.keys(formik.touched).length > 0 &&
                                           (formik.touched.primaryDetail.length < 2 || formik.touched.dependentDetail.length < 2 || formik.touched.nomineeDetail.length < 2)
                                         ) */
                                        /*  {
                                           Object.entries(formik.touched).map((field, ind) => {
                                             if (field[0] === "primaryDetail") {
                                               formik.touched.primaryDetail.map((innefield) => {
                                                 for (let i = 0; i < 2; i++) {
                                                   formik.touched.primaryDetail.push(innefield);
                                                 }
                                               });
                                             } else if (field[0] === "dependentDetail") {
                                               formik.touched.dependentDetail.map((innefield) => {
                                                 for (let i = 0; i < 2; i++) {
                                                   formik.touched.dependentDetail.push(innefield);
                                                 }
                                               });
                                             } else {
                                               formik.touched.nomineeDetail.map((innefield) => {
                                                 for (let i = 0; i < 2; i++) {
                                                   formik.touched.nomineeDetail.push(innefield);
                                                 }
                                               });
                                             }
                                           });
                                         } */

                                        return (
                                            <HealthInsuranceForm
                                                Fields={Fields}
                                                activeStep={activeStep}
                                                quickQuoteDetails={quickQuoteDetails}
                                                depInd={depInd}
                                                disableBuy={disableBuy}
                                                handleBack={handleBack}
                                                isLastStep={isLastStep}
                                            />
                                        );
                                    }}
                                </Formik>
                            ) : (
                                <Typography>Loading....</Typography>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>




    );
}

export default Policy;
