import React from 'react'
import InputFields from './InputFields/InputFields'
import FormikRadio from './InputFields/FormikRadio'
import FormikSelect from './InputFields/FormikSelect'
import FirmikFileField from './InputFields/FirmikFileField'
import FormikDatePicker from './InputFields/FormikDatePicker'
function FormikControl(props) {
    const { control, ...rest} = props
    
    switch(control){
        case 'input': 
            return <InputFields {...rest} /> 
        case 'radio':
            return <FormikRadio {...rest}/>    
        case 'select':
             return <FormikSelect {...rest}/> 
        case 'file':
             return <FirmikFileField {...rest}/>  
       case 'date':
          return <FormikDatePicker {...rest}/>           
        default: return  null


    }
}

export default FormikControl