import React from "react";
import { FieldArray, Form, Formik } from "formik";
import FormikControl from "../../Components/LifeInsFormikComponent/FormikControl";


const CreateQuoteObject = (props) => {
  const { name, Field,parentName } = props;
  // console.log(name, Field);
  return (
    <div>
        <div className="mb-3">
          <b>{Field.label}</b> 
        </div>
        <div id={Field.name}>
        {Object.keys(Field).length > 0
        ? Field.obj.map((field, index) => {
            return (
              <div id={field.name}>
                {field.htmlElement === "TextField" ? (
                  <div className="mb-3" key={index} hidden={field.hidden}>
                    <FormikControl
                      control="input"
                      name={`${name}.${field.name}`}
                      manualValidation={field.manualValidation}
                      readOnly={field.readOnly}
                      url={field.url}
                      label={field.label}
                      type={field.dataType}
                    />
                  </div>
                ) : field.htmlElement === "Select" ? (
                  <div className="mb-3" key={index} hidden={field.hidden}>
                    <FormikControl
                      control="select"
                      readOnly={field.readOnly}
                      name={`${name}.${field.name}`}
                      label={field.label}
                      type={field.dataType}
                      options={field.options}
                    />
                  </div>
                ) : field.htmlElement === "TextArea" ? (
                  <div className="mb-3" key={index} hidden={field.hidden}>
                    <FormikControl
                      control="textarea"
                      name={`${name}.${field.name}`}
                      label={field.label}
                      type={field.dataType}
                    />
                  </div>
                ) : field.htmlElement === "Radio" ? (
                  <div className="mb-3" key={index} hidden={field.hidden}>
                    <FormikControl
                      control="radio"
                      name={`${name}.${field.name}`}
                      parentname={parentName}
                      imParent={Field.name}
                      options={field.options}
                      manualValidation={field.manualValidation}
                      label={field.label}
                      type={field.dataType}
                    />
                  </div>
                ) : field.htmlElement === "DatePicker" ? (
                  <div className="mb-3" key={index} hidden={field.hidden}>
                    <FormikControl
                      control="date"
                      name={`${name}.${field.name}`}
                      errors={field.errors}
                      readOnly={field.readOnly}
                      label={field.label}
                      type={field.dataType}
                    />
                  </div>
                ) : field.htmlElement === "checkbox" ? (
                  <div className="mb-3" key={index} hidden={field.hidden}>
                    <FormikControl
                      control="checkBox"
                      name={`${name}.${field.name}`}
                      errors={field.errors}
                      label={field.label}
                      type={field.dataType}
                      options={field.options}
                    />
                  </div>
                ) : field.htmlElement === "File" ? (
                  <div className="mb-3" key={index} hidden={field.hidden}>
                    <FormikControl
                      control="file"
                      name={`${name}.${field.name}`}
                      errors={field.errors}
                      label={field.label}
                      type={field.dataType}
                      options={field.options}
                    />
                  </div>
                ):null}
              </div>
            );
          })
        : null}

        </div>
     
    </div>
  );
};
export default CreateQuoteObject;
