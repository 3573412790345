import { CircularProgress, FormLabel, InputAdornment, MenuItem, Select, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Axios from "axios";
import * as Yup from "yup";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import starQuickReqBody from '../../Utilities/HealthInsuanceRequestBodies/QuickQuoteRequestBodies/StarQuickQuoteReqBody';
import careReqbodyGen from '../../Utilities/HealthInsuanceRequestBodies/QuickQuoteRequestBodies/CareQuickQuoteReqBody';
import CryptoJS from 'crypto-js'
import QuickFieldArray from './QuickFieldArray';
import TextError from '../../Components/HealthInsFormikComponent/TextError';

//var CryptoJS = require("crypto-js");

const QuickQuoteForm = () => {

    const [productDetails, setProductDetails] = useState([]);
    const navigate = useNavigate();

    const initialValues = {
        'proposer_name': '',
        'gender': '',
        'pincode': '',
        'period': '',
        'insureds': [
            {
                'relationShip': '',
                //'DOB': '',
                'name': '',
                'Age': '',
            }
        ],
        'sumInsuredValue': ''
    };

    const validationSchema = Yup.object().shape({
        proposer_name: Yup.string()
            .matches(/^[a-zA-Z]+([a-zA-Z ]*)$/, 'Please Give valid name')
            .min(3, 'Name must have more than 3 digits'),

        pincode: Yup.string()
            .required('Pincode Required')
            .min(6, 'Pin code must have 6 digits')
            .max(6, 'Pin code must not exceed 6 digits'),
        period: Yup.string()
            .required('Period is Required'),
        insureds: Yup.array()
            .of(
                Yup.object().shape({
                    relationShip: Yup.string()
                        .required('Please Select Members'),
                    name: Yup.string()
                        .required('Name is Required '),
                    Age: Yup.string()
                        .required('Age is Required'),

                })
            )
            .required('Required'),
        sumInsuredValue: Yup.string()
            .required('Sum Insured Value Required'),
    })

    const onSubmit = (values, { actions }) => {
        console.log('sumit values', values)
        // let starReq = createStar(values)
        const starReq = starQuickReqBody(values)
        console.log('star', JSON.stringify(starReq))
        //starQuickReqBody(values)
        const careReq = careReqbodyGen(values)
        console.log("careReq", careReq)
        let finalObject = {
            "starHealth": starReq,
            "care": careReq,
            "proposer_name": values['proposer_name'],
            "gender": values['gender']
        }

        console.log('finalObject', finalObject)
        console.log('finalObject', JSON.stringify(finalObject))


         if (window.location.hostname === 'localhost') {
            Axios.post('http://127.0.0.1:8000/api/health/quick-quote/', JSON.stringify(finalObject)).then(
                response => {
                    console.log(response.data);
                    // console.log(response);http://65.0.70.70/
                    //console.log(Object.keys(response.data).length)

                    //        let dependentCount =response.data.dependentCount;
                    let dependentCount = response.data.number_of_insured;

                    if ((Object.keys(response.data).length > 0) && (response.status === 200)) {
                        //navigate(`/quickquote/${response.data.response_id}+${dependentCount}`);
                        //console.log('yes')
                        let encrypteResponseId = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(response.data.response_id), 'secret key 124').toString());
                        navigate(`/health-insurance/products-list/${encrypteResponseId}`);
                    } else if (Object.keys(response.data).length === 0) {
                        alert("Sorry We don't have any matching Products")
                    }
                    // alert(response);
                }
            ).catch(
                error => {
                    //alert('Service Not Available')
                    console.log(error)
                    //console.log(error.status)

                }
            )
        } else {
            Axios.post('http://65.0.70.70:8000/api/health/quick-quote/', JSON.stringify(finalObject)).then(
                response => {
                    console.log(response.data);
                    // console.log(response);http://65.0.70.70/
                    //console.log(Object.keys(response.data).length)

                    //        let dependentCount =response.data.dependentCount;
                    let dependentCount = response.data.number_of_insured;

                    if ((Object.keys(response.data).length > 0) && (response.status === 200)) {
                        //navigate(`/quickquote/${response.data.response_id}+${dependentCount}`);
                        //console.log('yes')
                        let encrypteResponseId = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(response.data.response_id), 'secret key 124').toString());
                        navigate(`/health-insurance/products-list/${encrypteResponseId}`);
                    } else if (Object.keys(response.data).length === 0) {
                        alert("Sorry We don't have any matching Products")
                    }
                    // alert(response);
                }
            ).catch(
                error => {
                    //alert('Service Not Available')
                    console.log(error)
                    //console.log(error.status)

                }
            )
        } 

    }

    useEffect(() => {


    }, [])

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(formik) => (

                <Form >
                    {console.log('touch', formik)}
                    <h3 className="p-2  fw-bold">Tell us about yourself</h3>
                    <div className="mb-3 p-2">
                        <label htmlFor="inputName" className="fw-light">Name</label>
                        <Field type="text" name='proposer_name' id="inputName"
                            className={
                                formik.touched.proposer_name && formik.errors.proposer_name ? " errorStyle form-control bg-white"
                                    : !formik.errors.proposer_name && formik.touched.proposer_name ? " successField form-control bg-white"
                                        : " form-control bg-white"
                            }

                        />

                        <ErrorMessage name='proposer_name' component={TextError} />

                    </div>

                    {/*  <div className="form-group-box">
         <div className="group-one group">
         <input name="gender" class="gender" value="M" type="radio" id="one" />
         <label for="one" class="one"><span>Male</span></label>
         </div>
     </div> */}


                    <div className="d-flex flex-nowrap justify-content-center bd-highlight mb-4">

                        <div className='maleRadioGroup'>
                            <div className='radioGroupBelow'>
                                <label htmlFor="maleGender">
                                    <Field type="radio" name="gender"
                                        value='Male' id="maleGender" />
                                    <span>Male</span>
                                </label>

                            </div>
                        </div>

                        <div className='femaleRadioGroup'>
                            <div className='radioGroupBelow'>
                                <label htmlFor="maleGender">
                                    <Field type="radio" name="gender"
                                        value='Female' id="maleGender"
                                        className={formik.touched.name ? "successField" : null}
                                    />
                                    Female
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="form-group mb-3 p-2">
                        <label htmlFor="inpuPincode" className=" fw-light">Pincode</label>
                        <div className='input -icons'>
                            {/*  <i className="bi bi-check2"></i> */}
                            <Field type="number"
                                //className="form-control bg-white"
                                className={
                                    formik.touched.pincode && formik.errors.pincode ? "errorStyle form-control bg-white"
                                        : !formik.errors.pincode && formik.touched.pincode ? "successField form-control bg-white"
                                            : "form-control bg-white"
                                }

                                name="pincode" id="inpuPincode" aria-describedby="nameHelp"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}

                            />
                        </div>


                        <ErrorMessage name='pincode' component={TextError} />

                    </div>
                    <div className="form-group mb-3 p-2">
                        <label htmlFor="inputPeriod">Period</label>
                        <Field as='select' name="period" id="inputPeriod"
                            className={
                                formik.touched.period && formik.errors.period ? "errorStyle form-control bg-white"
                                    : !formik.errors.period && formik.touched.period ? "successField form-control bg-white"
                                        : "form-control bg-white"
                            }
                        >
                            <option value="">Select Period</option>
                            <option value="1">One Year</option>
                            <option value="2">Two Year</option>
                            <option value="3">Three Year</option>
                        </Field>
                        <ErrorMessage name='period' component={TextError} />

                    </div>

                    <QuickFieldArray formik={formik} />


                    <div className="mb-4 p-2">
                        <label htmlFor="inputAge fw-bold">Sum Assured</label>
                        <Field as='select' name="sumInsuredValue" id="inputAge"
                            className={
                                formik.touched.sumInsuredValue && formik.errors.sumInsuredValue ? "errorStyle form-control bg-white"
                                    : !formik.errors.sumInsuredValue && formik.touched.sumInsuredValue ? "successField form-control bg-white"
                                        : "form-control bg-white"
                            }
                        >
                            <option>Select Options</option>
                            <option value="100000">1,00,000</option>
                            <option value="150000">1,50,000</option>
                            <option value="200000">2,00,000</option>
                            <option value="300000">3,00,000</option>
                            <option value="400000">4,00,000</option>
                            <option value="500000">5,00,000</option>
                        </Field>
                        <ErrorMessage name='sumInsuredValue' component={TextError} />

                    </div>
                    <div className="mb-3  btn-sec p-2  ">

                        {
                            formik.isSubmitting ?
                                <div className='mx-auto'>
                                    <CircularProgress
                                        size={48}
                                        sx={{
                                            color: 'red',

                                        }} />
                                </div>

                                :
                                <>
                                    <button className="Goback_btn text-capitalize"
                                        type="reset"
                                    //onClick={()=>resetForm()}
                                    >
                                        Reset
                                    </button>
                                    <button className="submitBtn text-capitalize " type="submit" disabled={formik.isSubmitting}>
                                        Submit
                                    </button>
                                </>

                        }
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default QuickQuoteForm