import React from 'react'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


const DocumentsNeeded = ({ doc1, doc2, doc3, doc4 }) => {
    return (
        <div className="id_grid">
            {
                doc1 !== "" ?
                    <div className='ids'>
                        <CheckCircleIcon style={{ color: "#18c6b1" }} />
                        {doc1}
                    </div>

                    : null
            }
            {
                doc2 !== "" ?
                    <div className='ids'>
                        <CheckCircleIcon style={{ color: "#18c6b1" }} />
                        {doc2}
                    </div>

                    : null
            }
            {
                doc3 !== "" ?
                    <div className='ids'>
                        <CheckCircleIcon style={{ color: "#18c6b1" }} />
                        {doc3}
                    </div>

                    : null
            }
            {
                doc4 !== "" ?
                    <div className='ids'>
                        <CheckCircleIcon style={{ color: "#18c6b1" }} />
                        {doc4}
                    </div>

                    : null
            }
        </div>
    )
}

export default DocumentsNeeded