
import { Box, Button, Card, CardActions, CardContent, Grid, Stack, styled, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from 'react';
import Axios from "axios";


const DownLoadButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#0d409b'),
    backgroundColor: '#0d409b',
    border: '1px solid',
    borderRadius: '24px',
    '&:hover': {
        backgroundColor: '#0d409b',
    },
}));

const BackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fafafa'),
    backgroundColor: '#fafafa',
    border: '1px solid',
    borderRadius: '24px',
    '&:hover': {
        backgroundColor: '#fafafa',
    },
}));


function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "Policy.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}


const SuccesStatus = (props) => {

    const { data } = props


    const downLoadPolicyProposal = () => {

    }
    const downLoadPolicy = () => {
        //let domain = ''
        console.log('clicked download')

        if(data.policyStatus === 3 && data.status === 1){
            console.log('from prop')
            downloadPDF(data.pdf)
        }

        /* if (window.location.hostname === 'localhost') {
            
        }else if (window.location.hostname === '3.111.231.158') {
            domain = process.env.REACT_APP_PRODUCTION_DJANGO
        }else {
            domain = process.env.REACT_APP_UAT_DJANGO
        } */
     /*    Axios.get(`${domain}/api/health-insurance/care/policy-status?proposalNum=${Number(data.proposalNum)}`).then(
            (resp)=>{
                console.log('resp download',resp)
                if(resp.data.policyStatus === 3 && resp.data.status === 1){
                    downloadPDF(resp.data.pdf)
                }
            }
        ).catch(
            error=>{
                alert(error)
            }
        ) */

    }
    const downLoadPolicy_Ecard = () => {

    }

    const goToHome = () => {
        window.open('', '_self').close()
    }

    useEffect(() => {



    }, [])


    return (
        <>
            <div className="header-sec">
                <p className="insurence-title">
                    Health Insurance <KeyboardArrowDownIcon />
                </p>
            </div>
            <Box sx={{ padding: '16px', marginTop: '16px' }}>
                <Card >
                    <CardContent>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px', }}>

                                <Typography variant='h6' color='green'
                                    sx={{ fontWeight: 600 }}
                                >
                                    Transaction Successfull
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px', }}>
                                <CheckCircleOutlineRoundedIcon
                                    style={{ color: "green", fontSize: "40px", }}
                                />
                            </Box>
                            <Box sx={{ padding: '28px', marginTop: '16px', }}>
                                <Grid container spacing={2} >
                                    <Grid item xs={6}>
                                        <Typography
                                            sx={{ fontWeight: 400, textTransform: 'capitalize' }}
                                        >
                                            Transaction Id
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>
                                            {"N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            sx={{ fontWeight: 400, textTransform: 'capitalize' }}
                                        >
                                            policy no
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>
                                            {data.policyNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Box>

                    </CardContent>
                </Card>
                <Stack spacing={2} sx={{ padding: '16px', marginTop: '16px' }}>
                    {/* <DownLoadButton>DownLoad policy proposal</DownLoadButton> */}
                    <DownLoadButton onClick={downLoadPolicy}>
                        DownLoad policy
                    </DownLoadButton>
                    {/*  <DownLoadButton>Download policy E-card</DownLoadButton> */}
                    <BackButton onClick={goToHome}>back to home</BackButton>
                </Stack>
            </Box>
        </>
    )
}

export default SuccesStatus