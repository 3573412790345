import { Box, Button, Card, CardActions, CardContent, Grid, Stack, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';


const DownLoadButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#0d409b'),
    backgroundColor: '#0d409b',
    border: '1px solid',
    borderRadius: '24px',
    '&:hover': {
        backgroundColor: '#0d409b',
    },
}));

const BackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fafafa'),
    backgroundColor: '#fafafa',
    border: '1px solid',
    borderRadius: '24px',
    '&:hover': {
        backgroundColor: '#fafafa',
    },
}));

const LoadingStatus = (props) => {

    const [msg, setMsg] = useState("")

    const { status, data, proposerNum } = props

    let navigate = useNavigate();


    useEffect(() => {
        switch (status) {
            case 4: setMsg("payment Failure")
                return
            case 5: setMsg("waiting for approval")
                return
            case 6: setMsg("policy declined")
                return
            case 9: setMsg("")
                return
        }


    }, [])

    const liveStatusHandler = () => {

        /* let path = `/health-insurance/policy-status/:${proposerNum}`;
        navigate(path) */
        console.log('live status')
        window.location.reload();

        /*   if (window.location.hostname === 'localhost') {
  
              Axios.get(`http://127.0.0.1:8000/api/health-insurance/care/policy-status?proposalNum=${proposerNum}`,).then(
                  response => {
                      console.log(response.data);
  
  
  
                  }
              ).catch(
                  error => {
                      //setError(true)
                      console.log(error)
                  }
              )
  
          } else {
              Axios.post(`http://65.0.70.70:8000/api/health-insurance/care/policy-status?proposalNum=${proposerNum}`,).then(
                  response => {
                      console.log(response.data);
  
                     
                  }
              ).catch(
                  error => {
                      //alert('Service Not Available')
                      console.log(error)
                      //console.log(error.status)
  
                  }
              )
          } */
    }

    return (
        <>
            <div className="header-sec">
                <p className="insurence-title">
                    Health Insurance <KeyboardArrowDownIcon />
                </p>
            </div>
            <Box sx={{ padding: '16px', marginTop: '16px' }}>

                <Card >
                    <CardContent>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px', }}>

                                <Typography variant='h6' color='green'
                                    sx={{ fontWeight: 600, textTransform: 'capitalize' }}
                                >
                                    {msg}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px', }}>
                                <CheckCircleOutlineRoundedIcon
                                    style={{ color: '#312d2d', fontSize: "40px", }}
                                />
                            </Box>
                            <Box sx={{ padding: '28px', marginTop: '16px', }}>
                                {
                                    status === 4 || status === 6 ?
                                        <Box>
                                            {
                                                status === 4 ?
                                                    <Typography
                                                        sx={{ fontWeight: 400, margin: 'auto', padding: 'auto' }}
                                                    >
                                                        The paid amount will be refunded within 5-7 days
                                                    </Typography>
                                                    : <Typography
                                                        sx={{ fontWeight: 400, margin: 'auto', padding: 'auto' }}
                                                    >
                                                        The policy has been rejected
                                                    </Typography>
                                            }

                                        </Box>
                                        :
                                        <Grid container spacing={2} >
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{ fontWeight: 400, textTransform: 'capitalize' }}
                                                >
                                                    {/* {status === 6 ? '' : 'current Status'} */}
                                                    {status === 5 ? 'current Status' : ''}
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{ textTransform: 'capitalize' }}
                                                >
                                                    {status === 6 ? '' : 'went for underwritting'}

                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{ fontWeight: 400, textTransform: 'capitalize' }}
                                                >
                                                    {status === 6 ? '' : 'policy no'}
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{ fontWeight: 400, textTransform: 'capitalize' }}
                                                >
                                                    {status === 6 ? ''
                                                        : data === undefined || Object.keys(data).length === 0 ?
                                                            'N/A'
                                                            :
                                                            data.policyNumber
                                                    }
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                }

                            </Box>

                        </Box>

                    </CardContent>
                </Card>
                <Stack spacing={2} sx={{ padding: '16px', marginTop: '16px' }}>
                  {/*   {
                        status === 5 ?
                            <DownLoadButton
                            onClick={liveStatusHandler}
                            >
                                live status
                            </DownLoadButton>
                            : ''
                    } */}

                    <BackButton 
                    //onClick={}
                    >
                    back to home</BackButton>
                </Stack>
            </Box>
        </>
    )
}

export default LoadingStatus