import automaticValidationSchema from "../../../Utilities/ValidationSchemaSetup/automaticValidationSchema";
import React, { useEffect, useState } from "react";
import createInitialValue from "../../../Utilities/InitialValuesSetup/createInitialValue";
// import { useParams } from "react-router-dom";
import Axios from "axios";
// import ShriRamreqbodyGen from "../RequestbodyGeneration/Shriram";
import "../../../Assets/css/LifeInsurance/CreateQuoteForm.css";
// import PEDvalues2 from "../JSONbodies/PED2";
//import VPJson from "../JSONbodies/validateProposalJson";
import _ from "lodash";
import { FieldArray, Form, Formik } from "formik";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import CreateQuoteObject from "../CreateQuoteObject";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import CreateQuoteArray from "../CreateQuoteArray";
import FormikControl from "../../../Components/LifeInsFormikComponent/FormikControl";
// import JSON_TO_YUP from "./FormikComponents/YupValidation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import CreateQuoteDependent from "./QuickQoutes/CreateQuoteDependent";
// import CreateQuoteObjectView from "./FormikComponents/CreatQuoteChilds/CreateQuoteObjectView";
import { useNavigate, useParams } from "react-router";
//import transData from "../TransferData/TransferData";
import ShriRamreqbodyGen from "../../../Utilities/LifeInsuranceRequestBodies/CreateQuoteRequestBodies/ShriRamReqBodyGen";
import swal from 'sweetalert2';

function Validateproposal(props) {
  const [productName, setProductName] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [requestBody, setrequestBody] = useState({});
  const [newRequestBody, setNewRequestBody] = useState({});
  const [productDetails, setProductDetails] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [depInd, setdepInd] = useState(1);

  const [transferData, setTransferData] = useState({});
  const [proposerData, setProposerdata] = useState(true)
  const [familyArray, setfamilyArray] = useState(2);
  const [nomineeArray, setnomineeArray] = useState(1)
  const [nomineeShare, setNomineeShare] = useState(0)
  const [pdata, setPdata] = useState(false);


  const Nominee = {
    AppointeeFirstName: "",
    AppointeeLastName: "",
    AppointeeMiddleName: "",
    DateOfBirth: "",
    FirstName: "",
    LastName: "",
    MiddleName: "",
    NomineeGender: "",
    RelationshiptoAssured: "",
    RelationshiptoNominee: "",
    ShareofNominee: "",
    Title: "",
  }

  const FFamily = {
    AgeatDeath: "",
    DateofDeath: "",
    DeathReason: "",
    HealthStatus: "",
    Name: "",
    Relationship: "101",
    Status: "",
  }


  let flag = 0;
  var pData;
  const { id } = useParams();
  let navigate = useNavigate();
  var initialValues;
  var FinalIni;
  console.log(id, "in validate form");

  if (pdata === true) {
    var steps = ["PRIMARY DETAILS", "PED QUESTION", "NOMINEE DETAILS"];
  } else {
    var steps = ["PRIMARY DETAILS", "PED QUESTION", "PROPOSER DETAILS", "NOMINEE DETAILS"];
  }

  if (Object.keys(productDetails).length > 0) {
    initialValues = createInitialValue(productDetails);
    console.log(pData, "pdata");
    if (Object.entries(transferData).length > 0) {
     // FinalIni = transData(transferData, initialValues, pData);
      console.log(FinalIni, "final");
    }

    // console.log("initialValues", initialValues);
  }


  var Fields = [];
  if (Object.keys(productDetails).length > 0) {
    productDetails.requestBody.formFields.fields.map((field, index) => {
      if (field.name === "Proposer" && pdata === true) {

      }
      else {
        Fields.push(field);
      }

    });
  }
  // console.log("Fields", Fields);

  if (Object.keys(productDetails).length > 0) {
    var SchemaArray = [];
    productDetails.requestBody.formFields.fields.map((field, index) => {
      let seperatearray = [];

      if (field.name === "Proposer" && pdata === true) {

      }
      else {
        seperatearray.push(field);
        SchemaArray.push(seperatearray);
      }
    });
  }

  var validationSchema = Yup.object().shape({});
  if (Object.keys(productDetails).length > 0) {
    validationSchema = automaticValidationSchema(SchemaArray[activeStep]);
  }
  // console.log("validSchema", validationSchema);

  const utility =
    "https://acuvisor.auxtomate.in/assets/js/proposalform/General-2.js";

  useEffect(() => {
    let productDetailsArray = [];
    let id_split = id.split('+')
    // setdepInd(1);
    // setProductDetails(VPJson);
    Axios.get(
      `http://localhost:3002/api/v1/lifeInsurance/validate-form/633ea88106613151abd0dcb7`
      //`http://localhost:3002/api/v1/lifeInsurance/validate-form/${id_split[1]}`
    ).then((response) => {
      if (response.status === 200) {
        console.log("response from node", response);
        setProductDetails(response.data);
      }
    });
    Axios.get(
      `http://65.0.70.70:8000/api/shriram/plp-validate-response/${id_split[0]}/`
      //`http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/shriram/plp-validate-response/${id_split[0]}/`
    ).then((response) => {
      if (response.status === 200) {
        console.log("response in validate", response);
        setTransferData(response.data);
      }
    });

    let utilityScript = document.createElement("script");
    utilityScript.src = utility;
    document.body.appendChild(utilityScript);
  }, []);


  const verifyValidate = (ShriRamValues) => {
    Axios.post(
      `http://65.0.70.70:8000/api/life-insurance/validate-proposal/`,
      //`http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/life-insurance/validate-proposal/`,
      ShriRamValues
    ).then((response) => {
      console.log(response, "response in validate form")
      /* if (response.data.ResultSet.ResponseCode === "200") { */
      if (response.data.ResultSet.ResponseCode !== "200") {
        alert(response.data.ResultSet.ResponseMsg)

      } else {

        swal({
          title: "Successfully Validated The Form",
          // text: "Do you want add Bussiness Logic",
          icon: "success",
          buttons: {
            ok: {
              // Button
              text: "OK", // Text displayed
              value: "yes", // Value returned
            }

          },
        }).then(value => {
          if (value == "yes") {
            navigate(`/life-insurance/getbi/${id}`)
          }
        })

      }

      /* }else{
        alert(response.data.ResultSet.ResponseMsg)
      } */


    });
  }

  const onSubmit = (values, actions) => {
    if (isLastStep()) {
      const ShriRamValues = ShriRamreqbodyGen(values);
      console.log(ShriRamValues, "FinalRequest");
      console.log(transferData, "trans");

      let id_split = id.split('+')
      let updatedtrans = transferData;

      let Laname;
      if (ShriRamValues.Request.Proposal.LifeType === "S") {
        Laname = `${ShriRamValues.Request.Proposal.LifeAssured.FirstName}` + " " + `${ShriRamValues.Request.Proposal.LifeAssured.MiddleName}` + " " + `${ShriRamValues.Request.Proposal.LifeAssured.LastName}`
      }
      else {
        Laname = `${ShriRamValues.Request.Proposal.Proposer.FirstName}` + " " + `${ShriRamValues.Request.Proposal.Proposer.MiddleName}` + " " + `${ShriRamValues.Request.Proposal.Proposer.LastName}`
      }

      updatedtrans = { ...updatedtrans, ['LAName']: Laname }
      console.log(updatedtrans, "transdata")

      Axios.put(
        `http://65.0.70.70:8000/api/shriram/plp-validate-response/${id_split[0]}/`,
        //`http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/shriram/plp-validate-response/${id_split[0]}/`,
        updatedtrans
      ).then((response) => {
        if (response.status === 200) {
          console.log("response in validate updated trans", response);
          if (response.data.message === "updated successfully") {
            verifyValidate(ShriRamValues)
          }
          // setTransferData(response.data);
        }
      });


      /*    Axios.post(
           `http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/life-insurance/validate-proposal/`,
           ShriRamValues
         )
           .then((response) => {
             console.log(response, "response")
              if (response.data.ResultSet.ResponseCode === "200" ) { 
               console.log("response data", response.data)
               swal({
                 title: "Successful Details saved in the list",
                 // text: "Do you want add Bussiness Logic",
                 icon: "success",
                 buttons: {
                   ok: {
                     // Button
                     text: "OK", // Text displayed
                     value: "yes", // Value returned
                   }
   
                 },
               }).then(value => {
                 if (value == "yes") {
                   navigate(`/getbi/${id}`)
                 }
               })
                navigate(`productlistcard/${response.data.response_id}`); 
              }
             else {
               alert(response.data.ResultSet.ResponseMsg)
             } 
   
           }) */

      // navigate("/getbi")

      // setProductDetails(response.data);

      // navigate("/stringify", { state: { value: ShriRamValues } });
    } else {
      console.log('vvvvv', values.primaryDetails)
      console.log('PAN', values.primaryDetails.LifeAssured)
      console.log('action', actions)

      let flag = false;

      values.primaryDetails.map((item, index) => {
        /* console.log('Plan TotalPremium',item.Plan.TotalPremium)
        console.log('PAN',item.LifeAssured.PAN)
        console.log('PAN length ',item.LifeAssured.PAN.length) */
        let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        if (item.Plan.TotalPremium > 49999) {
          //setFieldError(name, "Can't Proceed further")
          if (item.LifeAssured.PAN === '') {
            flag = false;
            actions.setFieldError(`primaryDetails[${index}].LifeAssured.PAN`, 'PAN is Required')

          } else if (item.LifeAssured.PAN.length > 10) {
            flag = false;
            actions.setFieldError(`primaryDetails[${index}].LifeAssured.PAN`, 'PAN number cannot Exceeds 10 digits')
          } else if (item.LifeAssured.PAN.length < 10) {
            flag = false;
            actions.setFieldError(`primaryDetails[${index}].LifeAssured.PAN`, 'PAN number must be 10 digits')
          } else if (!regpan.test(item.LifeAssured.PAN)) {
            flag = false;
            actions.setFieldError(`primaryDetails[${index}].LifeAssured.PAN`, 'Entert Valid PAN number')
          } else {
            flag = true;
          }

        } else {
          flag = true;
        }

        if (item.LifeType == "S") {
          Object.entries(values.Proposer[0]).map(proposer => {
            Object.entries(values.primaryDetails[0].LifeAssured).map(primary => {
              //console.log('sss',proposer[0],primary[0])

              if (proposer[0] === primary[0]) {
                console.log('sss', proposer[0], primary[1])

                //values={...values,['Proposer']:[{...values.Proposer[0],[`${proposer[0]}`]:primary[1]}]}
                //values={...values,['Proposer']:[{[`${proposer[0]}`]:primary[1]}]}
                values.Proposer = [{ ...values.Proposer[0], [`${proposer[0]}`]: primary[1] }]

                //values ={ ...values, ['Proposer']:[  ] }
                //values.Proposer[0] = { ...values.Proposer[0], [`${proposer[0]}`]: primary[1] }
              }
            })
          })

          console.log('hshs', values.Proposer[0], values.primaryDetails[0].LifeAssured)
        } else {
          Object.entries(values.Proposer[0]).map(proposer => {
            values.Proposer = [{ ...values.Proposer[0], [`${proposer[0]}`]: '' }]

          })
        }


      })
      console.log('flag', flag)
      setActiveStep(activeStep + 1);
      if (flag === true) {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? activeStep : prevActiveStep - 1
    );
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const iniVal = initialValues;
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: "#00008B",
    // theme.palette.mode === "dark" ? theme.palette.grey[700] : "",
    zIndex: 1,
    color: "white",
    width: 110,
    height: 38,
    fontSize: 10,
    display: "flex",

    justifyContent: "center",

    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "white",
      color: "black",
      borderWidth: "solid",
      borderColor: "black",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#00008B",
      color: "white",
      fontSize: 10,
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: "PRIMARY DETAILS",
      2: "PED QUESTION",
      3: "PROPOSER DETAILS",
      4: "NOMINEE DETAILS",
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  // return (
  //   <>
  //     <div className="header-sec"></div>
  //     <div id="content">
  //       <div className="container py-3">
  //         <div className="row">
  //           <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
  //             <h3 className="fw-400 text-center my-5 heading-text">
  //               Validate Proposal Form
  //             </h3>
  //             <div className="Form_container">
  //               <div className="mx-auto stepper-container">
  //                 <Stepper alternativeLabel activeStep={activeStep}>
  //                   {steps.map((label, index) => (
  //                     <Step key={label} completed={completed[index]}>
  //                       <StepLabel
  //                         StepIconComponent={ColorlibStepIcon}
  //                       ></StepLabel>
  //                     </Step>
  //                   ))}
  //                 </Stepper>
  //                 <p className="stepper"></p>
  //               </div>
  //               <div className="bg-white shadow-md rounded-card  pt-sm-4 pb-sm-5 px-sm-5 card-responsive">
  //                 {initialValues ? (
  //                   <Formik
  //                     initialValues={FinalIni}
  //                     validationSchema={validationSchema}
  //                     enableReinitialize={true}
  //                     onSubmit={onSubmit}
  //                   >
  //                     {(formik) => {
  //                       console.log("formik val", formik);
  //                       var newTouch = {};

  //                       return (
  //                         <Form autoComplete="off">
  //                           <div>
  //                             {Object.keys(Fields[activeStep]).length > 0 ? (
  //                               <FieldArray
  //                                 name={Fields[activeStep].name}
  //                                 render={(arrayHelpers) => {
  //                                   // console.log(depInd, "888");
  //                                   return (
  //                                     <div>
  //                                       {_.times(depInd, (i) => {
  //                                         const objName =
  //                                           Fields[activeStep].name;
  //                                         const arrObjName = `${objName}[${i}]`;
  //                                         //  console.log("name...",objName,arrObjName)
  //                                         return (
  //                                           <div>
  //                                             {Fields[activeStep].obj.map(
  //                                               (field, index) => {
  //                                                 return field.htmlElement ===
  //                                                   "TextField" ? (
  //                                                   <div
  //                                                     className="mb-3"
  //                                                     key={index}
  //                                                     hidden={field.hidden}
  //                                                   >
  //                                                     <FormikControl
  //                                                       control="input"
  //                                                       name={`${arrObjName}.${field.name}`}
  //                                                       manualValidation={
  //                                                         field.manualValidation
  //                                                       }
  //                                                       errors={formik.errors}
  //                                                       readOnly={
  //                                                         field.readOnly
  //                                                       }
  //                                                       transperData={
  //                                                         productDetails.transperData
  //                                                       }
  //                                                       url={field.url}
  //                                                       label={field.label}
  //                                                       type={field.dataType}
  //                                                       touched={formik.touched}
  //                                                     />
  //                                                   </div>
  //                                                 ) : field.htmlElement ===
  //                                                   "Select" ? (
  //                                                   <div
  //                                                     className="mb-3"
  //                                                     key={index}
  //                                                     hidden={field.hidden}
  //                                                   >
  //                                                     <FormikControl
  //                                                       control="select"
  //                                                       readOnly={
  //                                                         field.readOnly
  //                                                       }
  //                                                       name={`${arrObjName}.${field.name}`}
  //                                                       errors={formik.errors}
  //                                                       label={field.label}
  //                                                       type={field.dataType}
  //                                                       touched={formik.touched}
  //                                                       options={field.options}
  //                                                     />
  //                                                   </div>
  //                                                 ) : field.htmlElement ===
  //                                                   "TextArea" ? (
  //                                                   <div
  //                                                     className="mb-3"
  //                                                     key={index}
  //                                                     hidden={field.hidden}
  //                                                   >
  //                                                     <FormikControl
  //                                                       control="textarea"
  //                                                       name={`${arrObjName}.${field.name}`}
  //                                                       errors={formik.errors}
  //                                                       label={field.label}
  //                                                       type={field.dataType}
  //                                                       touched={formik.touched}
  //                                                     />
  //                                                   </div>
  //                                                 ) : field.htmlElement ===
  //                                                   "Radio" ? (
  //                                                   <div
  //                                                     className="mb-3"
  //                                                     key={index}
  //                                                     hidden={field.hidden}
  //                                                   >
  //                                                     <FormikControl
  //                                                       control="radio"
  //                                                       name={`${arrObjName}.${field.name}`}
  //                                                       errors={formik.errors}
  //                                                       dependent={
  //                                                         field.dependent
  //                                                       }
  //                                                       options={field.options}
  //                                                       manualValidation={
  //                                                         field.manualValidation
  //                                                       }
  //                                                       label={field.label}
  //                                                       type={field.dataType}
  //                                                       touched={formik.touched}
  //                                                     />
  //                                                   </div>
  //                                                 ) : field.htmlElement ===
  //                                                   "DatePicker" ? (
  //                                                   <div
  //                                                     className="mb-3"
  //                                                     key={index}
  //                                                     hidden={field.hidden}
  //                                                   >
  //                                                     <FormikControl
  //                                                       control="date"
  //                                                       name={`${arrObjName}.${field.name}`}
  //                                                       errors={field.errors}
  //                                                       readOnly={
  //                                                         field.readOnly
  //                                                       }
  //                                                       label={field.label}
  //                                                       type={field.dataType}
  //                                                     />
  //                                                   </div>
  //                                                 ) : field.htmlElement ===
  //                                                   "checkbox" ? (
  //                                                   <div
  //                                                     className="mb-3"
  //                                                     key={index}
  //                                                     hidden={field.hidden}
  //                                                   >
  //                                                     <FormikControl
  //                                                       control="checkBox"
  //                                                       name={`${arrObjName}.${field.name}`}
  //                                                       errors={field.errors}
  //                                                       label={field.label}
  //                                                       type={field.dataType}
  //                                                       options={field.options}
  //                                                     />
  //                                                   </div>
  //                                                 ) : field.htmlElement ===
  //                                                   "Object" ? (
  //                                                   <div
  //                                                     className="mb-3"
  //                                                     key={index}
  //                                                     hidden={field.hidden}
  //                                                   >
  //                                                     <CreateQuoteObject
  //                                                       name={`${arrObjName}.${field.name}`}
  //                                                       Field={field}
  //                                                     />
  //                                                   </div>
  //                                                 ) : field.htmlElement ===
  //                                                   "Array" ? (
  //                                                   <div
  //                                                     className="mb-3"
  //                                                     key={index}
  //                                                     hidden={field.hidden}
  //                                                   >
  //                                                     <CreateQuoteArray
  //                                                       name={`${arrObjName}`}
  //                                                       Field={field}
  //                                                     />
  //                                                   </div>
  //                                                 ) : null;
  //                                               }
  //                                             )}
  //                                           </div>
  //                                         );
  //                                       })}
  //                                     </div>
  //                                   );
  //                                 }}
  //                               ></FieldArray>
  //                             ) : null}
  //                           </div>

  //                           <div className="button-sec">
  //                             <button
  //                               className=" btn"
  //                               type="button"
  //                               onClick={handleBack}
  //                             >
  //                               Back
  //                             </button>
  //                             <button className="btn" type="submit">
  //                               Next
  //                             </button>
  //                           </div>
  //                         </Form>
  //                       );
  //                     }}
  //                   </Formik>
  //                 ) : (
  //                   <Typography>Loading....</Typography>
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <>
      <div className="header-sec"></div>
      <div id="content">
        <div className="container py-3">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
              <h3 className="fw-400 text-center my-5 heading-text">
                Validate Proposal Form
              </h3>
              <div className="Form_container">
                <div className="mx-auto stepper-container">
                  <div className="Stepper_container">
                    <div className={pdata === true ? "r_steppers" : "steppers"}>
                      {
                        steps.map((field, index) => {
                          return (
                            <div className={activeStep === index ? "active" : `step${index}`}>
                              {field}
                            </div>
                          )

                        })
                      }

                    </div>
                    {/* <div className="circularRep">
                      <div className="circular">
                      {
                      progress!==100?<CircularProgressWithLabel value={progress}/>:<CheckIcon/>
                      }
                      </div>
                    
                    {steps[activeStep]}
                    </div> */}
                  </div>
                  <p className="stepper"></p>
                </div>
                <div className="bg-white shadow-md rounded-card  pt-sm-4 pb-sm-5 px-sm-5 card-responsive">
                  {initialValues ? (
                    <Formik
                      initialValues={FinalIni}
                      validationSchema={validationSchema}
                      enableReinitialize={true}
                      onSubmit={onSubmit}
                    >
                      {(formik) => {
                        // var total=0;
                        console.log("formik val", formik);

                        // 
                        // console.log(total,"total")
                        var newTouch = {};

                        return (
                          <Form autoComplete="off">
                            <div>

                              <div>
                                {Object.keys(Fields[activeStep]).length > 0 ? (
                                  <FieldArray
                                    name={Fields[activeStep].name}
                                    render={(arrayHelpers) => {
                                      // console.log(depInd, "888");
                                      return (
                                        <div className='Array_end'>
                                          {_.times((Fields[activeStep].name === "nomineeDetails" ? nomineeArray : 1), (i) => {
                                            if (Fields[activeStep].name === "nomineeDetails") {
                                              if (arrayHelpers.form.values.nomineeDetails.length - 1 < i) {
                                                arrayHelpers.form.values.nomineeDetails.push(Nominee)
                                              }
                                            }

                                            const objName =
                                              Fields[activeStep].name;
                                            const arrObjName = `${objName}[${i}]`;
                                            //  console.log("name...",objName,arrObjName)
                                            return (
                                              <div id={Fields[activeStep].name}>
                                                {Fields[activeStep].obj.map(
                                                  (field, index) => {
                                                    return field.htmlElement ===
                                                      "TextField" ? (
                                                      <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                      >
                                                        <FormikControl
                                                          control="input"
                                                          name={`${arrObjName}.${field.name}`}
                                                          manualValidation={
                                                            field.manualValidation
                                                          }
                                                          parentname={Fields[activeStep].name}
                                                          errors={formik.errors}
                                                          readOnly={
                                                            field.readOnly
                                                          }
                                                          transperData={
                                                            productDetails.transperData
                                                          }
                                                          url={field.url}
                                                          label={field.label}
                                                          type={field.dataType}
                                                          touched={formik.touched}
                                                          nomineeArray={nomineeArray}
                                                          nomineeShare={nomineeShare}
                                                          setnomineeArray={setnomineeArray}
                                                          setNomineeShare={setNomineeShare}
                                                          array={i}
                                                        />
                                                      </div>
                                                    ) : field.htmlElement ===
                                                      "Select" ? (
                                                      <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                      >
                                                        <FormikControl
                                                          control="select"
                                                          readOnly={
                                                            field.readOnly
                                                          }
                                                          name={`${arrObjName}.${field.name}`}
                                                          setProposer={setProposerdata}
                                                          errors={formik.errors}
                                                          label={field.label}
                                                          type={field.dataType}
                                                          arrayIndex={i}
                                                          touched={formik.touched}
                                                          options={field.options}
                                                          setPdata={setPdata}
                                                        />
                                                      </div>
                                                    ) : field.htmlElement ===
                                                      "TextArea" ? (
                                                      <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                      >
                                                        <FormikControl
                                                          control="textarea"
                                                          name={`${arrObjName}.${field.name}`}
                                                          errors={formik.errors}
                                                          label={field.label}
                                                          type={field.dataType}
                                                          touched={formik.touched}
                                                        />
                                                      </div>
                                                    ) : field.htmlElement ===
                                                      "Radio" ? (
                                                      <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                      >
                                                        <FormikControl
                                                          control="radio"
                                                          name={`${arrObjName}.${field.name}`}
                                                          errors={formik.errors}
                                                          dependent={
                                                            field.dependent
                                                          }
                                                          options={field.options}
                                                          manualValidation={
                                                            field.manualValidation
                                                          }

                                                          label={field.label}
                                                          type={field.dataType}
                                                          touched={formik.touched}
                                                        />
                                                      </div>
                                                    ) : field.htmlElement ===
                                                      "DatePicker" ? (
                                                      <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                      >
                                                        <FormikControl
                                                          control="date"
                                                          name={`${arrObjName}.${field.name}`}
                                                          errors={field.errors}
                                                          readOnly={
                                                            field.readOnly
                                                          }
                                                          arrayIndex={i}
                                                          label={field.label}
                                                          type={field.dataType}
                                                        />
                                                      </div>
                                                    ) : field.htmlElement ===
                                                      "checkbox" ? (
                                                      <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                      >
                                                        <FormikControl
                                                          control="checkBox"
                                                          name={`${arrObjName}.${field.name}`}
                                                          errors={field.errors}
                                                          label={field.label}
                                                          type={field.dataType}
                                                          options={field.options}
                                                        />
                                                      </div>
                                                    ) : field.htmlElement ===
                                                      "Object" ? (
                                                      <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                      >
                                                        <CreateQuoteObject
                                                          name={`${arrObjName}.${field.name}`}
                                                          Field={field}
                                                          parentName={Fields[activeStep].name}
                                                        />
                                                      </div>
                                                    ) : field.htmlElement ===
                                                      "Array" ? (
                                                      <div
                                                        className="mb-3"
                                                        key={index}
                                                        hidden={field.hidden}
                                                      >
                                                        <CreateQuoteArray
                                                          name={`${arrObjName}`}
                                                          Field={field}
                                                          familyArray={familyArray}
                                                          setfamilyArray={setfamilyArray}
                                                          FFamily={FFamily}
                                                        />
                                                      </div>
                                                    ) : null;
                                                  }
                                                )}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      );
                                    }}
                                  ></FieldArray>
                                ) : null}
                              </div>

                            </div>

                            <div className="button-sec">
                              <button
                                className=" btn"
                                type="button"
                                onClick={handleBack}
                              >
                                Back
                              </button>
                              <button className="btn" type="submit">
                                Next
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  ) : (
                    <Typography>Loading....</Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Validateproposal;
