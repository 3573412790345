import { Box, TextField, Typography } from '@mui/material'
import Axios from "axios";

import { ErrorMessage, Field, useFormikContext,  } from 'formik'
import React, { useEffect, useState } from 'react'

import TextError from '../TextError'


function FormikInput(props) {
    const { label, name, validation, customeValidation,previousIndex, readOnly, hidden, prevArrayIndex, manualValidation, arrayindex, schemeId, url, errors, ...rest } = props
    const [localManualFunction, setLocalManualFunction] = useState('');

    const { values, submitForm } = useFormikContext();

    // console.log('URL',url,name,arrayindex)

    //console.log('name',name)
    //console.log('hidd',hidden)
    // console.log('shs',schemeId)
    // console.log('manualValidation', manualValidation !== undefined ? window[`${manualValidation}`] : null)

    useEffect(() => {
       /*  if (manualValidation !== undefined) {
            setLocalManualFunction(manualValidation)
        } else {
            setLocalManualFunction('');
        } */
      //console.log('fofof',values.nomineeDetail[0].nomineeAge)
      

    }, [])

    //console.log('loc',localManualFunction)

    return (
        <div >

            <div>
                {/*  <Typography  >{label}</Typography> */}
                <Typography  >{hidden ? null : label}</Typography>
                {/*               <Field id={name} 
            name={name} {...rest} 
            placeholder={label} style={{width:'60%'}} 
            
            label={label}/>  */}

                <Field name={name}
                //validate={val=> localManualFunction !== ''  ? window[`${localManualFunction}`](val) :null}
                //validate={val => name === 'aadharNumber' ? validateAdharCard(label, val): null }
                >
                    {
                        ({ form, field }) => {
                            const { setFieldValue, setFieldError } = form
                            const { value } = form

                            const handleChange = (val) => {
                                let incomingVal = val.target.value;
                                console.log('ssasa',name,arrayindex,prevArrayIndex,previousIndex)

                                if (name === 'dependentDetail.proposerResidencePostalCode' && url !== undefined) {
                                    let proposerAreaName;
                                    console.log(`${url}${incomingVal}`)

                                    Axios.get(`${url}${incomingVal}`).then((res) => {
                                        console.log('url res', res)

                                        if (res.data.proposerAreaId !== undefined) {
                                            proposerAreaName = res.data.proposerAreaName
                                            //console.log('url res proposerAreaName', res.data.proposerAreaName)

                                            setFieldValue('dependentDetail.proposerResidenceAreaId', res.data.proposerAreaId)
                                        } else {
                                            setFieldValue('dependentDetail.proposerResidenceAreaId', '')
                                            //form.setFieldError('proposerResidenceAreaId','Please give proper postal')
                                        }
                                    }).catch(e => console.log(e.message))
                                    console.log('proposerAreaName', proposerAreaName)
                                }

                                if (name === `primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].pinCode`
                                    || name === `primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].pinCode2`) {

                                    console.log(`${url}${incomingVal}`, arrayindex)

                                    Axios.get(`${url}${incomingVal}`).then((res) => {
                                        console.log('url res', res)

                                        if (res.data.message !== undefined) {
                                            if (name === `primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].pinCode`) {
                                                setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].cityCd`, '')
                                                setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].areaCd`, '')
                                                setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].stateCd`, '')
                                            } else if (name === `primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].pinCode2`) {
                                                setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].cityCd2`, '')
                                                setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].areaCd2`, '')
                                                setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].stateCd2`, '')
                                            }
                                        } else {
                                            if (name === `primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].pinCode`) {
                                                if (res.data.citycd !== undefined && res.data.statecd !== undefined) {
                                                    setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].cityCd`, res.data.citycd)
                                                    setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].areaCd`, res.data.citycd)
                                                    setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].stateCd`, res.data.statecd)
                                                }

                                            } else if (name === `primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].pinCode2`) {
                                                if (res.data.citycd !== undefined && res.data.statecd !== undefined) {
                                                    setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].cityCd2`, res.data.citycd)
                                                    setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].areaCd2`, res.data.citycd)
                                                    setFieldValue(`primaryDetail[${previousIndex}].partyAddressDOList[${arrayindex}].stateCd2`, res.data.statecd)
                                                }

                                            }
                                        }
                                        //proposerAreaName=res.data.proposerAreaName





                                    }).catch(e => {


                                        console.log('eror url', e)

                                    }
                                    )

                                }

                                if (name === `dependentDetail.partyAddressDOList[${arrayindex}].pinCode`
                                    || name === `dependentDetail.partyAddressDOList[${arrayindex}].pinCode2`) {

                                    console.log(`${url}${incomingVal}`, arrayindex)

                                    Axios.get(`${url}${incomingVal}`).then((res) => {
                                        console.log('url res', res)

                                        if (res.data.message !== undefined) {
                                            if (name === `dependentDetail.partyAddressDOList[${arrayindex}].pinCode`) {
                                                setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].cityCd`, '')
                                                setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].areaCd`, '')
                                                setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].stateCd`, '')
                                            } else if (name === `dependentDetail.partyAddressDOList[${arrayindex}].pinCode2`) {
                                                setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].cityCd2`, '')
                                                setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].areaCd2`, '')
                                                setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].stateCd2`, '')
                                            }
                                        } else {
                                            if (name === `dependentDetail.partyAddressDOList[${arrayindex}].pinCode`) {
                                                if (res.data.citycd !== undefined && res.data.statecd !== undefined) {
                                                    setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].cityCd`, res.data.citycd)
                                                    setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].areaCd`, res.data.citycd)
                                                    setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].stateCd`, res.data.statecd)
                                                }

                                            } else if (name === `dependentDetail.partyAddressDOList[${arrayindex}].pinCode2`) {
                                                if (res.data.citycd !== undefined && res.data.statecd !== undefined) {
                                                    setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].cityCd2`, res.data.citycd)
                                                    setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].areaCd2`, res.data.citycd)
                                                    setFieldValue(`dependentDetail.partyAddressDOList[${arrayindex}].stateCd2`, res.data.statecd)
                                                }

                                            }
                                        }
                                        //proposerAreaName=res.data.proposerAreaName
                                    }).catch(e => {
                                        console.log('eror url', e)
                                    })
                                }

                                setFieldValue(name, val.target.value)

                                if (name === 'nomineeDetail[0].nomineeAge') {
                                    console.log('input console', val.target.value)
                                    if (val.target.value < 18 && val.target.value !== '') {
                                        document.getElementById('nomineeDetail[0].appointeeName').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeDetail[0].appointeeAge').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeDetail[0].appointeeRelationship').closest('div').closest('.mb-3').removeAttribute('hidden')
                                       
                                    } else {
                                        document.getElementById('nomineeDetail[0].appointeeName').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].appointeeName', '')
                                        document.getElementById('nomineeDetail[0].appointeeAge').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].appointeeAge', '')
                                        document.getElementById('nomineeDetail[0].appointeeRelationship').closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].appointeeRelationship', '')

                                    }

                                    console.log('gsgsg', document.getElementById('appointeeRelationship').closest('div').closest('.mb-3'))
                                }
                                if (name === 'nomineeDetail[0].nomineeAgeTwo') {
                                    console.log('input console', val.target.value)
                                    if (val.target.value < 18 && val.target.value !== '') {
                                        document.getElementById('nomineeDetail[0].appointeeNameTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeDetail[0].appointeeAgeTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeDetail[0].appointeeRelationshipTwo').closest('div').closest('.mb-3').removeAttribute('hidden')
                                    } else {
                                        document.getElementById('nomineeDetail[0].appointeeNameTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].appointeeNameTwo', '')
                                        document.getElementById('nomineeDetail[0].appointeeAgeTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].appointeeAgeTwo', '')
                                        document.getElementById('nomineeDetail[0].appointeeRelationshipTwo').closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].appointeeRelationshipTwo', '')
                                    }

                                    console.log('gsgsg', document.getElementById('appointeeRelationship').closest('div').closest('.mb-3'))
                                }

                                if (name === 'nomineeDetail[0].nomineePercentClaim') {
                                    if (val.target.value < 100 && val.target.value !== '') {
                                        document.getElementById('nomineeDetail[0].nomineeNameTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeDetail[0].nomineeAgeTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeDetail[0].nomineeRelationshipTwo').closest('div').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeDetail[0].nomineePercentClaimTwo').closest('.mb-3').removeAttribute('hidden')

                                    } else {
                                        document.getElementById('nomineeDetail[0].nomineeNameTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].nomineeNameTwo', '')
                                        document.getElementById('nomineeDetail[0].nomineeAgeTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].nomineeAgeTwo', '')
                                        document.getElementById('nomineeDetail[0].nomineeRelationshipTwo').closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].nomineeRelationshipTwo', '')
                                        document.getElementById('nomineeDetail[0].nomineePercentClaimTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeDetail[0].nomineePercentClaimTwo', '')

                                    }
                                }

                               /*  if (name === 'nomineeAgeTwo') {
                                    console.log('input console', val.target.value)
                                    if (val.target.value < 18) {
                                        document.getElementById('appointeeNameTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('appointeeAgeTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('appointeeRelationshipTwo').closest('div').closest('.mb-3').removeAttribute('hidden')
                                    } else {
                                        document.getElementById('appointeeNameTwo').closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById('appointeeAgeTwo').closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById('appointeeRelationshipTwo').closest('div').closest('.mb-3').setAttribute('hidden', true)

                                    }

                                    console.log('gsgsg', document.getElementById('appointeeRelationship').closest('div').closest('.mb-3'))
                                }
 */
                            }

                            const handleOnBlur = (val) => {
                                /* if (manualValidation !== undefined) {
                                    //console.log(manualValidation)
                                    //console.log(val.target.value)
                                    //console.log(manualValidation === 'validateNomineePercentClaim' ? manualValidation : null)
                                    let externalResponse = window[`${manualValidation}`](val.target.value)
                                    //console.log(externalResponse)
                                    setFieldError(name, externalResponse)
                                    console.log(name)

                                } else {
                                    setFieldValue(name, val.target.value)

                                }

                                if (name === 'nomineeAge') {
                                    console.log('input console', val.target.value)
                                    if (val.target.value < 18 && val.target.value !== '') {
                                        document.getElementById('appointeeName').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('appointeeAge').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('appointeeRelationship').closest('div').closest('.mb-3').removeAttribute('hidden')
                                    } else {
                                        document.getElementById('appointeeName').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('appointeeName', '')
                                        document.getElementById('appointeeAge').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('appointeeAge', '')
                                        document.getElementById('appointeeRelationship').closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('appointeeRelationship', '')

                                    }

                                    console.log('gsgsg', document.getElementById('appointeeRelationship').closest('div').closest('.mb-3'))
                                }
                                if (name === 'nomineeAgeTwo') {
                                    console.log('input console', val.target.value)
                                    if (val.target.value < 18 && val.target.value !== '') {
                                        document.getElementById('appointeeNameTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('appointeeAgeTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('appointeeRelationshipTwo').closest('div').closest('.mb-3').removeAttribute('hidden')
                                    } else {
                                        document.getElementById('appointeeNameTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('appointeeNameTwo', '')
                                        document.getElementById('appointeeAgeTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('appointeeAgeTwo', '')
                                        document.getElementById('appointeeRelationshipTwo').closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('appointeeRelationshipTwo', '')
                                    }

                                    console.log('gsgsg', document.getElementById('appointeeRelationship').closest('div').closest('.mb-3'))
                                }

                                if (name === 'nomineePercentClaim') {
                                    if (val.target.value < 100 && val.target.value !== '') {
                                        document.getElementById('nomineeNameTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeAgeTwo').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineeRelationshipTwo').closest('div').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById('nomineePercentClaimTwo').closest('.mb-3').removeAttribute('hidden')

                                    } else {
                                        document.getElementById('nomineeNameTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeNameTwo', '')
                                        document.getElementById('nomineeAgeTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeAgeTwo', '')
                                        document.getElementById('nomineeRelationshipTwo').closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineeRelationshipTwo', '')
                                        document.getElementById('nomineePercentClaimTwo').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue('nomineePercentClaimTwo', '')

                                    }
                                } */
                            }

                            return (
                                <>
                                    {

                                        readOnly !== undefined ?
                                            <Field
                                                className="form-control"
                                                label={label}
                                                {...rest}
                                                id={name}
                                                hidden={hidden}
                                                disabled={Boolean(readOnly)}
                                                name={name}
                                                //value={getValueByKey(transperData,name)}
                                                {...rest}
                                                placeholder={label}
                                                onChange={val => handleChange(val)}
                                            //onBlur={val => handleOnBlur(val)}

                                            /> : <Field
                                                className="form-control"
                                                label={label}
                                                {...rest}
                                                //validate={val => name === 'panNumber' ? validatePanNumber(label, val) : null}
                                                id={name}
                                                hidden={hidden}
                                                //readOnly={readOnly !== undefined ? readOnly : null}
                                                name={name}
                                                {...rest}
                                                placeholder={label}
                                                onChange={val => handleChange(val)}
                                                onBlur={val => handleOnBlur(val)}

                                            />
                                    }
                                </>
                            )

                        }
                    }

                </Field>

            <ErrorMessage name={name} component={TextError} />

            </div>



        </div >
    )
}

export default FormikInput