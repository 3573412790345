import { Box, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import React, { useEffect, useState } from 'react'
import TextError from '../TextError'
//import $ from 'jquery'
import moment from 'moment'



const FormikDatePicker = (props) => {
    const { label, name, validation,arrayIndex, options, readOnly, setSchemeId, errors, ...rest } = props
    const [erro, setErro] = useState('')
    const [errorMessage, setErrorMessage] = useState({})
    console.log(rest, "...")
    var currendate = new Date();
    var day = currendate.getDate();
    day = day.toString()
    var month = currendate.getMonth() + 1;
    month = month.toString()
    const fdate = `${currendate.getFullYear()}-${month.length === 1 ? `0${month}` : month}-${day.length === 1 ? `0${day}` : day}`

    //    $(document).on("focus", ".for-date", (e) => {
    //     $(".for-date").datepicker({
    //         dateFormat: 'mm-dd-yy', changeMonth: true,
    //         changeYear: true, maxDate: 0, yearRange: "1900:2022"
    //     });

    // });

    const calculateAge = (birthday) => {
        const startDate = new Date();
        const endDate = new Date(birthday);
        return Math.abs(moment.duration(endDate - startDate).years());
    }

    return (
        <Box>
            <div>
                <Typography  >{label}</Typography>
                <Field name={name}
                >
                    {
                        ({ form, field }) => {
                            const { setFieldValue, setFieldError, errors } = form
                            const { value, } = form
                            //console.log('form', form, errors)

                            const handleChange = (val) => {
                                // console.log('date', val.target.value, name)
                                //setFieldValue(name,val.target.value)
                                if (name === 'insured[0].dob') {

                                    let externalResponse = window['ValidateDOB'](val.target.value)

                                    console.log('aaa', externalResponse)
                                    //setErrorMessage(window['ValidateDOB'](val.target.value));
                                    if (externalResponse.message === '') {

                                        setFieldValue(name, val.target.value)
                                        setFieldValue('schemeId', externalResponse.schemeId)

                                    } else {
                                        //setFieldValue(name, '')
                                        setFieldError(name, externalResponse.message)

                                    }
                                    /* if (errorMessage.schemeId !== '') {
                                        setSchemeId({ errorMessage })
                                    } */
                                } else {
                                    setFieldValue(name, val.target.value)

                                }

                                if (name === `nomineeDetails[${arrayIndex}].DateOfBirth`) {
                                    console.log('value date', val.target.value)
                                    console.log('Age', calculateAge(val.target.value))

                                    let age = calculateAge(val.target.value)
                                    if (age < '18') {
                                        console.log('minor')
                                        setFieldValue(`nomineeDetails[${arrayIndex}].AppointeeFirstName`, '')
                                        setFieldValue(`nomineeDetails[${arrayIndex}].AppointeeMiddleName`, '')
                                        setFieldValue(`nomineeDetails[${arrayIndex}].AppointeeLastName`, '')
                                        setFieldValue(`nomineeDetails[${arrayIndex}].RelationshiptoNominee`, '')
                                        console.log('gtgg', document.getElementById(`nomineeDetails[${arrayIndex}].AppointeeLastName`).closest('div'))

                                        console.log('sggs',document.getElementById(`nomineeDetails[${arrayIndex}].RelationshiptoNominee`).closest('div').closest('.mb-3'))
                                        document.getElementById(`nomineeDetails[${arrayIndex}].AppointeeFirstName`).closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById(`nomineeDetails[${arrayIndex}].AppointeeMiddleName`).closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById(`nomineeDetails[${arrayIndex}].AppointeeLastName`).closest('div').closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById(`nomineeDetails[${arrayIndex}].RelationshiptoNominee`).closest('div').closest('.mb-3').removeAttribute('hidden')

                                    } else {
                                        document.getElementById(`nomineeDetails[${arrayIndex}].AppointeeFirstName`).closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById(`nomineeDetails[${arrayIndex}].AppointeeMiddleName`).closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById(`nomineeDetails[${arrayIndex}].AppointeeLastName`).closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById(`nomineeDetails[${arrayIndex}].RelationshiptoNominee`).closest('div').closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue(`nomineeDetails[${arrayIndex}].AppointeeFirstName`, 'null')
                                        setFieldValue(`nomineeDetails[${arrayIndex}].AppointeeMiddleName`, 'null')
                                        setFieldValue(`nomineeDetails[${arrayIndex}].AppointeeLastName`, 'null')
                                        setFieldValue(`nomineeDetails[${arrayIndex}].RelationshiptoNominee`, 'null')

                                    }
                                }




                                /*  if(name === 'insured[0].dob'){
                                     /ValidateDOB(val.target.value)
                                 } */


                            }

                            const blurEffect = () => {

                            }
                            return (
                                <>
                                    {
                                        readOnly !== undefined || readOnly !== false ?
                                            <Field data-provide="for-date"
                                                label={label}

                                                {...rest}
                                                id={name}
                                                name={name}
                                                {...rest}
                                                disabled={Boolean(readOnly)}
                                                max={fdate}
                                                placeholder={label}
                                                className="form-control for-date"
                                                //value={'04/02/2022'}
                                                //onChange={(val) => handleChange(val)}
                                                onBlur={(e) => {
                                                    handleChange(e)

                                                }}
                                            />
                                            :
                                            <Field data-provide="datepicker"
                                                label={label}

                                                {...rest}
                                                id={name}
                                                name={name}
                                                {...rest}
                                                value={new Date('2021/05/06')}
                                                placeholder={label}
                                                className="form-control for-date"
                                                // max={fdate}
                                                onChange={(val) => handleChange(val)}
                                                onBlur={(e) => {
                                                    handleChange(e)

                                                }}
                                            />
                                    }
                                </>

                            )

                        }
                    }

                </Field>
                <ErrorMessage name={name} component={TextError} />
                {/*  {
                    errors ? <p style={{ color: 'red', fontSize: '16px' }}>{errors}</p> : null
                } */}
                {/*  {
                        errorMessage.message !==''? <p style={{color:'red',fontSize:'16px'}}>{errorMessage.message}</p>:null
                    }  */}
                {/* <ErrorMessage name={name} component={TextError} /> */}
            </div>
        </Box >
    )
}

export default FormikDatePicker