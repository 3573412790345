const validateCriticalIlleness=(name,value)=>{
  //console.log('ext val',value)
  //console.log('ext val',name)

    let error;
    if(value === 'true'){
      error =  `You are not allowed to purchase this policy`;
    }            
    
      return error; 
  
}
export default validateCriticalIlleness