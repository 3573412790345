import React from 'react'
import FormikCheckBox from './Inputs/FormikCheckBox'
import FormikDatePicker from './Inputs/FormikDatePicker'
import FormikInput from './Inputs/FormikInput'
import FormikMultiSelect from './Inputs/FormikMultiSelect'
import FormikRadio from './Inputs/FormikRadio'
import FormikSelect from './Inputs/FormikSelect'
import formikTextArea from './Inputs/FormikTextArea'
import TextAreaFormik from './Inputs/TextAreaFormik'

function FormikControl(props) {
    const { control, ...rest} = props
    
    switch(control){
        case 'input': 
            return <FormikInput {...rest} />
        case 'select':
            return <FormikSelect {...rest} />
        case 'date':
            return <FormikDatePicker {...rest}/>
        case 'checkBox':
            return <FormikCheckBox {...rest}/>
            
        case 'textarea':
            return <TextAreaFormik {...rest}/>   
        case 'radio':
            return <FormikRadio {...rest}/>    
        case 'MultiSelect':
            return <FormikMultiSelect {...rest} />
    

        default: return  null


    }
}

export default FormikControl