import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import '../../Assets/css/LifeInsurance/otp_dialog.css'

const OTP_Dialog = (props) => {

    const { onClose, selectedValue, open, handleClose, OTPSuccessful, initial, setInitial, setOTPSuccessful, ApplicationNo, setResponseGetOTP, responseGetOTP } = props;
    const [OTP, setOTP] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const [errors, setErrors] = useState({
        mobileNumber: "",
        otpNum: "",
    });

    const inputHandler = (e) => {
        setErrors("");
        const { value, name } = e.target;

        setInitial({ ...initial, [name]: value });
    };

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        //Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };


  const verifyingHandler = () => {
        // const otpvalue = "123456";
        if (otp.join("") !== "") {
            const requestt = {
                UserID: "AXN80DMKYQ",
                ApplicationNo: responseGetOTP.ApplicationNo,
                MobileNumber: initial.mobileNumber,
                OTP: otp.join(""),
                SessionID: responseGetOTP.SessionID,
            };
            console.log(requestt, "requestttv");
            Axios.post(
                " http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/shriram-otp/verify/",
                requestt
            ).then(response => {
                console.log(response, "response from verify otp")
                if (response.data.ResponseCode === "200" && response.data.ResponseMsg === "OTP Validated Sucessfully...") {
                    setOTPSuccessful(true);
                }
                else {
                    setErrors({ ...errors, ["otpNum"]: "Invalid OTP" });
                }
            }).catch(e => {
                console.log(e, "error from getotp")
            });
            // setOTPSuccessful(true);
        } else {
            setErrors({ ...errors, ["otpNum"]: "Invalid OTP" });
        }
    };
 
    const verifyOTPhandler = () => {
        // console.log(initial.mobileNumber,"llll")
        if (initial.mobileNumber === "") {
            setErrors({ ...errors, ["mobileNumber"]: "Please Enter Mobile Number" });
        } else if (!initial.mobileNumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
            setErrors({
                ...errors,
                ["mobileNumber"]: "Please enter valid mobile number",
            });
        } else {
            const request = {
                UserID: "AXN80DMKYQ",
                ApplicationNo,
                MobileNumber: initial.mobileNumber,
            };
            console.log(request, "requestttt")
            // setOTP(true);

            Axios.post(
                "http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/shriram-otp/generate/",
                request
            ).then(response => {
                setResponseGetOTP(response.data)
                console.log(response, "response from get otp")
                setOTP(true);
            }).catch(e => {
                console.log(e, "error from getotp")
            });
        }
    };


    return (
        <Dialog open={open} onClose={onClose}>
            {OTPSuccessful ? (
                <DialogContent>
                    <DialogContentText>
                        <p className="otpsuccess">OTP verified Successfully....Thank u</p>
                    </DialogContentText>
                </DialogContent>
            ) : (
                <DialogContent>
                    <DialogContentText>
                        {OTP === true
                            ? "Enter your OTP here"
                            : "Please enter your mobile number, OTP will send to the following number"}
                    </DialogContentText>
                    <form>
                        {OTP === true ? (
                            <div>
                                <div className="otp-container">
                                    {otp.map((data, index) => {
                                        return (
                                            <input
                                                className="otp-field"
                                                type="text"
                                                name="otp"
                                                maxLength="1"
                                                key={index}
                                                value={data}
                                                onChange={(e) => handleChange(e.target, index)}
                                                onFocus={(e) => e.target.select()}
                                            />
                                        );
                                    })}
                                </div>
                                <div></div>
                                <p id="otpfail">{errors.otpNum ? errors.otpNum : ""}</p>
                            </div>
                        ) : (
                            <div>
                                {errors.mobileNumber ? (
                                    <TextField
                                        error
                                        className="textField"
                                        name="mobileNumber"
                                        label="Error"
                                        value={initial.mobileNumber}
                                        margin="dense"
                                        fullWidth
                                        helperText={errors.mobileNumber}
                                        defaultValue={initial.mobileNumber}
                                        onChange={inputHandler}
                                    />
                                ) : (
                                    <TextField
                                        className="textField"
                                        name="mobileNumber"
                                        value={initial.mobileNumber}
                                        margin="dense"
                                        label="Mobile Number"
                                        fullWidth
                                        onChange={inputHandler}
                                    />
                                )}
                            </div>
                        )}
                    </form>
                </DialogContent>
            )}
            {OTPSuccessful ? (
                ""
            ) : (
                <DialogActions>
                    {OTP === true && (
                        <Button
                            // className="btn-secondary mr-2"
                            onClick={(e) => setOtp([...otp.map((v) => "")])}
                        >
                            Clear
                        </Button>
                    )}
                    <Button onClick={onClose}>Cancel</Button>
                    {OTP === true ? (
                        <Button 
                        onClick={verifyingHandler}
                        >verify OTP</Button>
                    ) : (
                        <Button onClick={verifyOTPhandler}>Get OTP</Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default OTP_Dialog