import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React from 'react'

const Highlights = ({ highlights }) => {


    return (
        <>
            {
                highlights.length > 0 ?
                    <>
                        {
                            highlights.map((item, index) => {
                                console.log('high', item)
                                return (
                                    <Accordion style={{ backgroundColor: "#0fb19e", color: "white", border: " solid dashed" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div className="acc-circle">
                                                {index+1}
                                            </div>
                                            <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
                                                {item.highlight_name}
                                            </Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Typography>
                                               {item.highlight_desc}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                       {/*  <Accordion style={{ backgroundColor: "#0fb19e", color: "white", border: " solid dashed" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="acc-circle">
                                    1
                                </div>
                                <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
                                    Simple online process{" "}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                    eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="acc-circle">
                                    2
                                </div>
                                <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
                                    Get treated at any hospital
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                    eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="acc-circle">
                                    3
                                </div>
                                <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
                                    Get Bonus
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                    eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="acc-circle">
                                    4
                                </div>
                                <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
                                    Minimal copayment
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                eget.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="acc-circle">
                                    5
                                </div>
                                <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
                                    Super easy claims
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                eget.

                            </AccordionDetails>
                        </Accordion> */}
                    </>
                    : null
            }

        </>
    )
}

export default Highlights