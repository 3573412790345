import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Slide, Slider } from '@mui/material';
import React, { useState } from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const SortHealthInsureProduct = ({ open, close,sortByPremium }) => {

    const gfg = [
        {
            value: 2,
            label: "2",
        },
        {
            value: 3,
            label: "3",
        },
        {
            value: 4,
            label: "4",
        },
        {
            value: 5,
            label: "5",
        },
        {
            value: 10,
            label: "10",
        },
        {
            value: 15,
            // label: "10",
        },
        {
            value: 20,
            label: "20",
        },
    ];

    const [val, setVal] = useState();
    const updateRange = (e, data) => {
        setVal(data);
    };
    const [state, setState] = useState({
        open: false,
    });
    // state = {
    //   open: false,
    // };

    const handleClickOpen = () => {
        setState({ open: true });
    };
    const handleListItemClick = (value) => {
        //price(value)
        sortByPremium(value)
        close();
    };

    const handleClose = () => {
        setState({ open: false });
    };


    return (

        <>
            {/* <Dialog
            //open={state.open}
            open={open}
            onClose={close}
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={Transition}
            keepMounted
        >
            
            <DialogContent>
               
                
                <div style={{ margin: 20 }}>
                    <Button variant="outlined" onClick={() => price('LOW')}>Premium Low to High</Button>
                </div>
                <div style={{ margin: 20 }}>
                    <Button variant="outlined" onClick={() => price('HIGH')}>Premium High to Low</Button>
                </div>
                <div>
                   
                    <div style={{ width: 200, margin: 20 }}>
                        <span> Length of service </span>{" "}
                        <Slider value={val} onChange={updateRange} min={2} max={20} step={null} marks={gfg} valueLabelDisplay="on" aria-labelledby="non-linear-slider"
                        />
                    </div>{" "}
                   
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
               
            </DialogActions>
        </Dialog> */}

            <Dialog onClose={close} open={open}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>Sort</DialogTitle>
                <List sx={{ pt: 0 }}>
                    <ListItem button onClick={() => handleListItemClick('LOW')} >
                        <Button variant="outlined" >Premium Low to High</Button>
                    </ListItem>
                    <ListItem button onClick={() => handleListItemClick('HIGH')} >
                        <Button variant="outlined" >Premium High to Low</Button>
                    </ListItem>
                    <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
                        Length of service
                    </ListItem>
                    <ListItem button  >

                        <Slider value={val} onChange={updateRange} min={2} max={20} step={null} marks={gfg} valueLabelDisplay="on" aria-labelledby="non-linear-slider"
                        />
                    </ListItem>

                </List>
            </Dialog>

        </>

    )
}

export default SortHealthInsureProduct
