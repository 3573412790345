import { Box, Typography } from '@mui/material'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import TextError from '../TextError'

const FormikSelect = (props) => {
    const { label, name, validation, readOnly, options, quickQuoteDetails, arrayindex, previousIndex, errors, ...rest } = props


    const [disabled, setDisabled] = useState(false)

    const { values } = useFormikContext()

    //const 

    function updateObject(target, src) {
        const res = {};
        Object.keys(target)
            .forEach(k => res[k] = (k in src ? src[k] : target[k]));
        return res;
    }


    useEffect(() => {

        //console.log('depend_pre',quickQuoteDetails,name)




        if (values.primaryDetail[`${arrayindex}`] !== undefined) {
            console.log('jjj', values.primaryDetail[`${arrayindex}`])
            if (values.primaryDetail[`${arrayindex}`].relationCd === "SELF") {
                // let finalObj = [values.dependentDetail,values.primaryDetail[`${previousIndex}`]]
                // console.log('fffgg',finalObj)
                let finalObj = updateObject(values.dependentDetail, values.primaryDetail[`${arrayindex}`])



                finalObj = {
                    ...finalObj,
                    ["roleCd"]: "PROPOSER",
                }
                console.log('dep_pre', quickQuoteDetails)
                // if(quickQuoteDetails !== undefined){
                //     if(parseInt(quickQuoteDetails.premium) >= 50){
                //         console.log('jjj',finalObj)
                //         finalObj.partyIdentityDOList[0]={
                //             ...finalObj.partyIdentityDOList[0],
                //             ['identityTypeCd']:"PAN"
                //         }
                //         finalObj={
                //             ...finalObj,
                //             ["roleCd"]: "PROPOSER",
                //         }                    
                //     }
                // }
                // if(quickQuoteDetails !== undefined){
                //     if(parseInt(quickQuoteDetails.premium) >= 50){
                //         document.getElementById(`dependentDetail.partyIdentityDOList[0].panNumber`)
                // .closest('.mb-3').removeAttribute('hidden')
                //     }
                // }
                //     if(parseInt(quickQuoteDetails.premium) >= 50){
                //         console.log('jjj',finalObj)
                //         finalObj.partyIdentityDOList[0]={
                //             ...finalObj.partyIdentityDOList[0],
                //             ['identityTypeCd']:"PAN"
                //         }
                //         finalObj={
                //             ...finalObj,
                //             ["roleCd"]: "PROPOSER",
                //         }                    
                //     }
                // else{
                //     finalObj={
                //         ...finalObj,
                //         ["roleCd"]: "PROPOSER",
                //     }
                // }


                console.log('fffgg', finalObj)
                values.dependentDetail = {
                    ...finalObj
                }
            }
        }









        //  if(values?.primaryDetail[`${arrayindex}`]?.genderCd === "MALE"){
        //     values.primaryDetail[`${arrayindex}`] ={
        //         ...values.primaryDetail[`${arrayindex}`],
        //         ['titleCd']:"MR"
        //     }
        //  }else  if(values?.primaryDetail[`${arrayindex}`]?.genderCd === "FEMALE"){
        //     values.primaryDetail[`${arrayindex}`] ={
        //         ...values.primaryDetail[`${arrayindex}`],
        //         ['titleCd']:"MS"
        //     }
        //  }

        //  if(values?.dependentDetail?.genderCd === "MALE"){
        //     values.dependentDetail ={
        //         ...values.dependentDetail,
        //         ['titleCd']:"MR"
        //     }
        //  }else  if(values?.dependentDetail?.genderCd === "FEMALE"){
        //     values.dependentDetail ={
        //         ...values.dependentDetail,
        //         ['titleCd']:"MS"
        //     }
        //  }


        if (name === `primaryDetail[${previousIndex}].partyIdentityDOList[0].identityTypeCd`) {
            if (values.primaryDetail[`${previousIndex}`].partyIdentityDOList[0].identityTypeCd === "") {

            } else if (values.primaryDetail[`${previousIndex}`].partyIdentityDOList[0].identityTypeCd === "PAN") {
                if (document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`)
                    .closest('.mb-3') !== 'null') {
                    document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`)
                        .closest('.mb-3').removeAttribute('hidden')
                }
                if (document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].passportNumber`)
                    .closest('.mb-3') !== 'null') {
                    document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].passportNumber`)
                        .closest('.mb-3').setAttribute('hidden', true)
                }


            } else if (values.primaryDetail[`${previousIndex}`].partyIdentityDOList[0].identityTypeCd === "PASSPORT") {
                document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`)
                    .closest('.mb-3').setAttribute('hidden', true)
                document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].passportNumber`)
                    .closest('.mb-3').removeAttribute('hidden')
            }

            console.log('qqq', quickQuoteDetails)
            if (parseInt(quickQuoteDetails.premium) >= 50) {
                setDisabled(true)
            }

        }



        if (name === `dependentDetail.partyIdentityDOList[0].identityTypeCd`) {
            if (values.dependentDetail.partyIdentityDOList[0].identityTypeCd === "") {

            } else if (values.dependentDetail.partyIdentityDOList[0].identityTypeCd === "PAN") {
                if (document.getElementById(`dependentDetail.partyIdentityDOList[0].panNumber`)
                    .closest('.mb-3') !== 'null') {
                    document.getElementById(`dependentDetail.partyIdentityDOList[0].panNumber`)
                        .closest('.mb-3').removeAttribute('hidden')
                }
                if (document.getElementById(`dependentDetail.partyIdentityDOList[0].passportNumber`)
                    .closest('.mb-3') !== 'null') {
                    document.getElementById(`dependentDetail.partyIdentityDOList[0].passportNumber`)
                        .closest('.mb-3').setAttribute('hidden', true)
                }


            } else if (values.dependentDetail.partyIdentityDOList[0].identityTypeCd === "PASSPORT") {
                if (document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].panNumber`)
                    .closest('.mb-3') !== 'null') {
                    document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].panNumber`)
                        .closest('.mb-3').setAttribute('hidden', true)
                }
                if (document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].passportNumber`)
                    .closest('.mb-3') !== 'null') {
                    document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].passportNumber`)
                        .closest('.mb-3').removeAttribute('hidden')
                }

            }

            if (parseInt(quickQuoteDetails.premium) >= 50) {
                setDisabled(true)
            }
        }





    }, [values])


    return (
        <Box >
            <div>
                <Typography  >{label}</Typography>

                <Field name={name}
                >
                    {
                        ({ form, field }) => {
                            const { setFieldValue, values } = form


                            //const { value } = form
                            //  console.log(name,'name')
                            const handleChange = (val) => {
                                console.log('inside select', val.target.value)
                                setFieldValue(name, val.target.value)
                                if (name === `primaryDetail[${previousIndex}].partyIdentityDOList[0].identityTypeCd`) {


                                    if (val.target.value === 'PAN') {

                                        console.log('gdgdggd', document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`).closest('.mb-3'))
                                        document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`)
                                            .closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].passportNumber`)
                                            .closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue(`primaryDetail[${previousIndex}].partyIdentityDOList[0].panNumber`, '')
                                    } else if (val.target.value === 'PASSPORT') {
                                        console.log('jjjjjjjjj',)
                                        document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`)
                                            .closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].passportNumber`)
                                            .closest('.mb-3').removeAttribute('hidden')
                                        setFieldValue(`primaryDetail[${previousIndex}].partyIdentityDOList[0].passportNumber`, '')

                                    } else {
                                        document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`)
                                            .closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].passportNumber`)
                                            .closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`, '')
                                        setFieldValue(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].passportNumber`, '')

                                    }
                                }

                                if (name === `dependentDetail.partyIdentityDOList[${arrayindex}].identityTypeCd`) {
                                    if (val.target.value === 'PAN') {
                                        //console.log('gdgdggd',document.getElementById(`primaryDetail[${previousIndex}].partyIdentityDOList[${arrayindex}].panNumber`).closest('.mb-3'))
                                        document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].panNumber`)
                                            .closest('.mb-3').removeAttribute('hidden')
                                        document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].passportNumber`)
                                            .closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue(`dependentDetail.partyIdentityDOList[${arrayindex}].passportNumber`, '')
                                    } else if (val.target.value === 'PASSPORT') {
                                        document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].panNumber`)
                                            .closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].passportNumber`)
                                            .closest('.mb-3').removeAttribute('hidden')
                                        setFieldValue(`dependentDetail.partyIdentityDOList[${arrayindex}].panNumber`, '')

                                    } else {
                                        document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].panNumber`)
                                            .closest('.mb-3').setAttribute('hidden', true)
                                        document.getElementById(`dependentDetail.partyIdentityDOList[${arrayindex}].passportNumber`)
                                            .closest('.mb-3').setAttribute('hidden', true)
                                        setFieldValue(`dependentDetail.partyIdentityDOList[${arrayindex}].panNumber`, '')
                                        setFieldValue(`dependentDetail.partyIdentityDOList[${arrayindex}].passportNumber`, '')

                                    }
                                }

                                if (name === `primaryDetail[${previousIndex}].partyContactDOList[${arrayindex}].contactTypeCd`) {
                                    if (val.target.value || val.target.value === '') {
                                        setFieldValue(`primaryDetail[${previousIndex}].partyContactDOList[${arrayindex}].contactNum`, '')
                                    }
                                }



                                /* if(name === `primaryDetail[${arrayindex}].relationCd`){
                                    if(val.target.value === "SELF"){
                                        //let finalObj = [values.dependentDetail,values.primaryDetail[`${arrayindex}`]]
                                        let finalObj =updateObject(values.dependentDetail,values.primaryDetail[`${arrayindex}`])
                                        console.log('fffgg',finalObj)
                                        values={
                                            ...values,
                                            ['dependentDetail'] : finalObj
                                        }
                                        
                                    } 
                                } */

                                /*   if (name === `insureds[${arrayindex}].relationshipId`) {
                                      let flag = 0;
                                      values.insureds.map((field, ind) => {
                                          if (field.relationshipId === '3') {
                                              document.getElementById(`insureds[${ind}].engageManualLabour`).closest('.mb-3').setAttribute('hidden', true)
                                              document.getElementById(`insureds[${ind}].engageWinterSports`).closest('.mb-3').setAttribute('hidden', true)
                                              document.getElementById(`insureds[${ind}].isPersonalAccidentApplicable`).closest('.mb-3').setAttribute('hidden', true)
                                          }
                                          else {
                                              if (field.relationshipId) {
                                                  document.getElementById(`insureds[${ind}].engageManualLabour`).closest('.mb-3').removeAttribute('hidden')
                                                  document.getElementById(`insureds[${ind}].engageWinterSports`).closest('.mb-3').removeAttribute('hidden')
                                                  document.getElementById(`insureds[${ind}].isPersonalAccidentApplicable`).closest('.mb-3').removeAttribute('hidden')
  
                                              }
                                              if (field.relationshipId && flag !== 0) {
                                                  document.getElementById(`insureds[${ind}].isPersonalAccidentApplicable`).closest('.mb-3').setAttribute('hidden', true)
  
                                              }
  
                                              flag++;
                                          }
  
                                      })
                                  } */





                                console.log('val', val.target.value.trim())
                            }





                            return (
                                <>
                                    {
                                        readOnly !== undefined ?
                                            <Field
                                                as='select'
                                                id={name}
                                                name={name}
                                                disabled={Boolean(readOnly)}
                                                {...rest}
                                                placeholder="select"
                                                className="form-select form-select-lg mb-3"
                                                onChange={val => handleChange(val)}
                                            >
                                                {
                                                    options.map(option => {
                                                        return (
                                                            <option key={option.value} value={option.value}>{option.label}</option>
                                                        )
                                                    })
                                                }

                                            </Field>
                                            : <Field
                                                as='select'
                                                id={name}
                                                name={name}
                                                disabled={disabled}
                                                {...rest}
                                                placeholder="select"
                                                className="form-select form-select-lg mb-3"
                                                onChange={val => handleChange(val)}
                                            >
                                                {
                                                    options.map(option => {
                                                        return (
                                                            <option key={option.value} value={option.value}>{option.label}</option>
                                                        )
                                                    })
                                                }

                                            </Field>
                                    }
                                </>

                            )

                        }
                    }

                </Field>



                <ErrorMessage name={name} component={TextError} />
            </div>
        </Box >
    )
}

export default FormikSelect