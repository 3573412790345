import React from 'react'
import logo from './logo.svg';
import { Route, Routes, Link } from 'react-router-dom'
import './App.css';
import QuickQuote from './HealthInsurance/QuickQuote/QuickQuote';
import ListOfProducts from './HealthInsurance/ListOfProducts/ListOfProducts'
import LifeInsuranceQQ from './LifeInsurance/QuickQuote/LifeInsuranceQQ';
import PolicyHighlightPage from './HealthInsurance/PolicyHighlightPage/PolicyHighlightPage';
import NotFound from './NotFound/NotFound';
import Policy from './HealthInsurance/CreateQuote/Policy';
import PaymentOptions from './HealthInsurance/Payment/PaymentOptions';
import ProductListCard from './LifeInsurance/ListOfProducts/ProductListCard';
import ProductDetailsPage from './LifeInsurance/PolicyHighlightPage/ProductDetailsPage';
import Validateproposal from './LifeInsurance/CreateQuote/ValidateProposer/Validateproposal';
import GetBiForm from './LifeInsurance/CreateQuote/GetBiForm/GetBiForm';
import CreateQuoteForm from './LifeInsurance/CreateQuote/SubmitProper/CreateQuoteForm';
import PaypremiumForm from './LifeInsurance/CreateQuote/PaypremiumForm/PaypremiumForm';
import HealthInsPolicyStatus from './HealthInsurance/PolicyStatus/HealthInsPolicyStatus';
import PaymentPage from './HealthInsurance/Payment/PaymentPage';


function App() {
  return (
    <Routes>
     {/*  <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<App />} />
      <Route path="/createquote" element={<Product />} /> */}
      {/* <Route path="/products" element={<ProductList />} /> */}
      {/* <Route path="/health-insurance/product/:productId" element={<Policy />} /> */}
      {/* <Route path="/productstatus/:productId" element={<PolicyStatus />} /> */}

     {/*  <Route path="/care" element={<QuickQuote />} /> */}
      <Route path="/" element={<QuickQuote />} />
      <Route path="/health-insurance" element={<QuickQuote />} />
      <Route path="/health-insurance/products-list/:responseId" element={<ListOfProducts />} />
      <Route path="/health-insurance/product-detail/:productId" element={<PolicyHighlightPage/>} />
      <Route path="/health-insurance/product/:productId" element={<Policy />} />
      <Route path="/health-insurance/product-stastus/:referencetId" element={<PaymentOptions />} />
      <Route path="/health-insurance/policy-status/:proposerNum" element={<HealthInsPolicyStatus />} /> {/* redirect url */}
      <Route path="/health-insurance/payment/:referencetId" element={<PaymentPage />} />
      

      <Route path="/life-insurance" element={<LifeInsuranceQQ />} />
      <Route path="/life-insurance/products-list/:id" element={<ProductListCard/>}/>
      <Route path="/life-insurance/product-detail/:id" element={<ProductDetailsPage/>} />
      <Route path="/life-insurance/validateproposal/:id" element={<Validateproposal />} />
      <Route path="/life-insurance/getbi/:id" element={<GetBiForm />} />
      <Route path="/life-insurance/paypremium/:id" element={<PaypremiumForm />} />
      <Route path="/life-insurance/submitproposal" element={<CreateQuoteForm />} />




      <Route path="*" element={<NotFound/>} />


     {/*  <Route path="/health-insurance/products-list/:responseId" element={<ListOfQuickQuotes />} />
      <Route path="/health-insurance/product-stastus/:referencetId" element={<PaymentOptions />} />
      <Route path="/health-insurance/product-detail/:productId" element={<PolicyHighlightPage />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/sort" element={<SortHealthInsureProduct />} />
 */}

    </Routes>
  );
}

export default App;
