import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

function TextError(props) {
  return (
    <Typography color='error'>
        {props.children}
    </Typography>
  )
}

export default TextError