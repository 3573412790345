import React, { useEffect, useState } from "react";
import "../../Assets/css/paymentOptions.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

const PaymentOptions = () => {

    const { referencetId } = useParams();
    const [responseData, setResponseData] = useState({})
    const navigate = useNavigate()

    //62e28fc7150e9c6a0a39676e
    useEffect(() => {
        console.log(referencetId)
        /* http://api.acuvisor.in/api/health/createquote-response/ */
        if (window.location.hostname === 'localhost') {
            Axios.get(`http://localhost:8000/api/health/createquote-response/${referencetId}/`).then(
                response => {
                    console.log('respon', response)
                    setResponseData(response.data.response)
                }
            )
        } else {
            Axios.get(`http://65.0.70.70:8000/api/health/createquote-response/${referencetId}/`).then(
                response => {
                    console.log('respon', response)
                    setResponseData(response.data.response)
                }
            )
        }

    }, [])


    const backButtonHandler = () => {
        navigate(-1);
    }

    const sendLink = () => {
        Axios.get(`https://dev.acuvisor.com/V2/AcuHealthInsurance/care_policy_payment_link?enquiryId=${responseData.A_id}`)
            .then(response => {
                console.log('fffss', response)
                console.log('data', response.data)

            }
            )
    }

    const payHere = () => {
        console.log('link', responseData.proposalNum)

        if (responseData.vendor === "Star Health") {
            window.open(`${responseData.redirectTokenUrl}`)
        } else {
            //window.open(`https://apiuat.religarehealthinsurance.com/portalui/PortalPayment.run?proposalNum=${responseData.proposalNum}&returnURL=http://65.0.70.70/health-insurance/policy-status/${responseData.proposalNum}`)
            if (window.location.hostname === 'localhost') {
                console.log('host',window.location.hostname)
                window.open(`http://localhost:8000/api/care-payment/?proposalNum=${responseData.proposalNum}`)
            } else {
                console.log('host',window.location.hostname)
                window.open(`http://65.0.70.70:8000/api/care-payment/?proposalNum=${responseData.proposalNum}`)

            } 
        }

        /*  Axios.get(`http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/care-payment/?proposalNum=${responseData.proposalNum}`)
         .then((res)=>{
             console.log('payment pay here',res)
             console.log('hhh',res.data)
             window.open(`${res.data}`)
         }) */
        //window.open(`http://www.care.acuvisor.in/pay_care.php?proposalNumber=${responseData.proposalNum}`)
        //window.open(`http://65.0.70.70/api/care-payment/?proposalNum=${responseData.proposalNum}`)
    }


    return (
        <>
            {console.log('nnn', typeof (responseData.number_of_insured))}
            <div>
                <div className="header-section">
                    <p className="insurence-title-sec">
                        Health Insurance
                        <KeyboardArrowDownIcon />
                    </p>
                </div>
                <Container
                    style={{ marginTop: 10, marginBottom: 10 }}
                    maxWidth="sm md lg"
                >
                    <Card className="card-section" sx={{ minWidth: 275, marginTop: 5 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Typography>Proposer Name:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography><strong>{responseData.proposer_name}</strong></Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography>Policy Type:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography><strong>{responseData.number_of_insured > 1 ? 'Floater' : 'Individual'}</strong></Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography>Number of Insured(s):</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography>{responseData.number_of_insured} </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography></Typography>
                                </Grid>
                                <hr />
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6} color="text.primary" style={{}}>
                                    <Typography>
                                        <b>Premium Details</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={8}>
                                    <Typography>Policy Premium:</Typography>
                                </Grid>
                                <Grid item xs={4}>

                                    <Typography><b className="rupee_symp">&#8377;</b> {responseData.premium}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>Tax On Premium:</Typography>
                                </Grid>
                                <Grid item xs={4}>

                                    <Typography><b className="rupee_symp">&#8377;</b> {responseData.serviceTax}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>Gross premium:</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography><b className="rupee_symp">&#8377;</b>  {responseData.totalPremium}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <div className="button-section">
                        <div>
                            <button className="payment-btn" type="button"
                                onClick={sendLink}
                            >
                                Send payment link to customer
                            </button>
                        </div>
                        <div>
                            <button className="pay-btn " type="button" onClick={payHere} >
                                Pay here
                            </button>
                        </div>
                        <div>
                            <button className="back-btn" type="button" onClick={backButtonHandler}>
                                Back
                            </button>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default PaymentOptions;
