import { ErrorMessage, Field } from 'formik'
import MultiSelect from 'multiselect-react-dropdown'
import React from 'react'
import TextError from '../TextError'

import { Typography } from '@mui/material';

const FormikMultiSelect = (props) => {
    const { label, name, validation, readOnly, options, errors, ...rest } = props

    //console.log('multi name',name)
    return (
        <div>
            <Typography  >{label}</Typography>

            <Field name={name}
            >
                {
                    ({ form, field }) => {
                        const { setFieldValue } = form
                        const { value } = form
                        
                        const onSelect = (val) => {
                            console.log('multi val', val)
                            let recVal=Array.isArray(val) ? val.map(e => e.value) : []
                            console.log('receive val',recVal.join());
                           
                            setFieldValue(name,recVal.join())

                        }
                      
                     
                        return (
                            <>                          
                                <MultiSelect
                                        name={name}
                                        onRemove={(val) => onSelect(val)}
                                        onSelect={(val) => onSelect(val)}
                                        options={options}
                                        displayValue="label"
                                        showCheckbox
                                        placeholder={label}
                                       
                                    />  
      
                            </>
                        )

                    }
                }

            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default FormikMultiSelect