var initialValues={};

const createInitialValue=(product)=>{
    //console.log('para',product.requestBody.formFields)
    if(product.requestBody.formFields !==undefined){
        product.requestBody.formFields.fields.map((field,index)=>{
//            console.log('fie',field)
            if(field.dataType ==='object'){
               // console.log('object',setObject(field.obj))
                initialValues[field.name]= setObject(field.obj)
            }else if(field.dataType ==='String' || field.dataType ==='number'|| field.dataType ==='Date'){
                initialValues[field.name]= ''
            }
            else if(field.dataType ==='Array'){
                initialValues[field.name]= [setArray(field.obj)]
            }
        })
    }else{
        product.requestBody.fields.map((field,index)=>{
           // console.log('fie',field)
            if(field.dataType ==='object'){
                //console.log('object',setObject(field.obj))
                initialValues[field.name]= setObject(field.obj)
            }else if(field.dataType ==='String' || field.dataType ==='number' || field.dataType ==='Date'){
                initialValues[field.name]= ''
            }
            else if(field.dataType ==='Array'){
                initialValues[field.name]= [setArray(field.obj)]
            }
        })
    }

   

    //console.log('ini',initialValues)
    return initialValues
}

const setObject=(data)=>{
    let objectInitialValue={};
    //console.log('setObject',data)
    data.map((item)=>{
      //  console.log('item',item)
        if(item.dataType ==='object'){
            objectInitialValue[item.name]=setObject(item.obj)
        }else if(item.dataType ==='Array'){
        //    console.log('arr',item)
            objectInitialValue[item.name]=[setArray(item.obj)]
        }else if(item.dataType ==='String' ||item.dataType ==='number' ||item.dataType ==='Date'){
          //  console.log('arr',item)
            objectInitialValue[item.name]=''
        }
    })
    //console.log('NEW',objectInitialValue)
    return objectInitialValue
}
const setArray=(data)=>{
    let arrayInitialValue={};
   // console.log('setObject',data)
    data.map((item)=>{
     //   console.log('item',item)
        if(item.dataType ==='object'){
            arrayInitialValue[item.name]=setObject(item.obj)
        }else if(item.dataType ==='Array'){
       //     console.log('arr',item)
            arrayInitialValue[item.name]=[setArray(item.obj)]
        }else if(item.dataType ==='String' ||item.dataType ==='number' ||item.dataType ==='Date'){
         //   console.log('arr',item)
            arrayInitialValue[item.name]=''
        }
    })
    //console.log('NEW',arrayInitialValue)
    return arrayInitialValue
}
export default createInitialValue