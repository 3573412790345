const careReqbodyGen=(data)=>{

    var date = '';
    var careSeniorReq = {};
    var carefreeReq = {};
    var careReqbody = {};
    var count = 0;
    const agearray = [];
    const stringfield_care = [
      "UA",
      "OPD",
      "EDC",
      "35",
      "43",
      "CPW",
      "CS",
      "DA",
    ];
    const stringfield_caresenior = [
      "NCB",
      "OPD",
      "43",
      "SS",
      "CS",
      "CPW",
    ];
    var max = 0;
  
    //console.log("Form data", data);
    const listarr = [];
    listarr[3] = [];
    careReqbody["postedField"] = {};
    careReqbody["partnerId"] = "156";
    careReqbody["abacusId"] = "1144";
    careReqbody.postedField["outPutField"] = "ncbPremium";
  
    careSeniorReq["postedField"] = {};
    careSeniorReq["partnerId"] = "156";
    careSeniorReq["abacusId"] = "2001";
    careSeniorReq.postedField["outPutField"] =
      "field_8";
  
    carefreeReq["postedField"] = {};
    carefreeReq["partnerId"] = "156";
    carefreeReq["abacusId"] = "1145";
    carefreeReq.postedField["outPutField"] =
      "field_8 - Premium For Care Freedom";
  
    Object.keys(data).map((key) => {
  
      switch (key) {
        case "sumInsuredValue":
          const sumInsured=parseInt(data.sumInsuredValue)
          if (sumInsured>=300000 && sumInsured <=500000) {
            listarr[2] = data.sumInsuredValue[0];
          } else {
            listarr[2] = "";
          }
  
          break;
        case "insureds":
  
          data.insureds.map((obj) => {
            console.log('obj', obj.relationShip)
            if (obj.relationShip === 'Dependent Child') {
              count++;
            } else if (obj.Age) {
              // const getAge = Math.floor((new Date() - new Date(obj.dob).getTime()) / 3.15576e+10)
              // console.log("age", getAge)
              agearray.push(obj.Age);
              max = Math.max(...agearray);
              // console.log(getAge, agearray, max);
            }
  
          });
  
          listarr[10] = `${count}`;
          listarr[1] = data.insureds.length;
          console.log("max", max)
          if (data.insureds.length > 1) listarr[9] = "Floater";
          else listarr[9] = "Individual";
          if (max <= 5) listarr[3][2] = "90 Days - 5 Yrs";
          else if (max >= 5 && max <= 35) {
            if (max <= 17) {
              listarr[3][1] = "5 - 24 years";
              listarr[3][2] = "6 - 17 Yrs";
            } else if (max >= 17 && max <= 24) {
              listarr[3][1] = "5 - 24 years";
              listarr[3][2] = "18 - 35 Yrs";
            } else {
              listarr[3][1] = "25 - 35 years";
              listarr[3][2] = "18 - 35 Yrs";
            }
          } else if (max <= 80 && max >= 36) {
            let number;
            if (max % 5 === 0) number = max
            else for (let i = max; i % 5 !== 0; i--)  number = i;
  
            if (max <= 60) {
              listarr[3][1] = `${number} - ${number + 4} years`;
              listarr[3][2] = `${number} - ${number + 4} Yrs`;
            }
            else if (max > 60 && max <= 75) {
              listarr[3][2] = `${number} - ${number + 4} Yrs`;
              listarr[3][3] = `${number} - ${number + 4} years`;
              listarr[3][1] = `${number} - ${number + 4} years`;
            }
            else if (max > 75 && max <= 80) {
              listarr[3][2] = `${number} - ${number + 4} Yrs`;
              listarr[3][3] = "> 75 years";
              listarr[3][1] = `> 75 years`;
            }
            else {
              // listarr[3][2] = `${number} - ${number + 4} Yrs`;
              listarr[3][2] = "Above 80 Yrs";
              listarr[3][3] = "> 75 years";
              listarr[3][1] = `> 75 years`;
            }
  
  
          } else {
            listarr[3][2] = "Above 80 Yrs";
            listarr[3][3] = "> 75 years";
            listarr[3][1] = `> 75 years`;
          }
  
          break;
        case "period":
          listarr[4] = `${data.period} Year`;
  
          break;
        default:
  
      }
    });
  
    for (let i = 1; i < listarr.length; i++) {
      if (Array.isArray(listarr[i])) {
        if (listarr[i][1])
          careReqbody.postedField[`field_${i}`] = listarr[i][1];
        if (listarr[i][3])
          careSeniorReq.postedField[`field_${i}`] = listarr[i][3];
        carefreeReq.postedField[`field_${i}`] = listarr[i][2];
      }
      else {
        if (listarr[i]) {
          careReqbody.postedField[`field_${i}`] = listarr[i];
          careSeniorReq.postedField[`field_${i}`] = listarr[i];
          carefreeReq.postedField[`field_${i}`] = listarr[i];
        }
      }
    }
  
    for (let i = 0; i < stringfield_care.length; i++)
      careReqbody.postedField[`field_${stringfield_care[i]}`] = 0;
  
    for (let j = 0; j < stringfield_caresenior.length; j++)
      careSeniorReq.postedField[`field_${stringfield_caresenior[j]}`] = 0;
  
      const Finalobj={}
  
      if (data.sumInsuredValue) {
        switch (data.sumInsuredValue) {
          case "400000":
           
            Finalobj["careReq"] = careReqbody;
            break;
          case "300000":
            
            Finalobj["careSenior"] = careSeniorReq;
            Finalobj["careFree"] = carefreeReq;
            break;
          case "500000":
            Finalobj["careReq"] = careReqbody;
            Finalobj["careSenior"] = careSeniorReq;
            Finalobj["careFree"] = carefreeReq;
            break;
  
          default:
            
            break;
        }
  
      }
  
      
  
  return Finalobj;
  }
  
  export default careReqbodyGen;