import { Typography, Box } from '@mui/material'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { date } from 'yup'
import validateCriticalIlleness from '../../../Assets/js/CriticalIllenesssValidation'
import TextError from '../TextError'

const FormikRadio = (props) => {
    const { label, name, validation, options, hidden,previousIndex, arrayindex, prevArrayIndex, errors, ...rest } = props

    const {values} =useFormikContext()
    //console.log('options radio',options)

    useEffect(() => {
        //console.log('setSocial',values?.dependentDetail['socialStatusBpl'])
        
      
    }, [])
    

    return (
        <div className=" form-control ">
            {/* <label>{label}</label>{" "} */}
            <div className='d-flex justify-content-between'>
                <div>
                    <label>{label}</label>
                </div>
                <div>
                    <Field name={name} 
                        validate={val => name === 'dependentDetail.criticalIllness' ? validateCriticalIlleness(label, val) : null}
                    >
                        {
                            ({ field, form, setErrors }) => {
                                const { setFieldValue, setFieldError, values } = form


                                const handleChange = (val) => {
                                    console.log('name', name, arrayindex, prevArrayIndex,val.target.value)
                                    setFieldValue(name, val.target.value)


                                    if(name === `primaryDetail[${arrayindex}].genderCd`){

                                        if(val.target.value === "MALE"){
                                            setFieldValue(`primaryDetail[${arrayindex}].titleCd`,"MR")
                                        }else{
                                            setFieldValue(`primaryDetail[${arrayindex}].titleCd`,"MS")

                                        }
                                    }
                                    if(name === `dependentDetail.genderCd`){
                                        
                                        if(val.target.value === "MALE"){
                                            setFieldValue(`dependentDetail.titleCd`,"MR")
                                        }else{
                                            setFieldValue(`dependentDetail.titleCd`,"MS")

                                        }
                                    }

                                    if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`, today.toLocaleDateString());
                                            }



                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd2`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {

                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd2_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd2_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd2_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd2_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd2_date`, today.toLocaleDateString());
                                            }

                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd3`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd3_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd3_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd3_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd3_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd3_date`, today.toLocaleDateString());
                                            }

                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd4`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd4_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd4_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd4_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd4_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd4_date`, today.toLocaleDateString());
                                            }
                                          
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd5`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd5_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd5_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd5_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd5_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd5_date`, today.toLocaleDateString());
                                            }
                                           
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd6`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd6_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd6_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd6_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd6_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd6_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd7`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd7_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd7_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd7_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd7_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd7_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd8`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd8_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd8_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd8_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd8_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd8_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd9`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd9_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd9_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd9_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd9_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd5_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd10`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd10_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd10_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd10_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd10_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd10_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd11`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd11_date`))
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd11_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd11_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {

                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd11_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd11_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd11_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd12`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd12_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd12_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd12_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd12_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd12_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd13`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd13_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd13_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd13_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd13_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd13_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd14`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd14_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd14_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd14_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd14_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd14_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd15`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd15_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd15_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd15_date`)!== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd15_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd15_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd16`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd16_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd16_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd16_date`)!== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd16_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd16_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd17`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd17_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd17_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd17_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd17_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd17_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd18`) {
                                        console.log('hshs', val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd18_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd18_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd18_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd18_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd18_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd19`) {
                                        console.log('hshs', val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd19_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd19_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd19_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd19_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd19_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd20`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd20_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd20_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd20_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd20_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd20_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd21`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd21_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd21_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd21_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd21_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd21_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd22`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd22_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd22_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd22_date`) !== null) {
                                                document.getElementById(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd22_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`dependentDetail.partyQuestionDOList[${arrayindex}].questionSetCd22_date`, today.toLocaleDateString());
                                            }
                                        }
                                    }else{

                                    }


                                    

                                    if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`, today.toLocaleDateString());
                                            }



                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd2`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {

                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd2_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd2_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd2_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd2_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd2_date`, today.toLocaleDateString());
                                            }

                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd3`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd3_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd3_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd3_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd3_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd3_date`, today.toLocaleDateString());
                                            }

                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd4`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd4_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd4_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd4_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd4_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd4_date`, today.toLocaleDateString());
                                            }
                                          
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd5`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd5_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd5_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd5_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd5_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd5_date`, today.toLocaleDateString());
                                            }
                                           
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd6`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd6_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd6_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd6_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd6_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd6_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd7`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd7_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd7_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            // console.log('prese',today.toLocaleDateString())
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd7_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd7_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd7_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd8`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd8_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd8_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd8_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd8_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd8_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd9`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd9_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd9_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd9_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd9_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd9_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd10`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd10_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd10_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd10_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd10_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd10_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd11`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd11_date`))
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd11_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd11_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {

                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd11_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd11_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd11_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd12`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd12_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd12_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd12_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd12_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd12_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd13`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd13_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd13_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd13_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd13_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd13_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd14`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd14_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd14_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd14_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd14_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd14_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd15`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd15_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd15_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd15_date`)!== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd15_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd15_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd16`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd16_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd16_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd16_date`)!== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd16_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd16_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd17`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd17_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd17_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd17_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd17_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd17_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd18`) {
                                        console.log('hshs', val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd18_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd18_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd18_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd18_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd18_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd19`) {
                                        console.log('hshs', val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd19_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd19_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd19_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd19_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd19_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd20`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd20_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd20_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd20_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd20_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd20_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd21`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd21_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd21_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd21_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd21_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd21_date`, today.toLocaleDateString());
                                            }
                                        }
                                    } else if (name === `primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd22`) {
                                        //console.log('hshs',val.target.value)
                                        if (val.target.value !== 'NO') {
                                            //console.log('if part', document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd1_date`).closest('.mb-3'))                                    
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd22_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd22_date`).closest('.mb-3').removeAttribute('hidden')
                                            }
                                        } else {
                                            let today = new Date();
                                            if (document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd22_date`) !== null) {
                                                document.getElementById(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd22_date`).closest('.mb-3').setAttribute('hidden', true);
                                                setFieldValue(`primaryDetail[${previousIndex}].partyQuestionDOList[${arrayindex}].questionSetCd22_date`, today.toLocaleDateString());
                                            }
                                        }
                                    }else{

                                    }

                                    /* if (name === 'dependentDetail.socialStatusBpl' || name === 'dependentDetail.socialStatusDisabled' 
                                    || name === 'dependentDetail.socialStatusInformal' || name === 'dependentDetail.socialStatusUnorganized') {

                                        setFieldValue(name, val.target.value)

                                        if (val.target.value === "1") {
                                            console.log(typeof(val.target.value))

                                            setFieldValue('dependentDetail.socialStatus', '1')
                                        } else  if (val.target.value === "0"){
                                            //setFieldValue(name, val.target.value)

                                            console.log('nnnooooooo',form.values.dependentDetail['socialStatusBpl'])
                                            if (values.dependentDetail['socialStatusBpl'] === ""
                                                || values.dependentDetail['socialStatusDisabled'] === ""
                                                || values.dependentDetail['socialStatusInformal'] === ""
                                                || values.dependentDetail['socialStatusUnorganized'] ===""                                                
                                                ) {

                                                setFieldValue('dependentDetail.socialStatus', '0')
                                            }else if(
                                                values.dependentDetail['socialStatusBpl'] === "0"
                                                && values.dependentDetail['socialStatusDisabled'] === "0"
                                                && values.dependentDetail['socialStatusInformal'] === "0"
                                                && values.dependentDetail['socialStatusUnorganized'] ==="0"
                                            ){
                                                setFieldValue('dependentDetail.socialStatus', '0')

                                            }
                                        }
                                    } */

                                    if (name == `primaryDetail[${arrayindex}].illness`) {
                                        //console.log('hhhhhh', document.getElementById(`insureds[${arrayindex}].buyBackPED`))

                                        //console.log('val', val.target.value)
                                        if (val.target.value === 'YES') {
                                            console.log('buy', document.getElementById(`primaryDetail[${arrayindex}].buyBackPED`))
                                            console.log('desc', document.getElementById(`primaryDetail[${arrayindex}].descriptionIllness`))


                                            if (document.getElementById(`primaryDetail[${arrayindex}].buyBackPED`) !== null) {
                                                document.getElementById(`primaryDetail[${arrayindex}].buyBackPED`).closest('.mb-3').removeAttribute('hidden')
                                                setFieldValue(`primaryDetail[${arrayindex}].buyBackPED`, '')

                                            }

                                            if (document.getElementById(`primaryDetail[${arrayindex}].descriptionIllness`) !== null) {
                                                document.getElementById(`primaryDetail[${arrayindex}].descriptionIllness`).closest('.mb-3').removeAttribute('hidden')
                                                setFieldValue(`primaryDetail[${arrayindex}].descriptionIllness`, '')

                                            }
                                            //console.log('gsgsgsf')
                                            //console.log(`'insureds[${arrayindex}].buyBackPED'`)

                                            // console.log(document.getElementById(`insureds[${arrayindex}].buyBackPED`).closest('.mb-3'))                                                    

                                        } else {
                                            if (document.getElementById(`primaryDetail[${arrayindex}].buyBackPED`) !== null) {
                                                document.getElementById(`primaryDetail[${arrayindex}].buyBackPED`).closest('.mb-3').setAttribute('hidden', true)
                                                setFieldValue(`primaryDetail[${arrayindex}].buyBackPED`, '0')
                                            }

                                            if (document.getElementById(`primaryDetail[${arrayindex}].descriptionIllness`) !== null) {
                                                document.getElementById(`primaryDetail[${arrayindex}].descriptionIllness`).closest('.mb-3').setAttribute('hidden', true)
                                                setFieldValue(`primaryDetail[${arrayindex}].descriptionIllness`, 'None')
                                            }


                                        }
                                    }

                                    if (name === 'dependentDetail.criticalIllness') {
                                        console.log('criticalIllness', val.target.value)
                                        if (val.target.value === '1') {
                                            // setErrors({...errors, [`${name}`]:"Can't Proceed further" })
                                            //setErrors({ })

                                            setFieldError(name, "Can't Proceed further")
                                            //setFieldValue(name,'',false)


                                        }
                                    }


                                    if (arrayindex !== undefined) {
                                        //console.log('jjjjjj', arrayindex)

                                        if (name === `insureds[${arrayindex}].isPersonalAccidentApplicable`) {
                                            //   console.log('val', val.target.value)
                                            let flag;
                                            if (val.target.value === 'true') {
                                                //setFieldValue(`insureds[${arrayindex}].isPersonalAccidentApplicable`,val.target.value)

                                                console.log('relat', values.insureds[`${arrayindex}`].relationshipId)
                                                let relationShip = values.insureds[`${arrayindex}`].relationshipId
                                                let cond = relationShip === '1' ? '2' : '1'
                                                console.log('cond', cond)
                                                values.insureds.map((insured, index) => {
                                                    if (insured.relationshipId === cond) {
                                                        flag = index;
                                                    }
                                                    console.log('insured', insured.isPersonalAccidentApplicable, index)
                                                })
                                                console.log('flag', flag)
                                                console.log()
                                                document.getElementById(`insureds[${flag}].isPersonalAccidentApplicable`).closest('.mb-3').setAttribute('hidden', true)
                                                setFieldValue(`insureds[${flag}].isPersonalAccidentApplicable`, 'false');

                                            }
                                            //console.log('insureds',values.insureds)
                                        }

                                        // console.log('val', val.target.value)

                                        if (name == `insureds[${arrayindex}].illness`) {
                                            //console.log('hhhhhh', document.getElementById(`insureds[${arrayindex}].buyBackPED`))

                                            //console.log('val', val.target.value)
                                            if (val.target.value === 'YES') {
                                                console.log('buy', document.getElementById(`insureds[${arrayindex}].buyBackPED`))
                                                console.log('desc', document.getElementById(`insureds[${arrayindex}].descriptionIllness`))


                                                if (document.getElementById(`insureds[${arrayindex}].buyBackPED`) !== null) {
                                                    document.getElementById(`insureds[${arrayindex}].buyBackPED`).closest('.mb-3').removeAttribute('hidden')

                                                }

                                                if (document.getElementById(`insureds[${arrayindex}].descriptionIllness`) !== null) {
                                                    document.getElementById(`insureds[${arrayindex}].descriptionIllness`).closest('.mb-3').removeAttribute('hidden')
                                                }
                                                //console.log('gsgsgsf')
                                                //console.log(`'insureds[${arrayindex}].buyBackPED'`)

                                                // console.log(document.getElementById(`insureds[${arrayindex}].buyBackPED`).closest('.mb-3'))                                                    

                                            } else {
                                                if (document.getElementById(`insureds[${arrayindex}].buyBackPED`) !== null) {
                                                    document.getElementById(`insureds[${arrayindex}].buyBackPED`).closest('.mb-3').setAttribute('hidden', true)
                                                    setFieldValue(`insureds[${arrayindex}].buyBackPED`, '0')
                                                }

                                                if (document.getElementById(`insureds[${arrayindex}].descriptionIllness`) !== null) {
                                                    document.getElementById(`insureds[${arrayindex}].descriptionIllness`).closest('.mb-3').setAttribute('hidden', true)
                                                    setFieldValue(`insureds[${arrayindex}].descriptionIllness`, 'None')
                                                }


                                            }
                                        }
                                    }

                                    /*  if (name === 'socialStatus') {

                                         if (val.target.value === '1') {
                                             document.getElementById('socialStatusBpl').closest('.mb-3').removeAttribute('hidden')
                                             document.getElementById('socialStatusUnorganized').closest('.mb-3').removeAttribute('hidden')
                                             document.getElementById('socialStatusInformal').closest('.mb-3').removeAttribute('hidden')
                                             document.getElementById('socialStatusDisabled').closest('.mb-3').removeAttribute('hidden')


                                             console.log('radio check', document.getElementById('socialStatusUnorganized').closest('.mb-3'))
                                             console.log('hsgsgsgs', val.target.value)
                                         } else {
                                             document.getElementById('socialStatusBpl').closest('.mb-3').setAttribute('hidden', true)
                                             document.getElementById('socialStatusUnorganized').closest('.mb-3').setAttribute('hidden', true)
                                             document.getElementById('socialStatusInformal').closest('.mb-3').setAttribute('hidden', true)
                                             document.getElementById('socialStatusDisabled').closest('.mb-3').setAttribute('hidden', true)

                                         }
                                     } */
                                }


                                const handleOnBlur = (val) => {
                                    //console.log('hsgsgsgs', val.target.value)

                                 /*    let socialStatus = (parseInt(values.dependentDetail['socialStatusBpl'])
                                    || parseInt(values.dependentDetail['socialStatusDisabled'])
                                    || parseInt(values.dependentDetail['socialStatusInformal'])
                                    || parseInt(values.dependentDetail['socialStatusUnorganized'])) */

                                /* if (socialStatus !== NaN) {
                                    setFieldValue('dependentDetail.socialStatus', socialStatus.toString())
                                } */
                                   /*  if (name === 'primaryDetail[${previousIndex}].socialStatus') {

                                        let socialStatus = (parseInt(form.values['socialStatusBpl'])
                                            || parseInt(form.values['socialStatusDisabled'])
                                            || parseInt(form.values['socialStatusInformal'])
                                            || parseInt(form.values['socialStatusUnorganized']))

                                        if (socialStatus !== NaN) {
                                            setFieldValue('socialStatus', socialStatus.toString())
                                        }

                                        if (val.target.value === '1') {
                                            document.getElementById('socialStatusBpl').closest('.mb-3').removeAttribute('hidden')
                                            document.getElementById('socialStatusUnorganized').closest('.mb-3').removeAttribute('hidden')
                                            document.getElementById('socialStatusInformal').closest('.mb-3').removeAttribute('hidden')
                                            document.getElementById('socialStatusDisabled').closest('.mb-3').removeAttribute('hidden')


                                            console.log('radio check', document.getElementById('socialStatusUnorganized').closest('.mb-3'))
                                            console.log('hsgsgsgs', val.target.value)
                                        } else {
                                            document.getElementById('socialStatusBpl').closest('.mb-3').setAttribute('hidden', true)
                                            document.getElementById('socialStatusUnorganized').closest('.mb-3').setAttribute('hidden', true)
                                            document.getElementById('socialStatusInformal').closest('.mb-3').setAttribute('hidden', true)
                                            document.getElementById('socialStatusDisabled').closest('.mb-3').setAttribute('hidden', true)

                                        }
                                    }
 */
                                    if (name === 'criticalIllness') {
                                        console.log('criticalIllness', val.target.value)
                                        if (val.target.value === '1') {
                                            // setErrors({...errors, [`${name}`]:"Can't Proceed further" })
                                            //setErrors({ })

                                            setFieldError(name, "Can't Proceed further")
                                            //setFieldValue(name,'',false)


                                        }
                                    }


                                }

                                return options.map((option,index) => {
                                    //console.log('radio', field)

                                   



                                    return (
                                        <div className="form-check form-check-inline " key={index} >
                                            <Field
                                                type='radio'
                                                className="form-check-input "
                                                id={field.name}
                                                {...field}
                                                value={option.value}
                                                onChange={val => handleChange(val)}
                                                onBlur={val => handleOnBlur(val)}

                                            />
                                            <label htmlFor={field.name}>{option.label}</label>
                                        </div>
                                    )
                                })
                            }
                        }
                    </Field>
                </div>

            </div>


            <ErrorMessage name={name} component={TextError} />


        </div>
    )
}

export default FormikRadio