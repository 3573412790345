import React from "react";
import FormikControl from "../../Components/LifeInsFormikComponent/FormikControl";
import { FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import CreateQuoteObject from "./CreateQuoteObject";
import { useState } from "react";

const CreateQuoteArray = (props) => {
  const { name, Field,familyArray,setfamilyArray,FFamily } = props;
  const [arrayItem, setArrayItem] = useState({})

console.log(familyArray,"familyArray")

 
  // console.log(name)
  return (
    <div>
      <div autoComplete="off">
        <div>
            <b>{Field.name}</b>
        </div>
        <div>
          {Object.keys(Field).length > 0 ? (
            <FieldArray
            name={Field.name==="Family"?"primaryDetails[0].Family":Field.name}
              render={(arrayHelpers) => {
                const AddmemberHandler=()=>{
                  setfamilyArray(familyArray+1)
                  arrayHelpers.push(FFamily)
                }

                const FamilyDeleteHandler=()=>{
                  // if(familyArray<=1)
                  //    setfamilyArray(familyArray)
                  // else{
                  //   setfamilyArray(familyArray-1)
                  // }  
                  arrayHelpers.pop(FFamily)
                  setfamilyArray(familyArray-1) 
                }

               

                return (
                  <div className={Field.name}>
                    {(Field.name==="Family") && 
                    <div className="f_delete_con">
                    <button className={"family_add"} type="button" onClick={AddmemberHandler}>Add Member</button>
                    </div>}
                    
                    <div>
                    {_.times((Field.name==="Family"?familyArray:1), (i) => {
                      const objName = Field.name;
                      const arrObjName = `${objName}[${i}]`;
                      //  console.log("name...",objName,arrObjName)
                      return (
                        <div id ={arrObjName} className='Array_end'>
                          {Field.obj.map((field, index) => {
                            return field.htmlElement === "TextField" ? (
                              <div
                                className="mb-3"
                                id={field.name}
                                key={index}
                                hidden={field.hidden}
                              >
                                <FormikControl
                                  control="input"
                                  name={`${name}.${arrObjName}.${field.name}`}
                                  manualValidation={field.manualValidation}
                                  errors={field.errors}
                                  readOnly={field.readOnly}
                                 
                                  url={field.url}
                                  label={field.label}
                                  type={field.dataType}
                                  
                                />
                              </div>
                            ) : field.htmlElement === "Select" ? (
                              <div
                                className="mb-3"
                                key={index}
                                hidden={field.hidden}
                              >
                                <FormikControl
                                  control="select"
                                  readOnly={field.readOnly}
                                  name={`${name}.${arrObjName}.${field.name}`}
                                  arrind={i}
                                  parentname={`${name}.${arrObjName}`}
                                  label={field.label}
                                  type={field.dataType}
                                  arrayindex={i}
                                  options={field.options}
                                />
                              </div>
                            ) : field.htmlElement === "TextArea" ? (
                              <div
                                className="mb-3"
                                key={index}
                                hidden={field.hidden}
                              >
                                <FormikControl
                                  control="textarea"
                                  name={`${name}.${arrObjName}.${field.name}`}
                                  
                                  label={field.label}
                                  type={field.dataType}
                                  
                                />
                              </div>
                            ) : field.htmlElement === "Radio" ? (
                              <div
                                className="mb-3"
                                key={index}
                                hidden={field.hidden}
                              >
                                <FormikControl
                                  control="radio"
                                  name={`${name}.${arrObjName}.${field.name}`}
                                 
                                  dependent={field.dependent}
                                  options={field.options}
                                  manualValidation={field.manualValidation}
                                  label={field.label}
                                  type={field.dataType}
                                 
                                />
                              </div>
                            ) : field.htmlElement === "DatePicker" ? (
                              <div
                                className="mb-3"
                                id={field.name}
                                key={index}
                                hidden={field.hidden}
                              >
                                <FormikControl
                                  control="date"
                                  name={`${name}.${arrObjName}.${field.name}`}
                                  
                                  readOnly={field.readOnly}
                                  label={field.label}
                                  type={field.dataType}
                                />
                              </div>
                            ) : field.htmlElement === "checkbox" ? (
                              <div
                                className="mb-3"
                                key={index}
                                hidden={field.hidden}
                              >
                                <FormikControl
                                  control="checkBox"
                                  name={`${name}.${arrObjName}.${field.name}`}
                                 
                                  label={field.label}
                                  type={field.dataType}
                                 
                                />
                              </div>
                            ) : field.htmlElement === "File" ? (
                              <div
                                className="mb-3"
                                key={index}
                                hidden={field.hidden}
                              >
                                <FormikControl
                                  control="file"
                                  name={`${name}.${arrObjName}.${field.name}`}
                                 
                                  label={field.label}
                                  type={field.dataType}
                                 
                                />
                              </div>):field.htmlElement === "Object" ? (
                              <div
                                className="mb-3"
                                key={index}
                                hidden={field.hidden}
                              >
                                <CreateQuoteObject
                                 
                                  name={`${name}.${arrObjName}.${field.name}`}
                                 Field={field}
                                 
                                 
                                />
                              </div>):null;
                          })}
                          {(Field.name==="Family"&& i>1 )&&
                          <div className="f_delete_con">
                            <button className={"family_del"} onClick={FamilyDeleteHandler} type="button" >Delete</button>
                          </div>
                          }
                        </div>
                      );
                    })}
                    </div>
                   
                  </div>
                );
              }}
            ></FieldArray>
          ) : null}
        </div>

       
      </div>
    </div>
  );
};
export default CreateQuoteArray;