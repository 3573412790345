

export const createTabCareReqBdy = (data, prodRequestBody) => {

    console.log('data', data)
    let updatedReqBody = prodRequestBody;
    let partyDOList = [];
  
    let policy = {};
    let policyObject = {}
    var partyDoListArray = []
    let policyAdditionalFieldsDOList = []
    const moment = require('moment');
  
    Object.entries(data).map((field, index) => {
  
      switch (field[0]) {
        case "primaryDetail":
  
          field[1].map((primary, primaryInd) => {
            let newPartyQuestionArray = [];
            let newAdressArray = []
            let newIdentityDOList=[]
            console.log('primary', primary)
            console.log('prodRequestBody', prodRequestBody)
            let nePrimary = primary
            if (primary.partyQuestionDOList) {
              //console.log('question', field.partyQuestionDOList)
              primary.partyQuestionDOList.map((questions, questionsIndex) => {
                //console.log('inside quest',questions)
                Object.entries(questions).map((questionObject, objectIndex) => {
                  //  console.log('ususuddg',questionObject)
                  if (questionObject[0] !== "questionCd") {
                    let object = {};
  
                    switch (questionObject[0]) {
                      case 'questionSetCd1':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDdiabetesDetails'
                          object['questionCd'] = '205'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
                          // console.log('recDate',recDate)
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '205'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = 'diabetesExistingSince'
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        // console.log('ne questuihd',newPartyQuestionArray)
                        break;
                      case 'questionSetCd2':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDhyperTensionDetails'
                          object['questionCd'] = '207'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '207'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = 'hyperTensionExistingSince'
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd3':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDliverDetails'
                          object['questionCd'] = '232'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '232'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "liverExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd4':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDcancerDetails'
                          object['questionCd'] = '114'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '114'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "cancerExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd5':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDcardiacDetails'
                          object['questionCd'] = '143'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '143'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "cardiacExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd6':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDjointpainDetails'
                          object['questionCd'] = '105'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '105'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "jointpainExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd7':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDkidneyDetails'
                          object['questionCd'] = '129'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '129'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "kidneyExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd8':
                        //.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDparalysisDetails'
                          object['questionCd'] = '164'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '164'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "paralysisExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd9':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDcongenitalDetails'
                          object['questionCd'] = '122'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '122'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "congenitalExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd10':
                        // console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDHivaidsDetails'
                          object['questionCd'] = '147'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '147'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "hivaidsExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd11':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'yesNoExist'
                          object['questionCd'] = 'pedYesNo'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
  
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'pedYesNo'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd12':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDHealthHospitalized'
                          object['questionCd'] = 'H001'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H001'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd13':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = "HEDHealthClaim"
                          object['questionCd'] = 'H002'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H002'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd14':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = "HEDHealthDeclined"
                          object['questionCd'] = 'H003'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H003'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd15':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = "HEDHealthCovered"
                          object['questionCd'] = 'H004'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H004'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd16':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = "PEDotherDetails"
                          object['questionCd'] = '210'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '210'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "otherExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd17':
                        //console.log('questionSetCd1', questionObject[1])
                        object['questionSetCd'] = "PEDotherDetails"
                        object['questionCd'] = 'otherDiseasesDescription'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                        /*  if (questionObject[1] === 'NO') {
                             object['questionSetCd'] = "PEDotherDetails"
                             object['questionCd'] = '210'
                             object['response'] = questionObject[1]
                             newPartyQuestionArray.push(object)
                         } else {
                             //console.log('hdh',questions.questionSetCd1_date)
                             let extraObject = {}
                             object['questionSetCd'] = questionObject[1]
                             object['questionCd'] = '210'
                             object['response'] = 'Yes'
                             newPartyQuestionArray.push(object)
                             extraObject['questionSetCd'] = questionObject[1]
                             extraObject['questionCd'] = "otherExistingSince"
                             extraObject['response'] = questions.questionSetCd16_date
                             newPartyQuestionArray.push(extraObject)
                         } */
                        break;
  
                      case 'questionSetCd18':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDRespiratoryDetails'
                          object['questionCd'] = '250'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '250'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "respiratoryExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd19':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDEndoDetails'
                          object['questionCd'] = '222'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '222'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "EndocriExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd20':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDillnessDetails'
                          object['questionCd'] = '502'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '502'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "illnessExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd21':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDSurgeryDetails'
                          object['questionCd'] = '503'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '503'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "SurgeryExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd22':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDSmokeDetails'
                          object['questionCd'] = '504'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '504'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "SmokeExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
  
                      case 'questionSetCd23':
                        //console.log('questionSetCd1', questionObject[1])
                        object['questionSetCd'] = "PEDSmokeDetails"
                        object['questionCd'] = 'OtherSmokeDetails'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                        /*  if (questionObject[1] === 'NO') {
                             object['questionSetCd'] = "PEDotherDetails"
                             object['questionCd'] = '210'
                             object['response'] = questionObject[1]
                             newPartyQuestionArray.push(object)
                         } else {
                             //console.log('hdh',questions.questionSetCd1_date)
                             let extraObject = {}
                             object['questionSetCd'] = questionObject[1]
                             object['questionCd'] = '210'
                             object['response'] = 'Yes'
                             newPartyQuestionArray.push(object)
                             extraObject['questionSetCd'] = questionObject[1]
                             extraObject['questionCd'] = "otherExistingSince"
                             extraObject['response'] = questions.questionSetCd16_date
                             newPartyQuestionArray.push(extraObject)
                         } */
                        break;
  
                      default: break;
                    }
                  }
                })
              })
            }
  
            if (primary.partyAddressDOList) {
  
              console.log(`Adrees section at ${index}`, primary.partyAddressDOList)
              newAdressArray = [
                {
                  "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd,
                  "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang1,
                  "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang1,
                  "stateCd": primary.partyAddressDOList[0].stateCd,
                  "cityCd": primary.partyAddressDOList[0].cityCd,
                  "pinCode": primary.partyAddressDOList[0].pinCode,
                  "areaCd": primary.partyAddressDOList[0].areaCd,
                  "countryCd": primary.partyAddressDOList[0].countryCd
                },
                {
                  "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd2,
                  "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang12,
                  "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang12,
                  "stateCd": primary.partyAddressDOList[0].stateCd2,
                  "cityCd": primary.partyAddressDOList[0].cityCd2,
                  "pinCode": primary.partyAddressDOList[0].pinCode2,
                  "areaCd": primary.partyAddressDOList[0].areaCd2,
                  "countryCd": primary.partyAddressDOList[0].countryCd2
                },
              ]
            }
  
            if (primary.partyIdentityDOList) {
              console.log('idenee hhshshhs',primary.partyIdentityDOList)
              const identityNum= primary.partyIdentityDOList[0].panNumber !=='' 
              ? primary.partyIdentityDOList[0].panNumber: primary.partyIdentityDOList[0].passportNumber
              newIdentityDOList=[
                {
                  identityNum: identityNum,
                  identityTypeCd: primary.partyIdentityDOList[0].identityTypeCd
                }
              ]
            }
  
           
           
  
            let birthDate = new Date(primary.birthDt)
            let newDate = moment(birthDate).format('DD/MM/yyyy')
  
            nePrimary = {
              ...nePrimary,
              'birthDt': newDate,
              ['partyQuestionDOList']: newPartyQuestionArray,
              ['partyAddressDOList']: newAdressArray,
              ['partyIdentityDOList']: newIdentityDOList
            }
            console.log('primary2', nePrimary)
  
            partyDOList.push(nePrimary)
  
          });
  
          break;
        case "dependentDetail":
          let primary =field[1];
          let nePrimary = primary
          let newPartyQuestionArray = [];
          let newAdressArray = []
          let newIdentityDOList=[]
  
  
          if (primary.partyQuestionDOList) {
            //console.log('question', field.partyQuestionDOList)
            primary.partyQuestionDOList.map((questions, questionsIndex) => {
              //console.log('inside quest',questions)
              Object.entries(questions).map((questionObject, objectIndex) => {
                //  console.log('ususuddg',questionObject)
                if (questionObject[0] !== "questionCd") {
                  let object = {};
  
                  switch (questionObject[0]) {
                    case 'questionSetCd1':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDdiabetesDetails'
                        object['questionCd'] = '205'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
                        // console.log('recDate',recDate)
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '205'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = 'diabetesExistingSince'
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      // console.log('ne questuihd',newPartyQuestionArray)
                      break;
                    case 'questionSetCd2':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDhyperTensionDetails'
                        object['questionCd'] = '207'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '207'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = 'hyperTensionExistingSince'
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd3':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDliverDetails'
                        object['questionCd'] = '232'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '232'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "liverExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd4':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDcancerDetails'
                        object['questionCd'] = '114'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '114'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "cancerExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd5':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDcardiacDetails'
                        object['questionCd'] = '143'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '143'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "cardiacExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd6':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDjointpainDetails'
                        object['questionCd'] = '105'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '105'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "jointpainExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd7':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDkidneyDetails'
                        object['questionCd'] = '129'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '129'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "kidneyExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd8':
                      //.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDparalysisDetails'
                        object['questionCd'] = '164'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '164'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "paralysisExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd9':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDcongenitalDetails'
                        object['questionCd'] = '122'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '122'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "congenitalExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd10':
                      // console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDHivaidsDetails'
                        object['questionCd'] = '147'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '147'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "hivaidsExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd11':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'yesNoExist'
                        object['questionCd'] = 'pedYesNo'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
  
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'pedYesNo'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd12':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDHealthHospitalized'
                        object['questionCd'] = 'H001'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H001'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd13':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = "HEDHealthClaim"
                        object['questionCd'] = 'H002'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H002'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd14':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = "HEDHealthDeclined"
                        object['questionCd'] = 'H003'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H003'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd15':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = "HEDHealthCovered"
                        object['questionCd'] = 'H004'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H004'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd16':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = "PEDotherDetails"
                        object['questionCd'] = '210'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '210'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "otherExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd17':
                      //console.log('questionSetCd1', questionObject[1])
                      object['questionSetCd'] = "PEDotherDetails"
                      object['questionCd'] = 'otherDiseasesDescription'
                      object['response'] = questionObject[1]
                      newPartyQuestionArray.push(object)
                      /*  if (questionObject[1] === 'NO') {
                           object['questionSetCd'] = "PEDotherDetails"
                           object['questionCd'] = '210'
                           object['response'] = questionObject[1]
                           newPartyQuestionArray.push(object)
                       } else {
                           //console.log('hdh',questions.questionSetCd1_date)
                           let extraObject = {}
                           object['questionSetCd'] = questionObject[1]
                           object['questionCd'] = '210'
                           object['response'] = 'Yes'
                           newPartyQuestionArray.push(object)
                           extraObject['questionSetCd'] = questionObject[1]
                           extraObject['questionCd'] = "otherExistingSince"
                           extraObject['response'] = questions.questionSetCd16_date
                           newPartyQuestionArray.push(extraObject)
                       } */
                      break;
  
                    case 'questionSetCd18':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDRespiratoryDetails'
                        object['questionCd'] = '250'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '250'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "respiratoryExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd19':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDEndoDetails'
                        object['questionCd'] = '222'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '222'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "EndocriExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd20':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDillnessDetails'
                        object['questionCd'] = '502'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '502'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "illnessExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd21':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDSurgeryDetails'
                        object['questionCd'] = '503'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '503'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "SurgeryExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd22':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDSmokeDetails'
                        object['questionCd'] = '504'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '504'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "SmokeExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
  
                    case 'questionSetCd23':
                      //console.log('questionSetCd1', questionObject[1])
                      object['questionSetCd'] = "PEDSmokeDetails"
                      object['questionCd'] = 'OtherSmokeDetails'
                      object['response'] = questionObject[1]
                      newPartyQuestionArray.push(object)
                      /*  if (questionObject[1] === 'NO') {
                           object['questionSetCd'] = "PEDotherDetails"
                           object['questionCd'] = '210'
                           object['response'] = questionObject[1]
                           newPartyQuestionArray.push(object)
                       } else {
                           //console.log('hdh',questions.questionSetCd1_date)
                           let extraObject = {}
                           object['questionSetCd'] = questionObject[1]
                           object['questionCd'] = '210'
                           object['response'] = 'Yes'
                           newPartyQuestionArray.push(object)
                           extraObject['questionSetCd'] = questionObject[1]
                           extraObject['questionCd'] = "otherExistingSince"
                           extraObject['response'] = questions.questionSetCd16_date
                           newPartyQuestionArray.push(extraObject)
                       } */
                      break;
  
                    default: break;
                  }
                }
              })
            })
          }
  
          if (primary.partyAddressDOList) {
  
            console.log(`Adrees section at ${index}`, primary.partyAddressDOList)
            newAdressArray = [
              {
                "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd,
                "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang1,
                "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang1,
                "stateCd": primary.partyAddressDOList[0].stateCd,
                "cityCd": primary.partyAddressDOList[0].cityCd,
                "pinCode": primary.partyAddressDOList[0].pinCode,
                "areaCd": primary.partyAddressDOList[0].areaCd,
                "countryCd": primary.partyAddressDOList[0].countryCd
              },
              {
                "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd2,
                "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang12,
                "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang12,
                "stateCd": primary.partyAddressDOList[0].stateCd2,
                "cityCd": primary.partyAddressDOList[0].cityCd2,
                "pinCode": primary.partyAddressDOList[0].pinCode2,
                "areaCd": primary.partyAddressDOList[0].areaCd2,
                "countryCd": primary.partyAddressDOList[0].countryCd2
              },
            ]
          }
  
          if (primary.partyIdentityDOList) {
            console.log('idenee hhshshhs',primary.partyIdentityDOList)
            const identityNum= primary.partyIdentityDOList[0].panNumber !=='' 
            ? primary.partyIdentityDOList[0].panNumber: primary.partyIdentityDOList[0].passportNumber
            newIdentityDOList=[
              {
                identityNum: identityNum,
                identityTypeCd: primary.partyIdentityDOList[0].identityTypeCd
              }
            ]
          }
  
          policyObject = {
            abacusId: primary.abacusId,
            addOns: primary.addOns === "" ? "NONE" : primary.addOns,
            baseAgentId: primary.baseAgentId,
            baseProductId: primary.baseProductId,
            businessTypeCd: primary.businessTypeCd,
            coverType: primary.coverType,
            eldestMemberAge: primary.eldestMemberAge,
            isPremiumCalculation: primary.isPremiumCalculation,
            quotationPremium: primary.quotationPremium,
            quotationReferenceNum: primary.quotationReferenceNum,
            sumInsured: primary.sumInsured,
            sumInsuredValue: primary.sumInsuredValue,
            term: primary.term,
  
          }
  
          policyAdditionalFieldsDOList.push(primary.policyAdditionalFieldsDOList) 
          console.log('policyAdditionalFieldsDOList', policyAdditionalFieldsDOList)
          // console.log('primary2', primary)
          delete nePrimary['abacusId'];
          delete nePrimary['addOns'];
          delete nePrimary['baseAgentId'];
          delete nePrimary['baseProductId'];
          delete nePrimary['businessTypeCd'];
          delete nePrimary['coverType'];
          delete nePrimary['eldestMemberAge'];
          delete nePrimary['isPremiumCalculation'];
          delete nePrimary['quotationPremium'];
          delete nePrimary['quotationReferenceNum'];
          delete nePrimary['sumInsured'];
          delete nePrimary['sumInsuredValue'];
          delete nePrimary['term'];
          delete nePrimary['policyAdditionalFieldsDOList'];
  
          let birthDate = new Date(primary.birthDt)
            let newDate = moment(birthDate).format('DD/MM/yyyy')
  
            nePrimary = {
              ...nePrimary,
              'birthDt': newDate,
              ['partyQuestionDOList']: newPartyQuestionArray,
              ['partyAddressDOList']: newAdressArray,
              ['partyIdentityDOList']: newIdentityDOList
  
            }
            console.log('primary24', nePrimary)
  
            partyDOList.push(nePrimary)
  
  
          break;
        case "nomineeDetail":
          field[1].map((nestedField, ind) => {
            policyAdditionalFieldsDOList[0] = {
              ...policyAdditionalFieldsDOList[0],
              ...nestedField,
            };
          });
  
          break;
      }
    });
  
    console.log('policy', policy)
    console.log('policyAdditionalFieldsDOList', policyAdditionalFieldsDOList)
  
  
    const FinalCare = {
      intPolicyDataIO: {
        policy: {
          ...policyObject,
          partyDOList,
          policyAdditionalFieldsDOList
  
        }
      }
    };
    return FinalCare;
  };
  
  
  export const createTabCareFreedomReqBdy = (data, prodRequestBody) => {
    console.log('data', data)
    let updatedReqBody = prodRequestBody;
    let partyDOList = [];
  
    let policy = {};
    let policyObject = {}
    var partyDoListArray = []
    let policyAdditionalFieldsDOList = []
    const moment = require('moment');
  
    Object.entries(data).map((field, index) => {
  
      switch (field[0]) {
        case "primaryDetail":
  
          field[1].map((primary, primaryInd) => {
            let newPartyQuestionArray = [];
            let newAdressArray = []
            let newIdentityDOList= []
            console.log('primary', primary)
            //console.log('prodRequestBody', prodRequestBody)
            let nePrimary = primary
            if (primary.partyQuestionDOList) {
              //console.log('question', field.partyQuestionDOList)
              primary.partyQuestionDOList.map((questions, questionsIndex) => {
                //console.log('inside quest',questions)
                Object.entries(questions).map((questionObject, objectIndex) => {
                  //  console.log('ususuddg',questionObject)
                  if (questionObject[0] !== "questionCd") {
                    let object = {};
  
                    switch (questionObject[0]) {
                      case 'questionSetCd1':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFONE'
                          object['questionCd'] = 'H102'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H102'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        // console.log('ne questuihd',newPartyQuestionArray)
                        break;
                      case 'questionSetCd2':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTWO'
                          object['questionCd'] = 'H103'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H103'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd3':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTHREE'
                          object['questionCd'] = 'H104'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H104'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd4':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFFOUR'
                          object['questionCd'] = 'H105'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H105'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd5':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFFIVE'
                          object['questionCd'] = 'H106'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H106'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd6':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFSIX'
                          object['questionCd'] = 'H107'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H107'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd7':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFSEVEN'
                          object['questionCd'] = 'H108'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H108'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd8':
                        //.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFEIGHT'
                          object['questionCd'] = 'H109'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H109'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd9':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFNINE'
                          object['questionCd'] = 'H110'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H110'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd10':
                        // console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTEN'
                          object['questionCd'] = 'H111'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H111'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd11':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFELEVEN'
                          object['questionCd'] = 'H112'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H112'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd12':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTWELVE'
                          object['questionCd'] = 'H113'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H113'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd13':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTHIRTEEN'
                          object['questionCd'] = 'H114'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H114'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd14':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFFOURTEEN'
                          object['questionCd'] = 'H115'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H115'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd15':
                        //console.log('questionSetCd1', questionObject[1])
                        object['questionSetCd'] = "CFLEAFFIFTEEN"
                        object['questionCd'] = 'AddInfo'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                        /*  if (questionObject[1] === 'NO') {
                           object['questionSetCd'] = 'CFLEAFFIFTEEN'
                           object['questionCd'] = 'AddInfo'
                           object['response'] = questionObject[1]
                           newPartyQuestionArray.push(object)
                         } else {
                           object['questionSetCd'] = questionObject[1]
                           object['questionCd'] = 'AddInfo'
                           object['response'] = 'Yes'
                           newPartyQuestionArray.push(object)
                         } */
                        break;
  
                      default: break;
                    }
                  }
                })
              })
            }
  
            if (primary.partyAddressDOList) {
  
              console.log(`Adrees section at ${index}`, primary.partyAddressDOList)
              newAdressArray = [
                {
                  "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd,
                  "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang1,
                  "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang1,
                  "stateCd": primary.partyAddressDOList[0].stateCd,
                  "cityCd": primary.partyAddressDOList[0].cityCd,
                  "pinCode": primary.partyAddressDOList[0].pinCode,
                  "areaCd": primary.partyAddressDOList[0].areaCd,
                  "countryCd": primary.partyAddressDOList[0].countryCd
                },
                {
                  "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd2,
                  "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang12,
                  "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang12,
                  "stateCd": primary.partyAddressDOList[0].stateCd2,
                  "cityCd": primary.partyAddressDOList[0].cityCd2,
                  "pinCode": primary.partyAddressDOList[0].pinCode2,
                  "areaCd": primary.partyAddressDOList[0].areaCd2,
                  "countryCd": primary.partyAddressDOList[0].countryCd2
                },
              ]
            }
            console.log('addOns', primary.addOns)
  
            if (primary.partyIdentityDOList) {
              console.log('idenee hhshshhs',primary.partyIdentityDOList)
              const identityNum= primary.partyIdentityDOList[0].panNumber !=='' 
              ? primary.partyIdentityDOList[0].panNumber: primary.partyIdentityDOList[0].passportNumber
              newIdentityDOList=[
                {
                  identityNum: identityNum,
                  identityTypeCd: primary.partyIdentityDOList[0].identityTypeCd
                }
              ]
            }
  
            
           
            console.log('primary2', nePrimary)
          
  
            let birthDate = new Date(primary.birthDt)
            let newDate = moment(birthDate).format('DD/MM/yyyy')
  
            nePrimary = {
              ...nePrimary,
              'birthDt': newDate,
              ['partyQuestionDOList']: newPartyQuestionArray,
              ['partyAddressDOList']: newAdressArray,
              ['partyIdentityDOList']: newIdentityDOList
            }
            console.log('primary2', nePrimary)
  
            partyDOList.push(nePrimary)
  
          });
  
          break;
        case "dependentDetail":
          let primary= field[1]
          let newPartyQuestionArray = [];
          let newAdressArray = []
          let newIdentityDOList = []
          let nePrimary = primary
  
          if (primary.partyQuestionDOList) {
            //console.log('question', field.partyQuestionDOList)
            primary.partyQuestionDOList.map((questions, questionsIndex) => {
              //console.log('inside quest',questions)
              Object.entries(questions).map((questionObject, objectIndex) => {
                //  console.log('ususuddg',questionObject)
                if (questionObject[0] !== "questionCd") {
                  let object = {};
  
                  switch (questionObject[0]) {
                    case 'questionSetCd1':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFONE'
                        object['questionCd'] = 'H102'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H102'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      // console.log('ne questuihd',newPartyQuestionArray)
                      break;
                    case 'questionSetCd2':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFTWO'
                        object['questionCd'] = 'H103'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H103'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd3':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFTHREE'
                        object['questionCd'] = 'H104'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H104'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd4':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFFOUR'
                        object['questionCd'] = 'H105'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H105'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd5':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFFIVE'
                        object['questionCd'] = 'H106'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H106'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd6':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFSIX'
                        object['questionCd'] = 'H107'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H107'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd7':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFSEVEN'
                        object['questionCd'] = 'H108'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H108'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd8':
                      //.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFEIGHT'
                        object['questionCd'] = 'H109'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H109'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd9':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFNINE'
                        object['questionCd'] = 'H110'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H110'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd10':
                      // console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFTEN'
                        object['questionCd'] = 'H111'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H111'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd11':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFELEVEN'
                        object['questionCd'] = 'H112'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H112'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd12':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFTWELVE'
                        object['questionCd'] = 'H113'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H113'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd13':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFTHIRTEEN'
                        object['questionCd'] = 'H114'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H114'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd14':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDCFLEAFFOURTEEN'
                        object['questionCd'] = 'H115'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H115'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd15':
                      //console.log('questionSetCd1', questionObject[1])
                      object['questionSetCd'] = "CFLEAFFIFTEEN"
                      object['questionCd'] = 'AddInfo'
                      object['response'] = questionObject[1]
                      newPartyQuestionArray.push(object)
                      /*  if (questionObject[1] === 'NO') {
                         object['questionSetCd'] = 'CFLEAFFIFTEEN'
                         object['questionCd'] = 'AddInfo'
                         object['response'] = questionObject[1]
                         newPartyQuestionArray.push(object)
                       } else {
                         object['questionSetCd'] = questionObject[1]
                         object['questionCd'] = 'AddInfo'
                         object['response'] = 'Yes'
                         newPartyQuestionArray.push(object)
                       } */
                      break;
  
                    default: break;
                  }
                }
              })
            })
          }
  
          if (primary.partyAddressDOList) {
  
            console.log(`Adrees section at ${index}`, primary.partyAddressDOList)
            newAdressArray = [
              {
                "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd,
                "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang1,
                "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang1,
                "stateCd": primary.partyAddressDOList[0].stateCd,
                "cityCd": primary.partyAddressDOList[0].cityCd,
                "pinCode": primary.partyAddressDOList[0].pinCode,
                "areaCd": primary.partyAddressDOList[0].areaCd,
                "countryCd": primary.partyAddressDOList[0].countryCd
              },
              {
                "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd2,
                "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang12,
                "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang12,
                "stateCd": primary.partyAddressDOList[0].stateCd2,
                "cityCd": primary.partyAddressDOList[0].cityCd2,
                "pinCode": primary.partyAddressDOList[0].pinCode2,
                "areaCd": primary.partyAddressDOList[0].areaCd2,
                "countryCd": primary.partyAddressDOList[0].countryCd2
              },
            ]
          }
  
          if (primary.partyIdentityDOList) {
            console.log('idenee hhshshhs',primary.partyIdentityDOList)
            const identityNum= primary.partyIdentityDOList[0].panNumber !=='' 
            ? primary.partyIdentityDOList[0].panNumber: primary.partyIdentityDOList[0].passportNumber
            newIdentityDOList=[
              {
                identityNum: identityNum,
                identityTypeCd: primary.partyIdentityDOList[0].identityTypeCd
              }
            ]
          }
  
          policyAdditionalFieldsDOList.push(primary.policyAdditionalFieldsDOList)
          console.log('policyAdditionalFieldsDOList', primary.policyAdditionalFieldsDOList)
  
          policyObject = {
            //abacusId: primary.abacusId,
            baseAgentId: primary.baseAgentId,
            baseProductId: primary.baseProductId,
            businessTypeCd: primary.businessTypeCd,
            coverType: primary.coverType,
            //eldestMemberAge: primary.eldestMemberAge,
            isPremiumCalculation: primary.isPremiumCalculation,
            //quotationPremium: primary.quotationPremium,
            quotationReferenceNum: primary.quotationReferenceNum,
            sumInsured: primary.sumInsured,
            //sumInsuredValue: primary.sumInsuredValue,
            term: primary.term,
          }
          delete primary['baseAgentId']
          delete primary['baseProductId']
          delete primary['businessTypeCd']
          delete primary['coverType']
          delete primary['isPremiumCalculation']
          delete primary['quotationReferenceNum']
          delete primary['sumInsured']
          delete primary['term']
          delete primary['policyAdditionalFieldsDOList']
  
  
  
          
          let birthDate = new Date(primary.birthDt)
            let newDate = moment(birthDate).format('DD/MM/yyyy')
  
            nePrimary = {
              ...nePrimary,
              'birthDt': newDate,
              ['partyQuestionDOList']: newPartyQuestionArray,
              ['partyAddressDOList']: newAdressArray,
              ['partyIdentityDOList']: newIdentityDOList
            }
            console.log('primary24', nePrimary)
  
            partyDOList.push(nePrimary)
  
  
         /*  field[1].map((primary, primaryInd) => {
            let newPartyQuestionArray = [];
            let newAdressArray = []
            console.log('primary', primary)
            console.log('prodRequestBody', prodRequestBody)
            let nePrimary = primary
  
            if (primary.partyQuestionDOList) {
              //console.log('question', field.partyQuestionDOList)
              primary.partyQuestionDOList.map((questions, questionsIndex) => {
                //console.log('inside quest',questions)
                Object.entries(questions).map((questionObject, objectIndex) => {
                  //  console.log('ususuddg',questionObject)
                  if (questionObject[0] !== "questionCd") {
                    let object = {};
  
                    switch (questionObject[0]) {
                      case 'questionSetCd1':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFONE'
                          object['questionCd'] = 'H102'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H102'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        // console.log('ne questuihd',newPartyQuestionArray)
                        break;
                      case 'questionSetCd2':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTWO'
                          object['questionCd'] = 'H103'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H103'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd3':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTHREE'
                          object['questionCd'] = 'H104'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H104'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd4':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFFOUR'
                          object['questionCd'] = 'H105'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H105'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd5':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFFIVE'
                          object['questionCd'] = 'H106'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H106'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd6':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFSIX'
                          object['questionCd'] = 'H107'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H107'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd7':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFSEVEN'
                          object['questionCd'] = 'H108'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H108'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd8':
                        //.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFEIGHT'
                          object['questionCd'] = 'H109'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H109'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd9':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFNINE'
                          object['questionCd'] = 'H110'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H110'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd10':
                        // console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTEN'
                          object['questionCd'] = 'H111'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H111'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd11':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFELEVEN'
                          object['questionCd'] = 'H112'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H112'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd12':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTWELVE'
                          object['questionCd'] = 'H113'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H113'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd13':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFTHIRTEEN'
                          object['questionCd'] = 'H114'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H114'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd14':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDCFLEAFFOURTEEN'
                          object['questionCd'] = 'H115'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H115'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd15':
                        //console.log('questionSetCd1', questionObject[1])
                        object['questionSetCd'] = "CFLEAFFIFTEEN"
                        object['questionCd'] = 'AddInfo'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                        //  if (questionObject[1] === 'NO') {
                        //    object['questionSetCd'] = 'CFLEAFFIFTEEN'
                        //    object['questionCd'] = 'AddInfo'
                        //    object['response'] = questionObject[1]
                        //    newPartyQuestionArray.push(object)
                        //  } else {
                        //    object['questionSetCd'] = questionObject[1]
                        //    object['questionCd'] = 'AddInfo'
                        //    object['response'] = 'Yes'
                        //    newPartyQuestionArray.push(object)
                        //  }
                        break;
  
                      default: break;
                    }
                  }
                })
              })
            }
  
            if (primary.partyAddressDOList) {
  
              console.log(`Adrees section at ${index}`, primary.partyAddressDOList)
              newAdressArray = [
                {
                  "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd,
                  "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang1,
                  "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang1,
                  "stateCd": primary.partyAddressDOList[0].stateCd,
                  "cityCd": primary.partyAddressDOList[0].cityCd,
                  "pinCode": primary.partyAddressDOList[0].pinCode,
                  "areaCd": primary.partyAddressDOList[0].areaCd,
                  "countryCd": primary.partyAddressDOList[0].countryCd
                },
                {
                  "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd2,
                  "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang12,
                  "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang12,
                  "stateCd": primary.partyAddressDOList[0].stateCd2,
                  "cityCd": primary.partyAddressDOList[0].cityCd2,
                  "pinCode": primary.partyAddressDOList[0].pinCode2,
                  "areaCd": primary.partyAddressDOList[0].areaCd2,
                  "countryCd": primary.partyAddressDOList[0].countryCd2
                },
              ]
            }
  
  
  
            //  policyObject = {
            //    abacusId: primary.abacusId,
            //    addOns: primary.addOns,
            //    baseAgentId: primary.baseAgentId,
            //    baseProductId: primary.baseProductId,
            //    businessTypeCd: primary.businessTypeCd,
            //    coverType: primary.coverType,
            //    eldestMemberAge: primary.eldestMemberAge,
            //    isPremiumCalculation: primary.isPremiumCalculation,
            //    quotationPremium: primary.quotationPremium,
            //    quotationReferenceNum: primary.quotationReferenceNum,
            //    sumInsured: primary.sumInsured,
            //    sumInsuredValue: primary.sumInsuredValue,
            //    term: primary.term,
   
            //  }
            //policyAdditionalFieldsDOList = primary.policyAdditionalFieldsDOList
            //console.log('policyAdditionalFieldsDOList', policyAdditionalFieldsDOList)
            // console.log('primary2', primary)
            //  delete nePrimary['abacusId'];
            //  delete nePrimary['addOns'];
            //  delete nePrimary['baseAgentId'];
            //  delete nePrimary['baseProductId'];
            //  delete nePrimary['businessTypeCd'];
            //  delete nePrimary['coverType'];
            //  delete nePrimary['eldestMemberAge'];
            //  delete nePrimary['isPremiumCalculation'];
            //  delete nePrimary['quotationPremium'];
            //  delete nePrimary['quotationReferenceNum'];
            //  delete nePrimary['sumInsured'];
            //  delete nePrimary['sumInsuredValue'];
            //  delete nePrimary['term'];
            //  delete nePrimary['policyAdditionalFieldsDOList'];
  
            let birthDate = new Date(primary.birthDt)
            let newDate = moment(birthDate).format('DD/MM/yyyy')
  
            nePrimary = {
              ...nePrimary,
              'birthDt': newDate,
              ['partyQuestionDOList']: newPartyQuestionArray,
              ['partyAddressDOList']: newAdressArray,
            }
            console.log('primary24', nePrimary)
  
            partyDOList.push(nePrimary)
  
  
  
          }); */
          break;
        case "nomineeDetail":
          field[1].map((nestedField, ind) => {
            policyAdditionalFieldsDOList[0] = {
              ...policyAdditionalFieldsDOList[0],
              ...nestedField,
            };
          });
  
          break;
      }
    });
  
    console.log('policy', policy)
    console.log('policyAdditionalFieldsDOList', policyAdditionalFieldsDOList)
  
  
    const FinalCare = {
      intPolicyDataIO: {
        policy: {
          ...policyObject,
          partyDOList,
          policyAdditionalFieldsDOList
  
        }
      }
    };
    return FinalCare;
  
  };
  
  
  export const createTabCareSeniorReqBdy = (data, prodRequestBody) => {
  
    console.log('data', data)
    let updatedReqBody = prodRequestBody;
    let partyDOList = [];
  
    let policy = {};
    let policyObject = {}
  
    let policyAdditionalFieldsDOList = []
    const moment = require('moment');
  
    Object.entries(data).map((field, index) => {
  
      switch (field[0]) {
        case "primaryDetail":
  
          field[1].map((primary, primaryInd) => {
            let newPartyQuestionArray = [];
            let newAdressArray = []
          let newIdentityDOList = []
  
            console.log('primary', primary)
            console.log('prodRequestBody', prodRequestBody)
            let nePrimary = primary
            if (primary.partyQuestionDOList) {
              //console.log('question', field.partyQuestionDOList)
              primary.partyQuestionDOList.map((questions, questionsIndex) => {
                //console.log('inside quest',questions)
                Object.entries(questions).map((questionObject, objectIndex) => {
                  //  console.log('ususuddg',questionObject)
                  if (questionObject[0] !== "questionCd") {
                    let object = {};
  
                    switch (questionObject[0]) {
                      case 'questionSetCd1':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDdiabetesDetails'
                          object['questionCd'] = '205'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyy')
                          // console.log('recDate',recDate)
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '205'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = 'diabetesExistingSince'
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        // console.log('ne questuihd',newPartyQuestionArray)
                        break;
                      case 'questionSetCd2':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDhyperTensionDetails'
                          object['questionCd'] = '207'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '207'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = 'hyperTensionExistingSince'
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd3':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDliverDetails'
                          object['questionCd'] = '232'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '232'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "liverExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd4':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDcancerDetails'
                          object['questionCd'] = '114'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '114'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "cancerExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd5':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDcardiacDetails'
                          object['questionCd'] = '143'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '143'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "cardiacExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd6':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDjointpainDetails'
                          object['questionCd'] = '105'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '105'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "jointpainExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd7':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDkidneyDetails'
                          object['questionCd'] = '129'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '129'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "kidneyExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd8':
                        //.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDparalysisDetails'
                          object['questionCd'] = '164'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '164'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "paralysisExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd9':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDcongenitalDetails'
                          object['questionCd'] = '122'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '122'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "congenitalExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd10':
                        // console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDHivaidsDetails'
                          object['questionCd'] = '147'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '147'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "hivaidsExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd11':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'yesNoExist'
                          object['questionCd'] = 'pedYesNo'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
  
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'pedYesNo'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd12':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'HEDHealthHospitalized'
                          object['questionCd'] = 'H001'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H001'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd13':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = "HEDHealthClaim"
                          object['questionCd'] = 'H002'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H002'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd14':
                        //console.log('questionSetCd1', field[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = "HEDHealthDeclined"
                          object['questionCd'] = 'H003'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H003'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd15':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = "HEDHealthCovered"
                          object['questionCd'] = 'H004'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = 'H004'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                        }
                        break;
  
                      case 'questionSetCd16':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = "PEDotherDetails"
                          object['questionCd'] = '210'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '210'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "otherExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd17':
                        //console.log('questionSetCd1', questionObject[1])
                        object['questionSetCd'] = "PEDotherDetails"
                        object['questionCd'] = 'otherDiseasesDescription'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                        /*  if (questionObject[1] === 'NO') {
                             object['questionSetCd'] = "PEDotherDetails"
                             object['questionCd'] = '210'
                             object['response'] = questionObject[1]
                             newPartyQuestionArray.push(object)
                         } else {
                             //console.log('hdh',questions.questionSetCd1_date)
                             let extraObject = {}
                             object['questionSetCd'] = questionObject[1]
                             object['questionCd'] = '210'
                             object['response'] = 'Yes'
                             newPartyQuestionArray.push(object)
                             extraObject['questionSetCd'] = questionObject[1]
                             extraObject['questionCd'] = "otherExistingSince"
                             extraObject['response'] = questions.questionSetCd16_date
                             newPartyQuestionArray.push(extraObject)
                         } */
                        break;
  
                      case 'questionSetCd18':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDRespiratoryDetails'
                          object['questionCd'] = '250'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '250'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "respiratoryExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd19':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDEndoDetails'
                          object['questionCd'] = '222'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '222'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "EndocriExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd20':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDillnessDetails'
                          object['questionCd'] = '502'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '502'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "illnessExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd21':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDSurgeryDetails'
                          object['questionCd'] = '503'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '503'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "SurgeryExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
                      case 'questionSetCd22':
                        //console.log('questionSetCd1', questionObject[1])
                        if (questionObject[1] === 'NO') {
                          object['questionSetCd'] = 'PEDSmokeDetails'
                          object['questionCd'] = '504'
                          object['response'] = questionObject[1]
                          newPartyQuestionArray.push(object)
                        } else {
                          //console.log('hdh',questions.questionSetCd1_date)
                          let extraObject = {}
                          let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                          object['questionSetCd'] = questionObject[1]
                          object['questionCd'] = '504'
                          object['response'] = 'Yes'
                          newPartyQuestionArray.push(object)
                          extraObject['questionSetCd'] = questionObject[1]
                          extraObject['questionCd'] = "SmokeExistingSince"
                          extraObject['response'] = recDate
                          newPartyQuestionArray.push(extraObject)
                        }
                        break;
  
  
                      case 'questionSetCd23':
                        //console.log('questionSetCd1', questionObject[1])
                        object['questionSetCd'] = "PEDSmokeDetails"
                        object['questionCd'] = 'OtherSmokeDetails'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                        /*  if (questionObject[1] === 'NO') {
                             object['questionSetCd'] = "PEDotherDetails"
                             object['questionCd'] = '210'
                             object['response'] = questionObject[1]
                             newPartyQuestionArray.push(object)
                         } else {
                             //console.log('hdh',questions.questionSetCd1_date)
                             let extraObject = {}
                             object['questionSetCd'] = questionObject[1]
                             object['questionCd'] = '210'
                             object['response'] = 'Yes'
                             newPartyQuestionArray.push(object)
                             extraObject['questionSetCd'] = questionObject[1]
                             extraObject['questionCd'] = "otherExistingSince"
                             extraObject['response'] = questions.questionSetCd16_date
                             newPartyQuestionArray.push(extraObject)
                         } */
                        break;
  
                      default: break;
                    }
                  }
                })
              })
            }
  
            if (primary.partyAddressDOList) {
  
              console.log(`Adrees section at ${index}`, primary.partyAddressDOList)
              newAdressArray = [
                {
                  "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd,
                  "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang1,
                  "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang1,
                  "stateCd": primary.partyAddressDOList[0].stateCd,
                  "cityCd": primary.partyAddressDOList[0].cityCd,
                  "pinCode": primary.partyAddressDOList[0].pinCode,
                  "areaCd": primary.partyAddressDOList[0].areaCd,
                  "countryCd": primary.partyAddressDOList[0].countryCd
                },
                {
                  "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd2,
                  "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang12,
                  "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang12,
                  "stateCd": primary.partyAddressDOList[0].stateCd2,
                  "cityCd": primary.partyAddressDOList[0].cityCd2,
                  "pinCode": primary.partyAddressDOList[0].pinCode2,
                  "areaCd": primary.partyAddressDOList[0].areaCd2,
                  "countryCd": primary.partyAddressDOList[0].countryCd2
                },
              ]
            }
  
            if (primary.partyIdentityDOList) {
              console.log('idenee hhshshhs',primary.partyIdentityDOList)
              const identityNum= primary.partyIdentityDOList[0].panNumber !=='' 
              ? primary.partyIdentityDOList[0].panNumber: primary.partyIdentityDOList[0].passportNumber
              newIdentityDOList=[
                {
                  identityNum: identityNum,
                  identityTypeCd: primary.partyIdentityDOList[0].identityTypeCd
                }
              ]
            }
  
            
  
            let birthDate = new Date(primary.birthDt)
            let newDate = moment(birthDate).format('DD/MM/yyyy')
  
            nePrimary = {
              ...nePrimary,
              'birthDt': newDate,
              ['partyQuestionDOList']: newPartyQuestionArray,
              ['partyAddressDOList']: newAdressArray,
              ['partyIdentityDOList']: newIdentityDOList
  
            }
            console.log('primary2', nePrimary)
  
            partyDOList.push(nePrimary)
  
          });
  
          break;
        case "dependentDetail":
          let primary = field[1];
          let newPartyQuestionArray = [];
          let newAdressArray = []
          let newIdentityDOList = []
          let nePrimary = primary
  
          if (primary.partyQuestionDOList) {
            //console.log('question', field.partyQuestionDOList)
            primary.partyQuestionDOList.map((questions, questionsIndex) => {
              //console.log('inside quest',questions)
              Object.entries(questions).map((questionObject, objectIndex) => {
                //  console.log('ususuddg',questionObject)
                if (questionObject[0] !== "questionCd") {
                  let object = {};
  
                  switch (questionObject[0]) {
                    case 'questionSetCd1':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDdiabetesDetails'
                        object['questionCd'] = '205'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
                        // console.log('recDate',recDate)
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '205'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = 'diabetesExistingSince'
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      // console.log('ne questuihd',newPartyQuestionArray)
                      break;
                    case 'questionSetCd2':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDhyperTensionDetails'
                        object['questionCd'] = '207'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '207'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = 'hyperTensionExistingSince'
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd3':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDliverDetails'
                        object['questionCd'] = '232'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '232'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "liverExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd4':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDcancerDetails'
                        object['questionCd'] = '114'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '114'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "cancerExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd5':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDcardiacDetails'
                        object['questionCd'] = '143'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '143'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "cardiacExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd6':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDjointpainDetails'
                        object['questionCd'] = '105'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '105'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "jointpainExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd7':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDkidneyDetails'
                        object['questionCd'] = '129'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '129'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "kidneyExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd8':
                      //.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDparalysisDetails'
                        object['questionCd'] = '164'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '164'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "paralysisExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd9':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDcongenitalDetails'
                        object['questionCd'] = '122'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '122'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "congenitalExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd10':
                      // console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDHivaidsDetails'
                        object['questionCd'] = '147'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '147'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "hivaidsExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd11':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'yesNoExist'
                        object['questionCd'] = 'pedYesNo'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
  
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'pedYesNo'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd12':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'HEDHealthHospitalized'
                        object['questionCd'] = 'H001'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H001'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd13':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = "HEDHealthClaim"
                        object['questionCd'] = 'H002'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H002'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd14':
                      //console.log('questionSetCd1', field[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = "HEDHealthDeclined"
                        object['questionCd'] = 'H003'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H003'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd15':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = "HEDHealthCovered"
                        object['questionCd'] = 'H004'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = 'H004'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                      }
                      break;
  
                    case 'questionSetCd16':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = "PEDotherDetails"
                        object['questionCd'] = '210'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '210'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "otherExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd17':
                      //console.log('questionSetCd1', questionObject[1])
                      object['questionSetCd'] = "PEDotherDetails"
                      object['questionCd'] = 'otherDiseasesDescription'
                      object['response'] = questionObject[1]
                      newPartyQuestionArray.push(object)
                      /*  if (questionObject[1] === 'NO') {
                           object['questionSetCd'] = "PEDotherDetails"
                           object['questionCd'] = '210'
                           object['response'] = questionObject[1]
                           newPartyQuestionArray.push(object)
                       } else {
                           //console.log('hdh',questions.questionSetCd1_date)
                           let extraObject = {}
                           object['questionSetCd'] = questionObject[1]
                           object['questionCd'] = '210'
                           object['response'] = 'Yes'
                           newPartyQuestionArray.push(object)
                           extraObject['questionSetCd'] = questionObject[1]
                           extraObject['questionCd'] = "otherExistingSince"
                           extraObject['response'] = questions.questionSetCd16_date
                           newPartyQuestionArray.push(extraObject)
                       } */
                      break;
  
                    case 'questionSetCd18':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDRespiratoryDetails'
                        object['questionCd'] = '250'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '250'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "respiratoryExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd19':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDEndoDetails'
                        object['questionCd'] = '222'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '222'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "EndocriExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd20':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDillnessDetails'
                        object['questionCd'] = '502'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '502'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "illnessExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd21':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDSurgeryDetails'
                        object['questionCd'] = '503'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '503'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "SurgeryExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
                    case 'questionSetCd22':
                      //console.log('questionSetCd1', questionObject[1])
                      if (questionObject[1] === 'NO') {
                        object['questionSetCd'] = 'PEDSmokeDetails'
                        object['questionCd'] = '504'
                        object['response'] = questionObject[1]
                        newPartyQuestionArray.push(object)
                      } else {
                        //console.log('hdh',questions.questionSetCd1_date)
                        let extraObject = {}
                        let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
                        object['questionSetCd'] = questionObject[1]
                        object['questionCd'] = '504'
                        object['response'] = 'Yes'
                        newPartyQuestionArray.push(object)
                        extraObject['questionSetCd'] = questionObject[1]
                        extraObject['questionCd'] = "SmokeExistingSince"
                        extraObject['response'] = recDate
                        newPartyQuestionArray.push(extraObject)
                      }
                      break;
  
  
                    case 'questionSetCd23':
                      //console.log('questionSetCd1', questionObject[1])
                      object['questionSetCd'] = "PEDSmokeDetails"
                      object['questionCd'] = 'OtherSmokeDetails'
                      object['response'] = questionObject[1]
                      newPartyQuestionArray.push(object)
                      /*  if (questionObject[1] === 'NO') {
                           object['questionSetCd'] = "PEDotherDetails"
                           object['questionCd'] = '210'
                           object['response'] = questionObject[1]
                           newPartyQuestionArray.push(object)
                       } else {
                           //console.log('hdh',questions.questionSetCd1_date)
                           let extraObject = {}
                           object['questionSetCd'] = questionObject[1]
                           object['questionCd'] = '210'
                           object['response'] = 'Yes'
                           newPartyQuestionArray.push(object)
                           extraObject['questionSetCd'] = questionObject[1]
                           extraObject['questionCd'] = "otherExistingSince"
                           extraObject['response'] = questions.questionSetCd16_date
                           newPartyQuestionArray.push(extraObject)
                       } */
                      break;
  
                    default: break;
                  }
                }
              })
            })
          }
  
          if (primary.partyAddressDOList) {
  
            console.log(`Adrees section at ${index}`, primary.partyAddressDOList)
            newAdressArray = [
              {
                "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd,
                "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang1,
                "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang1,
                "stateCd": primary.partyAddressDOList[0].stateCd,
                "cityCd": primary.partyAddressDOList[0].cityCd,
                "pinCode": primary.partyAddressDOList[0].pinCode,
                "areaCd": primary.partyAddressDOList[0].areaCd,
                "countryCd": primary.partyAddressDOList[0].countryCd
              },
              {
                "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd2,
                "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang12,
                "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang12,
                "stateCd": primary.partyAddressDOList[0].stateCd2,
                "cityCd": primary.partyAddressDOList[0].cityCd2,
                "pinCode": primary.partyAddressDOList[0].pinCode2,
                "areaCd": primary.partyAddressDOList[0].areaCd2,
                "countryCd": primary.partyAddressDOList[0].countryCd2
              },
            ]
          }
  
          if (primary.partyIdentityDOList) {
            console.log('idenee hhshshhs',primary.partyIdentityDOList)
            const identityNum= primary.partyIdentityDOList[0].panNumber !=='' 
            ? primary.partyIdentityDOList[0].panNumber: primary.partyIdentityDOList[0].passportNumber
            newIdentityDOList=[
              {
                identityNum: identityNum,
                identityTypeCd: primary.partyIdentityDOList[0].identityTypeCd
              }
            ]
          }
  
          policyAdditionalFieldsDOList.push(primary.policyAdditionalFieldsDOList)
            console.log('policyAdditionalFieldsDOList', policyAdditionalFieldsDOList)
            // console.log('primary2', primary)
            policyObject = {
              abacusId: primary.abacusId,
              addOns: primary.addOns === "" ? "NONE" : primary.addOns,
              baseAgentId: primary.baseAgentId,
              baseProductId: primary.baseProductId,
              businessTypeCd: primary.businessTypeCd,
              coverType: primary.coverType,
              eldestMemberAge: primary.eldestMemberAge,
              isPremiumCalculation: primary.isPremiumCalculation,
              quotationPremium: primary.quotationPremium,
              quotationReferenceNum: primary.quotationReferenceNum,
              sumInsured: primary.sumInsured,
              sumInsuredValue: primary.sumInsuredValue,
              term: primary.term,
  
            }
            delete nePrimary['abacusId'];
            delete nePrimary['addOns'];
            delete nePrimary['baseAgentId'];
            delete nePrimary['baseProductId'];
            delete nePrimary['businessTypeCd'];
            delete nePrimary['coverType'];
            delete nePrimary['eldestMemberAge'];
            delete nePrimary['isPremiumCalculation'];
            delete nePrimary['quotationPremium'];
            delete nePrimary['quotationReferenceNum'];
            delete nePrimary['sumInsured'];
            delete nePrimary['sumInsuredValue'];
            delete nePrimary['term'];
            delete nePrimary['policyAdditionalFieldsDOList'];
  
          let birthDate = new Date(primary.birthDt)
            let newDate = moment(birthDate).format('DD/MM/yyyy')
            nePrimary = {
              ...nePrimary,
              'birthDt': newDate,
              ['partyQuestionDOList']: newPartyQuestionArray,
              ['partyAddressDOList']: newAdressArray,
              ['partyIdentityDOList']: newIdentityDOList
  
            }
            console.log('primary24', nePrimary)
  
            partyDOList.push(nePrimary)
  
  
  
    //       field[1].map((primary, primaryInd) => {
    //         let newPartyQuestionArray = [];
    //         let newAdressArray = []
    //         console.log('primary', primary)
    //         console.log('prodRequestBody', prodRequestBody)
    //         let nePrimary = primary
  
    //         if (primary.partyQuestionDOList) {
    //           //console.log('question', field.partyQuestionDOList)
    //           primary.partyQuestionDOList.map((questions, questionsIndex) => {
    //             //console.log('inside quest',questions)
    //             Object.entries(questions).map((questionObject, objectIndex) => {
    //               //  console.log('ususuddg',questionObject)
    //               if (questionObject[0] !== "questionCd") {
    //                 let object = {};
  
    //                 switch (questionObject[0]) {
    //                   case 'questionSetCd1':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDdiabetesDetails'
    //                       object['questionCd'] = '205'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
    //                       // console.log('recDate',recDate)
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '205'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = 'diabetesExistingSince'
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     // console.log('ne questuihd',newPartyQuestionArray)
    //                     break;
    //                   case 'questionSetCd2':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDhyperTensionDetails'
    //                       object['questionCd'] = '207'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '207'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = 'hyperTensionExistingSince'
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd3':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDliverDetails'
    //                       object['questionCd'] = '232'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '232'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "liverExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd4':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDcancerDetails'
    //                       object['questionCd'] = '114'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '114'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "cancerExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd5':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDcardiacDetails'
    //                       object['questionCd'] = '143'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '143'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "cardiacExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd6':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDjointpainDetails'
    //                       object['questionCd'] = '105'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '105'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "jointpainExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd7':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDkidneyDetails'
    //                       object['questionCd'] = '129'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '129'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "kidneyExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd8':
    //                     //.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDparalysisDetails'
    //                       object['questionCd'] = '164'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '164'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "paralysisExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd9':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDcongenitalDetails'
    //                       object['questionCd'] = '122'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '122'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "congenitalExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd10':
    //                     // console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDHivaidsDetails'
    //                       object['questionCd'] = '147'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '147'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "hivaidsExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd11':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'yesNoExist'
    //                       object['questionCd'] = 'pedYesNo'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
  
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = 'pedYesNo'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd12':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'HEDHealthHospitalized'
    //                       object['questionCd'] = 'H001'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = 'H001'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd13':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = "HEDHealthClaim"
    //                       object['questionCd'] = 'H002'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = 'H002'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd14':
    //                     //console.log('questionSetCd1', field[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = "HEDHealthDeclined"
    //                       object['questionCd'] = 'H003'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = 'H003'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd15':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = "HEDHealthCovered"
    //                       object['questionCd'] = 'H004'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = 'H004'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd16':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = "PEDotherDetails"
    //                       object['questionCd'] = '210'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '210'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "otherExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd17':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     object['questionSetCd'] = "PEDotherDetails"
    //                     object['questionCd'] = 'otherDiseasesDescription'
    //                     object['response'] = questionObject[1]
    //                     newPartyQuestionArray.push(object)
    //                     /*  if (questionObject[1] === 'NO') {
    //                          object['questionSetCd'] = "PEDotherDetails"
    //                          object['questionCd'] = '210'
    //                          object['response'] = questionObject[1]
    //                          newPartyQuestionArray.push(object)
    //                      } else {
    //                          //console.log('hdh',questions.questionSetCd1_date)
    //                          let extraObject = {}
    //                          object['questionSetCd'] = questionObject[1]
    //                          object['questionCd'] = '210'
    //                          object['response'] = 'Yes'
    //                          newPartyQuestionArray.push(object)
    //                          extraObject['questionSetCd'] = questionObject[1]
    //                          extraObject['questionCd'] = "otherExistingSince"
    //                          extraObject['response'] = questions.questionSetCd16_date
    //                          newPartyQuestionArray.push(extraObject)
    //                      } */
    //                     break;
  
    //                   case 'questionSetCd18':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDRespiratoryDetails'
    //                       object['questionCd'] = '250'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '250'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "respiratoryExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd19':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDEndoDetails'
    //                       object['questionCd'] = '222'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '222'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "EndocriExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd20':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDillnessDetails'
    //                       object['questionCd'] = '502'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '502'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "illnessExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd21':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDSurgeryDetails'
    //                       object['questionCd'] = '503'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '503'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "SurgeryExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
    //                   case 'questionSetCd22':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     if (questionObject[1] === 'NO') {
    //                       object['questionSetCd'] = 'PEDSmokeDetails'
    //                       object['questionCd'] = '504'
    //                       object['response'] = questionObject[1]
    //                       newPartyQuestionArray.push(object)
    //                     } else {
    //                       //console.log('hdh',questions.questionSetCd1_date)
    //                       let extraObject = {}
    //                       let recDate = moment(questions.questionSetCd1_date).format('MM/yyyy')
  
    //                       object['questionSetCd'] = questionObject[1]
    //                       object['questionCd'] = '504'
    //                       object['response'] = 'Yes'
    //                       newPartyQuestionArray.push(object)
    //                       extraObject['questionSetCd'] = questionObject[1]
    //                       extraObject['questionCd'] = "SmokeExistingSince"
    //                       extraObject['response'] = recDate
    //                       newPartyQuestionArray.push(extraObject)
    //                     }
    //                     break;
  
  
    //                   case 'questionSetCd23':
    //                     //console.log('questionSetCd1', questionObject[1])
    //                     object['questionSetCd'] = "PEDSmokeDetails"
    //                     object['questionCd'] = 'OtherSmokeDetails'
    //                     object['response'] = questionObject[1]
    //                     newPartyQuestionArray.push(object)
    //                     /*  if (questionObject[1] === 'NO') {
    //                          object['questionSetCd'] = "PEDotherDetails"
    //                          object['questionCd'] = '210'
    //                          object['response'] = questionObject[1]
    //                          newPartyQuestionArray.push(object)
    //                      } else {
    //                          //console.log('hdh',questions.questionSetCd1_date)
    //                          let extraObject = {}
    //                          object['questionSetCd'] = questionObject[1]
    //                          object['questionCd'] = '210'
    //                          object['response'] = 'Yes'
    //                          newPartyQuestionArray.push(object)
    //                          extraObject['questionSetCd'] = questionObject[1]
    //                          extraObject['questionCd'] = "otherExistingSince"
    //                          extraObject['response'] = questions.questionSetCd16_date
    //                          newPartyQuestionArray.push(extraObject)
    //                      } */
    //                     break;
  
    //                   default: break;
    //                 }
    //               }
    //             })
    //           })
    //         }
  
    //         if (primary.partyAddressDOList) {
  
    //           console.log(`Adrees section at ${index}`, primary.partyAddressDOList)
    //           newAdressArray = [
    //             {
    //               "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd,
    //               "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang1,
    //               "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang1,
    //               "stateCd": primary.partyAddressDOList[0].stateCd,
    //               "cityCd": primary.partyAddressDOList[0].cityCd,
    //               "pinCode": primary.partyAddressDOList[0].pinCode,
    //               "areaCd": primary.partyAddressDOList[0].areaCd,
    //               "countryCd": primary.partyAddressDOList[0].countryCd
    //             },
    //             {
    //               "addressTypeCd": primary.partyAddressDOList[0].addressTypeCd2,
    //               "addressLine1Lang1": primary.partyAddressDOList[0].addressLine1Lang12,
    //               "addressLine2Lang1": primary.partyAddressDOList[0].addressLine2Lang12,
    //               "stateCd": primary.partyAddressDOList[0].stateCd2,
    //               "cityCd": primary.partyAddressDOList[0].cityCd2,
    //               "pinCode": primary.partyAddressDOList[0].pinCode2,
    //               "areaCd": primary.partyAddressDOList[0].areaCd2,
    //               "countryCd": primary.partyAddressDOList[0].countryCd2
    //             },
    //           ]
    //         }
  
  
    //         //policyAdditionalFieldsDOList = primary.policyAdditionalFieldsDOList
    //         //console.log('policyAdditionalFieldsDOList', policyAdditionalFieldsDOList)
    //         // console.log('primary2', primary)
    //         /*  delete nePrimary['abacusId'];
    //          delete nePrimary['addOns'];
    //          delete nePrimary['baseAgentId'];
    //          delete nePrimary['baseProductId'];
    //          delete nePrimary['businessTypeCd'];
    //          delete nePrimary['coverType'];
    //          delete nePrimary['eldestMemberAge'];
    //          delete nePrimary['isPremiumCalculation'];
    //          delete nePrimary['quotationPremium'];
    //          delete nePrimary['quotationReferenceNum'];
    //          delete nePrimary['sumInsured'];
    //          delete nePrimary['sumInsuredValue'];
    //          delete nePrimary['term'];
    //          delete nePrimary['policyAdditionalFieldsDOList'];
    // */
    //         let birthDate = new Date(primary.birthDt)
    //         let newDate = moment(birthDate).format('DD/MM/yyyy')
    //         nePrimary = {
    //           ...nePrimary,
    //           'birthDt': newDate,
    //           ['partyQuestionDOList']: newPartyQuestionArray,
    //           ['partyAddressDOList']: newAdressArray,
    //         }
    //         console.log('primary24', nePrimary)
  
    //         partyDOList.push(nePrimary)
  
  
  
    //       });
          break;
        case "nomineeDetail":
          field[1].map((nestedField, ind) => {
            policyAdditionalFieldsDOList[ind] = {
              ...policyAdditionalFieldsDOList[ind],
              ...nestedField,
            };
          });
  
          break;
      }
    });
  
    console.log('policy', policy)
    console.log('policyAdditionalFieldsDOList', policyAdditionalFieldsDOList)
  
  
    const FinalCare = {
      intPolicyDataIO: {
        policy: {
          ...policyObject,
          partyDOList,
          policyAdditionalFieldsDOList
  
        }
      }
    };
  
    /* let altObj = {
      ...policyObject,
      partyDOList
    }
    mergeRequestbody(prodRequestBody, altObj) */
  
    return FinalCare;
  };
  
  export const createTabStarReqBdy = (data, prodRequestBody) => {
    let updatedReqBody = prodRequestBody;
    let proposerDetail;
    let insuredsArray=[];
    let insuredsObject={};
    let nomineeDetail;
    console.log('params', data, prodRequestBody)
    Object.entries(data).map((entry, index) => {
      switch (entry[0]) {
        case 'primaryDetail':
          entry[1].map((item,ind)=>{
            //console.log('item',item)
            //insuredsObject = {...insuredsObject,[`insureds[${ind}]`]:item}
            insuredsArray.push(item);
            console.log('insurObject',insuredsObject)
            //console.log('primaryDetail',primaryDetail)
  
          })
          break;
        case 'dependentDetail':
         /*  Object.entries(entry[1]).map((item,ind)=>{
            console.log('item',item)
            proposerDetail = {...item}
  
           
  
          }) */
          proposerDetail = {...entry[1]}
  
          break;
        case 'nomineeDetail':
          entry[1].map((item,ind)=>{
            //console.log('item',item)
            nomineeDetail = {...item}
            //console.log('primaryDetail',primaryDetail)
  
          })
          break;
      }
    })
    updatedReqBody={
      ...proposerDetail,
      //...insuredsObject,
      'insureds':insuredsArray,
      ...nomineeDetail
    }
    return updatedReqBody
  }
  
  