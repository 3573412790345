import React, { useState } from "react";
import { useEffect } from "react";
import "../../Assets/css/LifeInsurance/ProductDetailPage.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useParams } from "react-router";
import Axios from "axios";
import { Card } from "@mui/material";
//import SRimg from '../../ShriramImage/SRimg.jpg'

function ProductDetailsPage(props) {
  const [transferData,setTransferData]=useState({})
  const [ProductDetails,setProductDetails]=useState({})
  const navigate = useNavigate();
  const { id } = useParams();


 useEffect(() => {
    // setProductDetails(shriQuick);
    Axios.get(
      `http://65.0.70.70:8000/api/shriram/plp-validate-response/${id}`
      //`http://ec2-43-205-26-216.ap-south-1.compute.amazonaws.com/api/shriram/plp-validate-response/${id}`
      ).then(
      (response) => {
        if (response.status === 200) {
          console.log("response in PHP", response);
          setProductDetails(response.data);
          // setProductDetails(response.data);
        }
      }
    );
  }, []);

  console.log(id, "PHP");
  const buyPlanHandler = () => {
   
    navigate(`/life-insurance/validateproposal/${id}+${ProductDetails.product_ref}`);
  };


  // return (
  //   <div className="detailpage">
  //     <Card style={{ backgroundColor: "#0c409a", color: "white", padding: 20 }}>
  //       <div className="pro_heading">
  //         <div className="pro_image">Product image</div>
  //         <div>
  //           <div>Care Freedom- Comprehensive Floater </div>
  //           <div>Care</div>
  //         </div>
  //       </div>
  //       <div className="details_container">
  //         <div>
  //           <div>
  //             <div>Sum Assured</div>
  //             <div>&#8377; 3 Lakhs</div>
  //           </div>
  //         </div>
  //         <div className="linewithdata">
  //           <div className="line"></div>
  //           <div>
  //             <div>Premium Amount</div>
  //             <div>&#8377; 3 Lakhs</div>
  //           </div>
  //         </div>
  //         <div className="linewithdata">
  //           <div className="line"></div>
  //           <div>
  //             <div>Term</div>
  //             <div>3 Years</div>
  //           </div>
  //         </div>
  //       </div>
  //     </Card>
  //     <div>
  //       <p style={{ marginLeft: 20, marginTop: 20, fontSize: 20 }}>Go Digit</p>
  //       <iframe
  //         src="https://www.youtube.com/embed/E7wJTI-1dvQ"
  //         frameborder="0"
  //         allow="autoplay; encrypted-media"
  //         allowfullscreen
  //         title="video"
  //       />
  //     </div>
  //     <Card className="id_container">
  //       <div>Document Needed</div>
  //       <div className="id_grid">
  //         <div className="ids">
  //           <CheckCircleIcon style={{ color: "#18c6b1" }} />
  //           Kyc-pan Card
  //         </div>
  //         <div className="ids">
  //           <CheckCircleIcon style={{ color: "#18c6b1" }} />
  //           Aadhaar Card
  //         </div>
  //         <div className="ids">
  //           <CheckCircleIcon style={{ color: "#18c6b1" }} />
  //           Passport
  //         </div>
  //       </div>
  //     </Card>

  //     <div className="accor_container">
  //       <p>Policy Highlight</p>
  //       <Accordion
  //         style={{
  //           backgroundColor: "#0fb19e",
  //           color: "white",
  //           border: " solid dashed",
  //         }}
  //       >
  //         <AccordionSummary
  //           className="Acc_summary"
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel1a-content"
  //           id="panel1a-header"
  //         >
  //           <div className="acc-circle">1</div>
  //           <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
  //             Simple online process{" "}
  //           </Typography>
  //         </AccordionSummary>

  //         <AccordionDetails className="Acc_detail">
  //           <Typography>
  //             Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  //             Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
  //             eget.
  //           </Typography>
  //         </AccordionDetails>
  //       </Accordion>
  //       <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel1a-content"
  //           id="panel1a-header"
  //         >
  //           <div className="acc-circle">2</div>
  //           <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
  //             Get treated at any hospital
  //           </Typography>
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <Typography>
  //             Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  //             Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
  //             eget.
  //           </Typography>
  //         </AccordionDetails>
  //       </Accordion>
  //       <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel1a-content"
  //           id="panel1a-header"
  //         >
  //           <div className="acc-circle">3</div>
  //           <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
  //             Get Bonus
  //           </Typography>
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <Typography>
  //             Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  //             Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
  //             eget.
  //           </Typography>
  //         </AccordionDetails>
  //       </Accordion>
  //       <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel1a-content"
  //           id="panel1a-header"
  //         >
  //           <div className="acc-circle">4</div>
  //           <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
  //             Minimal copayment
  //           </Typography>
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
  //           malesuada lacus ex, sit amet blandit leo lobortis eget.
  //         </AccordionDetails>
  //       </Accordion>
  //       <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel1a-content"
  //           id="panel1a-header"
  //         >
  //           <div className="acc-circle">5</div>
  //           <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
  //             Super easy claims
  //           </Typography>
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
  //           malesuada lacus ex, sit amet blandit leo lobortis eget.
  //         </AccordionDetails>
  //       </Accordion>
  //     </div>
  //     <div className="button-sec">
  //       <button className="Goback_btn">Go back</button>
  //       <button className="btn" onClick={buyPlanHandler}>
  //         buy plan
  //       </button>
  //     </div>
  //   </div>
  // );

  return (
    <div className="detailpage">
      <Card style={{ backgroundColor: "#0c409a", color: "white", padding: 20 }}>
        <div className="pro_heading">
         {/*  <img className="pro_img" src={SRimg}/> */}
          <div>
          <div className="product_heading">Shriram Life Assured Savings Plan</div>
           
          </div>
        </div>
        <div className="details_container">
          <div>
            <div>
              <div>Sum Assured</div>
              <div>&#8377; {ProductDetails.SumProposed}</div>
            </div>
          </div>
          <div className="linewithdata">
            <div className="line"></div>
            <div>
              <div>Premium Amount</div>
              <div>&#8377; {ProductDetails.TotalPremium}</div>
            </div>
          </div>
          <div className="linewithdata">
            <div className="line"></div>
            <div>
              <div>Term</div>
              <div>{ProductDetails.PremiumFrequencyString}</div>
            </div>
          </div>
        </div>
      </Card>
      <div>
        <p style={{ marginLeft: 20, marginTop: 20, fontSize: 20 }}>Shriram</p>
        <iframe
          src="https://www.youtube.com/embed/E7wJTI-1dvQ"
          frameborder="0"     
          allow="autoplay; encrypted-media"
          allowfullscreen
          title="video"
        />
      </div>
      <Card className="id_container">
        <div>Document Needed</div>
        <div className="id_grid">
          <div className="ids">
            <CheckCircleIcon style={{ color: "#18c6b1" }} />
            Kyc-pan Card
          </div>
          <div className="ids">
            <CheckCircleIcon style={{ color: "#18c6b1" }} />
            Aadhaar Card
          </div>
          <div className="ids">
            <CheckCircleIcon style={{ color: "#18c6b1" }} />
            Passport
          </div>
        </div>
      </Card>

      <div className="accor_container">
        <p>Policy Highlight</p>
        <Accordion
          style={{
            backgroundColor: "#0fb19e",
            color: "white",
            border: " solid dashed",
          }}
        >
          <AccordionSummary
            className="Acc_summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="acc-circle">1</div>
            <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
              Simple online process{" "}
            </Typography>
          </AccordionSummary>

          <AccordionDetails className="Acc_detail">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="acc-circle">2</div>
            <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
              Get treated at any hospital
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="acc-circle">3</div>
            <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
              Get Bonus
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="acc-circle">4</div>
            <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
              Minimal copayment
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#0fb19e", color: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="acc-circle">5</div>
            <Typography style={{ marginLeft: 10, marginTop: 5, fontSize: 20 }}>
              Super easy claims
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="button-sec">
        <button className="Goback_btn">Go back</button>
        <button className="btn" onClick={buyPlanHandler}>
          buy plan
        </button>
      </div>
    </div>
  );
}


export default ProductDetailsPage;
