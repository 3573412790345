import { Typography } from '@mui/material'

import React from 'react'

function TextError(props) {
  console.log('errCon',props)
  return (
    <Typography color='error'>
        {props.children}
    </Typography>
  )
}

export default TextError