import React, { useEffect, useState } from 'react'
import LoadingStatus from '../../Components/PolicyStatusComponents/LoadingStatus'
import SuccesStatus from '../../Components/PolicyStatusComponents/SuccesStatus'
import Axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const indicatorSize = 80;

const HealthInsPolicyStatus = (props) => {
    const {proposerNo} =props
    const [loading, setLoading] = useState(true)
    const [rejectPolicy, setRejectPolicy] = useState(true)
    const [policyData, setPolicyData] = useState([])

    const [policyDeclined, setPolicyDeclined] = useState(true)
    const [error, setError] = useState(true)
    const [data, setData] = useState({})

    console.log('pppp',proposerNo)

    let navigate = useNavigate();
    const { proposerNum } = useParams();


    console.log(proposerNum)

    useEffect(() => {
        let domain = ''

        if (window.location.hostname === 'localhost') {
            domain = process.env.REACT_APP_LOCAL_DJANGO

           /*  Axios.get(`http://127.0.0.1:8000/api/health-insurance/care/policy-status?proposalNum=${proposerNum}`,).then(
                response => {
                    console.log(response.data);

                    switch (response.data.policyStatus) {
                        case 3: setData(response.data)
                            setLoading(false)
                            setRejectPolicy(false)
                            setError(false)
                            setPolicyDeclined(false)
                            return
                        case 5: setData(response.data)
                            setLoading(true)
                            setError(false)
                            setRejectPolicy(false)
                            setPolicyDeclined(false)

                            return
                        case 6: setRejectPolicy(false)
                            setLoading(false)
                            setError(false)
                            setPolicyDeclined(true)

                            return
                        case 4: setRejectPolicy(true)
                            setLoading(false)
                            setError(false)
                            setPolicyDeclined(false)

                            return
                    }

                    /*  if (response.data.policyStatus === 3) {
                         console.log('ssssuccc')
                     }
 
                     if (response.data.error) {
                         console.log('eeeeeerrrrrr')
                         setError(true)
                         setLoading(false)
                         setRejectPolicy(false)
                     } else {
 
                     } 


                }
            ).catch(
                error => {
                    setError(true)
                    console.log(error)
                }
            ) */

        }else if (window.location.hostname.trim() === 'it.acuvisor.com') {
            domain = process.env.REACT_APP_PRODUCTION_DJANGO
        }else {
            domain = process.env.REACT_APP_UAT_DJANGO

            /* Axios.get(`http://65.0.70.70:82/api/health-insurance/care/policy-status?proposalNum=${proposerNum}`,).then(
                response => {
                    console.log(response.data);

                    switch (response.data.policyStatus) {
                        case 3: setData(response.data)
                            setLoading(false)
                            setRejectPolicy(false)
                            setError(false)
                            setPolicyDeclined(false)
                            return
                        case 5: setData(response.data)
                            setLoading(true)
                            setError(false)
                            setRejectPolicy(false)
                            setPolicyDeclined(false)

                            return
                        case 6: setRejectPolicy(false)
                            setLoading(false)
                            setError(false)
                            setPolicyDeclined(true)

                            return
                        case 4: setRejectPolicy(true)
                            setLoading(false)
                            setError(false)
                            setPolicyDeclined(false)

                            return
                    }
                }
            ).catch(
                error => {
                    //alert('Service Not Available')
                    console.log(error)
                    //console.log(error.status)

                }
            ) */
        }

        Axios.get(`${domain}/api/health-insurance/care/policy-status?proposalNum=${proposerNum}`,).then(
                response => {
                    console.log(response.data);

                    switch (response.data.policyStatus) {
                        case 3: setData(response.data)
                            setLoading(false)
                            setRejectPolicy(false)
                            setError(false)
                            setPolicyDeclined(false)
                            return
                        case 5: setData(response.data)
                            setLoading(true)
                            setError(false)
                            setRejectPolicy(false)
                            setPolicyDeclined(false)

                            return
                        case 6: setRejectPolicy(false)
                            setLoading(false)
                            setError(false)
                            setPolicyDeclined(true)

                            return
                        case 4: setRejectPolicy(true)
                            setLoading(false)
                            setError(false)
                            setPolicyDeclined(false)

                            return
                    }
                }
            ).catch(
                error => {
                    //alert('Service Not Available')
                    console.log(error)
                    //console.log(error.status)

                }
            )
    }, [])

    if (loading && error && rejectPolicy) {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: `${-indicatorSize / 2}px`,
                    marginLeft: `${-indicatorSize / 2}px`
                }}
            >
                <CircularProgress />

            </Box>
        )
    }

    if (loading) {
        return (
            <LoadingStatus
                status={5}
                data={data}
                proposerNum={proposerNum}
            />
        )
    }
    if (policyDeclined) {
        return (
            <LoadingStatus
                status={6}
            />
        )
    }
    if (rejectPolicy) {
        return (
            <LoadingStatus
                status={4}
            />
        )
    }
    if (error) {
        return (
            <LoadingStatus
                status={9}
            />
        )
    }

    return (
        <>
            {

                <SuccesStatus data={data} />
            }
        </>
    )
}

export default HealthInsPolicyStatus