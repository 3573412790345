import { useFormikContext } from 'formik';
import React, { useEffect } from 'react'
import FormikControl from '../../Components/HealthInsFormikComponent/FormikControl';
import CreateQuoteDependent from './Dependents/CreateQuoteDependent';
import CreateQuoteObjectView from './Dependents/CreateQuoteObjectView';


const CreateQuoteMainObjectView = ({ Fields, activeStep, quickQuoteDetails }) => {
    console.log('sss', Fields)
    const { values, setFieldValue } = useFormikContext()

    useEffect(() => {
        if('socialStatusBpl' in values.dependentDetail ){
            if (values.dependentDetail['socialStatusBpl'] !== '' && values.dependentDetail['socialStatusDisabled'] !== ''
            && values.dependentDetail['socialStatusInformal'] !== '' && values.dependentDetail['socialStatusUnorganized'] !== '') {
            let socialStatus = (
                parseInt(values.dependentDetail['socialStatusBpl']) ||
                parseInt(values.dependentDetail['socialStatusDisabled']) ||
                parseInt(values.dependentDetail['socialStatusInformal']) ||
                parseInt(values.dependentDetail['socialStatusUnorganized'])
            )
            console.log('setSocial', socialStatus)
            setFieldValue("dependentDetail['socialStatus']", String(socialStatus))


        }
        }
       
    }, [values])


    return (
        <div>
            {Fields[activeStep].obj.map(
                (field, index) => {
                    return field.htmlElement ===
                        "TextField" ? (
                        <div
                            className="mb-3"
                            key={index}
                            hidden={field.hidden}
                        >
                            <FormikControl
                                control="input"
                                name={`${Fields[activeStep].name}.${field.name}`}
                                /*  manualValidation={
                                   field.manualValidation
                                 } */
                                //errors={formik.errors}
                                readOnly={field.readOnly}
                                /*  transperData={
                                   productDetails.transperData
                                 }*/
                                url={field.url}
                                label={field.label}
                                type={field.dataType}
                            // touched={formik.touched}
                            />
                        </div>
                    ) : field.htmlElement ===
                        "Select" ? (
                        <div
                            className="mb-3"
                            key={index}
                            hidden={field.hidden}
                        >
                            <FormikControl
                                control="select"
                                readOnly={field.readOnly}
                                name={`${Fields[activeStep].name}.${field.name}`}
                                quickQuoteDetails={quickQuoteDetails}
                                //errors={formik.errors}
                                label={field.label}
                                type={field.dataType}
                                //touched={formik.touched}
                                options={field.options}
                            />
                        </div>
                    ) : field.htmlElement ===
                        "TextArea" ? (
                        <div
                            className="mb-3"
                            key={index}
                            hidden={field.hidden}
                        >
                            <FormikControl
                                control="textarea"
                                name={`${Fields[activeStep].name}.${field.name}`}

                                //errors={formik.errors}
                                label={field.label}
                                type={field.dataType}
                            // touched={formik.touched}
                            />
                        </div>
                    ) : field.htmlElement ===
                        "Radio" ? (
                        <div
                            className="mb-3"
                            key={index}
                            hidden={field.hidden}
                        >
                            <FormikControl
                                control="radio"
                                name={`${Fields[activeStep].name}.${field.name}`}

                                //errors={formik.errors}
                                dependent={
                                    field.dependent
                                }
                                options={field.options}
                                manualValidation={
                                    field.manualValidation
                                }
                                //arrayindex={i}
                                label={field.label}
                                type={field.dataType}
                            //touched={formik.touched}
                            />
                        </div>
                    ) : field.htmlElement ===
                        "DatePicker" ? (
                        <div
                            className="mb-3"
                            key={index}
                            hidden={field.hidden}
                        >
                            <FormikControl
                                control="date"
                                name={`${Fields[activeStep].name}.${field.name}`}

                                //errors={field.errors}
                                readOnly={field.readOnly}
                                label={field.label}
                                type={field.dataType}
                            />
                        </div>
                    ) : field.htmlElement ===
                        "checkbox" ? (
                        <div
                            className="mb-3"
                            key={index}
                            hidden={field.hidden}
                        >
                            <FormikControl
                                control="checkBox"
                                name={`${Fields[activeStep].name}.${field.name}`}

                                //errors={field.errors}
                                label={field.label}
                                type={field.dataType}
                                options={field.options}
                            />
                        </div>
                    )
                        : field.htmlElement === 'MultiSelect' ?
                            <div className="mb-3" key={index} hidden={field.hidden}>

                                <FormikControl
                                    control='MultiSelect'
                                    readOnly={field.readOnly}
                                    name={`${Fields[activeStep].name}.${field.name}`}

                                    //errors={formik.errors}
                                    label={field.label}
                                    type={field.dataType}
                                    // touched={formik.touched}
                                    options={field.options}
                                //onChange={(e)=>onChange(e,field.name)}  
                                //onChange={(e) => formik.handleChange(e)}

                                />

                            </div>
                            : field.htmlElement ===
                                "Array" ? (
                                <div
                                    className="mb-3"
                                    key={index}
                                    style={{
                                        marginTop: "24px",
                                    }}
                                >
                                    <h3 className="py-3 second-heading-text">
                                        {field.label}
                                    </h3>
                                    <div className="mb-3">
                                        <CreateQuoteDependent
                                            field={field}
                                            name={`${Fields[activeStep].name}`}
                                            quickQuoteDetails={quickQuoteDetails}

                                            dependentIndex={1}
                                        /*  transperData={
                                           productDetails.transperData
                                         } */
                                        />
                                    </div>
                                </div>
                            ) : field.htmlElement ===
                                "Object" ? (
                                <div
                                    className="mb-3"
                                    key={index}
                                    style={{
                                        marginTop: "24px",
                                    }}
                                >
                                    <CreateQuoteObjectView
                                        previousObjectName={`${Fields[activeStep].name}`}
                                        quickQuoteDetails={quickQuoteDetails}

                                        data={field}
                                    />
                                </div>
                            ) : null;
                }
            )}
        </div>
    )
}

export default CreateQuoteMainObjectView