import { CircularProgress } from '@mui/material'
import { Form, useFormik, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import ArrayMainComponent from './ArrayMainComponent'
import CreateQuoteMainObjectView from './CreateQuoteMainObjectView'

const HealthInsuranceForm = (props) => {

    const { quickQuoteDetails, Fields, activeStep, depInd, disableBuy, handleBack, isLastStep } = props

    const formik = useFormikContext()

    useEffect(() => {



    }, [])


    return (
        <Form autoComplete="off">
            {Object.keys(Fields[activeStep]).length > 0 ?
                console.log('actine', Fields[activeStep]) : null
            }
            <div>

                {Object.keys(Fields[activeStep]).length > 0 ?
                    Fields[activeStep].htmlElement === "Array" ?
                        <ArrayMainComponent
                            Fields={Fields}
                            formik={formik}
                            activeStep={activeStep}
                            quickQuoteDetails={quickQuoteDetails}
                            depInd={depInd}
                        />
                        : Fields[activeStep].htmlElement === "Object" ?
                            <CreateQuoteMainObjectView
                                Fields={Fields}
                                quickQuoteDetails={quickQuoteDetails}
                                activeStep={activeStep}

                            />
                            : null
                    : null}
            </div>

            <div className="btn-sec">

                {
                    disableBuy ?
                        <div style={{ justifyContent: 'center', textAlign: 'center', display: 'flex', margin: 'auto' }} >
                            <CircularProgress

                                size={48}
                                sx={{
                                    color: 'red',

                                }} />
                        </div>
                        :
                        <>
                            {/* {
                                  activeStep !== 0 ? 
                                  <button className="Goback_btn" type="button" onClick={handleBack}>Go back</button>
                                  :null

                                } */}
                            <button className="Goback_btn" type="button" onClick={handleBack}>Go back</button>

                            <button className="S_btn" disabled={formik.isSubmitting} type="submit" >{isLastStep() ? "Submit" : "Next"}</button>
                        </>

                }
                {/*  <button className="S_btn" type="submit" >{isLastStep() ? "Buy Plan" : "Next"}</button> */}

            </div>
        </Form>
    )
}

export default HealthInsuranceForm